import { API_BASE_URL } from "../common";
import axios from "axios";
import { userService } from "../services/user.service";

export const fetchWrapper = {
  getExcel,
  get,
  getAxios,
  post,
  put,
  putByParam,
  delete: _delete,
  postByParam,
};

function getExcel(url) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...getHeaderXLSX(),
      ...authHeader(url),
    },
  };
  return fetch(API_BASE_URL + url, requestOptions).then(handleResponse);
}

function get(url) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...getHeader(),
      ...authHeader(url),
    },
  };
  return fetch(API_BASE_URL + url, requestOptions).then(handleResponse);
}

function getAxios(url) {
  return axios.get(API_BASE_URL + url, {
    headers: {
      ...getHeader(),
      ...authHeader(url),
    },
    responseType: "blob",
  });
}

function post(url, body) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...getHeader(),
      ...authHeader(url),
    },
    credentials: "include",
    body: JSON.stringify(body),
  };
  return fetch(API_BASE_URL + url, requestOptions).then(handleResponse);
}

function put(url, body) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...getHeader(),
      ...authHeader(url),
    },
    credentials: "include",
    body: JSON.stringify(body),
  };
  return fetch(API_BASE_URL + url, requestOptions).then(handleResponse);
}

function putByParam(url, formData) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...authHeader(url),
    },
    body: formData,
  };
  return fetch(API_BASE_URL + url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url, body) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...getHeader(),
      ...authHeader(url),
    },
    body: JSON.stringify(body),
  };
  return fetch(API_BASE_URL + url, requestOptions).then(handleResponse);
}

function getHeader() {
  return {
    "Content-Type": "application/json;charset=UTF-8",
  };
}

function getHeaderXLSX() {
  return {
    "Content-Type":
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  };
}

function authHeader(url) {
  const isAuthen = JSON.parse(sessionStorage.getItem("user-abm"));
  if (isAuthen) {
    return { "X-Session-Token": `${isAuthen}` };
  } else {
    return {};
  }
}

function handleResponse(response) {
  return response.text().then((text) => {
    let data;
    try {
      data = text && JSON.parse(text);
    } catch (e) {
      data = text;
    }

    if (data?.error?.status === 401 || data?.error?.status === 404) {
      userService.logout();
    }

    if (!response.ok) {
      if (data.message) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      } else {
        return Promise.reject(data);
      }
    }
    return data;
  });
}

function postByParam(url, formData) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(url),
    },
    body: formData,
  };
  return fetch(API_BASE_URL + url, requestOptions).then(handleResponse);
}
