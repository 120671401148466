import React from "react";
import { Card, CardContent, Container, Grid, Icon } from "@mui/material";
import ImageHome from "../../assets/Frame-4.png";
import ImageHome2 from "../../assets/Frame-3.png";
import ImageHome3 from "../../assets/Frame-2.png";
import ImageHome4 from "../../assets/Frame-1.png";
import ImageHome5 from "../../assets/Frame.png";
import ImageHome6 from "../../assets/043-mower.png";
import ImageHome7 from "../../assets/014-carrot.png";
import ImageHome8 from "../../assets/017-vegetables.png";
import { Col, Image, Row } from "react-bootstrap";
import "../../scss/_homePage.scss";
import MediaQuery from "react-responsive";

const HomePage = () => {
  return (
    <div>
      <MediaQuery minWidth={851}>
        <Card sx={{ minWidth: 275, height: "fit-content" }}>
          <CardContent className="clickhere">
            <Row container spacing={2} className="show-first">
              <Col xs={8}>
                <div className="text-left p-5 show-two pb-0">
                  <p className="text-topic fs-2 mb-0">
                    โครงการพัฒนาเครื่องมือเพื่อวิเคราะห์ผลกระทบ
                  </p>
                  <p className="text-topic fs-2 mb-0">
                    จากการใช้นโยบาย/มาตรการทางการเกษตร
                  </p>
                </div>
                <div className="showme text-left p-5 pt-1">
                  <p style={{ fontSize: 15 }}>
                    โครงการได้พัฒนาแบบจำลอง Agent-Based Model (ABM)
                    เพื่ออธิบายผลกระทบที่เกี่ยวกับการตัดสินใจใช้ที่ดินและเศรษฐานะของครัวเรือนเกษตรจากการใช้นโยบาย/มาตรการทางการเกษตรกรรมเชิงพื้นที่โดยกำหนด
                    ให้พฤติกรรมการตัดสินใจของตัวแทน (Agents)
                    หรือครัวเรือนเกษตรกรในโลกเสมือนแตกต่างกันจากความแตกต่าง
                    และระหว่างบุคคล (Heterogeneity)
                    การปฏิสัมพันธ์ในท้องถิ่นระหว่างบุคคล (Interaction)
                    และการปรับพฤติกรรมของบุคคล (Adaptation)
                    ตามสภาพแวดล้อมที่เปลี่ยนแปลงไป
                  </p>
                </div>
              </Col>
              <Col xs={4}>
                <Image src={ImageHome} className="w-img" />
              </Col>
            </Row>
          </CardContent>
        </Card>
      </MediaQuery>
      <MediaQuery maxWidth={850}>
        <Card sx={{ width: 460, height: "fit-content" }}>
          <CardContent className="clickhere">
            <Row>
              <Col>
                <div className="text-left p-5 pb-0" style={{ fontSize: 15 }}>
                  <p className="text-topic mb-0">
                    โครงการพัฒนาเครื่องมือเพื่อวิเคราะห์ผลกระทบ
                  </p>
                  <p className="text-topic mb-0">
                    จากการใช้นโยบาย/มาตรการทางการเกษตร
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-left p-5 pt-1">
                  <p style={{ fontSize: 12 }}>
                    โครงการได้พัฒนาแบบจำลอง Agent-Based Model (ABM)
                    เพื่ออธิบายผลกระทบที่เกี่ยวกับการตัดสินใจใช้ที่ดินและเศรษฐานะของครัวเรือนเกษตรจากการใช้นโยบาย/มาตรการทางการเกษตรกรรมเชิงพื้นที่โดยกำหนด
                    ให้พฤติกรรมการตัดสินใจของตัวแทน (Agents)
                    หรือครัวเรือนเกษตรกรในโลกเสมือนแตกต่างกันจากความแตกต่าง
                    และระหว่างบุคคล (Heterogeneity)
                    การปฏิสัมพันธ์ในท้องถิ่นระหว่างบุคคล (Interaction)
                    และการปรับพฤติกรรมของบุคคล (Adaptation)
                    ตามสภาพแวดล้อมที่เปลี่ยนแปลงไป
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Image src={ImageHome} className="" width={200} />
              </Col>
            </Row>
          </CardContent>
        </Card>
      </MediaQuery>

      <MediaQuery minWidth={851}>
        <Card sx={{ minWidth: 275, height: 450 }} className="b-g mt-3">
          <CardContent className="clickhere2">
            <Row container spacing={2}>
              <Col>
                <div className="text-left pd-3-side text-white">
                  <p className="fs-3 mb-0">ข้อมูลนำเข้า</p>
                  <p style={{ fontSize: 15 }} className="text-white">
                    เนื่องจากโครงการฯ
                    ต้องการจำลองโลกเสมือนของครัวเรือนเกษตรกรการพัฒนาแบบจำลองจำเป็นต้องใช้ข้อมูลพฤติกรรมและสิ่งแวดล้อมที่ครัวเรือนเกษตรกรเผชิญอยู่เพื่อการสอบเทียบ
                    (Calibration) แบบจำลอง ดังนี้
                  </p>
                </div>
              </Col>
            </Row>

            <Row container spacing={2}>
              <Col>
                <div className="d-flex">
                  <div className="showme p-5">
                    <Image src={ImageHome2} className="w-img" />
                  </div>
                  <div className="text-left p-5 text-white mb-0 show-two">
                    <p style={{ fontSize: 15 }} className="text-white mb-0">
                      · ข้อมูลภาวะเศรษฐกิจสังคมครัวเรือนและแรงงานเกษตร (สศก.)
                    </p>
                    <p style={{ fontSize: 15 }} className="text-white mb-0">
                      · ข้อมูลราคาพืชผลทางการเกษตร (สศก.)
                    </p>
                    <p style={{ fontSize: 15 }} className="text-white mb-0">
                      · ข้อมูลจากฐานข้อมูลเกษตรกรกลาง (สศก.)
                    </p>
                    <p style={{ fontSize: 15 }} className="text-white mb-0">
                      · ข้อมูลอื่น ๆ
                      ที่เกี่ยวข้องกับพฤติกรรมการตัดสินใจของครัวเรือนเกษตรกร
                      จากการลงพื้นที่สำรวจ (สศก.)
                    </p>
                    <p style={{ fontSize: 15 }} className="text-white mb-0">
                      · ข้อมูลสำมะโนเกษตร (สสช.)
                    </p>
                    <p style={{ fontSize: 15 }} className="text-white mb-0">
                      · ข้อมูลสำรวจภาวะเศรษฐกิจและสังคมของครัวเรือน (สสช.)
                    </p>
                    <p style={{ fontSize: 15 }} className="text-white">
                      · ข้อมูลเงินช่วยเหลือและการทำประกันภัยพืชผล (ธกส.)
                    </p>
                    <p style={{ fontSize: 15 }} className="text-white">
                      · ข้อมูลสภาพอากาศ NASA Prediction Of Worldwide Energy
                      Resources (POWER)
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </CardContent>
        </Card>
      </MediaQuery>
      <MediaQuery maxWidth={850}>
        <Card sx={{ width: 460, height: "fit-content" }} className="b-g mt-3">
          <CardContent className="">
            <Row>
              <Col>
                <div className="text-left pd-3-side text-white">
                  <p className="mb-0" style={{ fontSize: 15 }}>
                    ข้อมูลนำเข้า
                  </p>
                  <p style={{ fontSize: 12 }} className="text-white">
                    เนื่องจากโครงการฯ
                    ต้องการจำลองโลกเสมือนของครัวเรือนเกษตรกรการพัฒนาแบบจำลองจำเป็นต้องใช้ข้อมูลพฤติกรรมและสิ่งแวดล้อมที่ครัวเรือนเกษตรกรเผชิญอยู่เพื่อการสอบเทียบ
                    (Calibration) แบบจำลอง ดังนี้
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-left p-5 text-white mb-0 show-two">
                  <p style={{ fontSize: 15 }} className="text-white mb-0">
                    · ข้อมูลภาวะเศรษฐกิจสังคมครัวเรือนและแรงงานเกษตร (สศก.)
                  </p>
                  <p style={{ fontSize: 15 }} className="text-white mb-0">
                    · ข้อมูลราคาพืชผลทางการเกษตร (สศก.)
                  </p>
                  <p style={{ fontSize: 15 }} className="text-white mb-0">
                    · ข้อมูลจากฐานข้อมูลเกษตรกรกลาง (สศก.)
                  </p>
                  <p style={{ fontSize: 15 }} className="text-white mb-0">
                    · ข้อมูลอื่น ๆ
                    ที่เกี่ยวข้องกับพฤติกรรมการตัดสินใจของครัวเรือนเกษตรกร
                    จากการลงพื้นที่สำรวจ (สศก.)
                  </p>
                  <p style={{ fontSize: 15 }} className="text-white mb-0">
                    · ข้อมูลสำมะโนเกษตร (สสช.)
                  </p>
                  <p style={{ fontSize: 15 }} className="text-white mb-0">
                    · ข้อมูลสำรวจภาวะเศรษฐกิจและสังคมของครัวเรือน (สสช.)
                  </p>
                  <p style={{ fontSize: 15 }} className="text-white">
                    · ข้อมูลเงินช่วยเหลือและการทำประกันภัยพืชผล (ธกส.)
                  </p>
                  <p style={{ fontSize: 15 }} className="text-white">
                    · ข้อมูลสภาพอากาศ NASA Prediction Of Worldwide Energy
                    Resources (POWER)
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Image src={ImageHome2} width={200} />
              </Col>
            </Row>
          </CardContent>
        </Card>
      </MediaQuery>

      <MediaQuery minWidth={851}>
        <div className="mt-3">
          <CardContent style={{ padding: 0 }}>
            <Row style={{ alignItems: "center" }}>
              <Col md={5}>
                <Card sx={{ height: 600 }} className="card-center-page">
                  <CardContent
                    style={{ alignItems: "center" }}
                    className="clickhere3"
                  >
                    <div className="show-two">
                      <p className="text-topic fs-2 mb-0">การนำไปใช้</p>
                      <p style={{ fontSize: 20 }}>"Resilience"</p>
                      <p style={{ fontSize: 20 }}>
                        นโยบายเกษตรสามารถช่วยให้เกษตรกรรับมือกับความเสี่ยงที่เกิดจากตลาดและสามารถปรับตัวให้เท่าทันต่อความเสี่ยงที่เกิดจากการเปลี่ยนแปลงของสภาพอากาศที่มีผลกระทบต่อการผลิตได้หรือไม่
                      </p>
                    </div>
                    <div className="showme">
                      <Image src={ImageHome3} className="w-img" />
                    </div>
                  </CardContent>
                </Card>
              </Col>
              <Col>
                <Card sx={{ height: 600 }} className="card-center-page">
                  <CardContent className="clickhere3">
                    <div className="show-two">
                      <p className="text-topic fs-2 mb-0">ผลลัพธ์</p>
                      <p style={{ fontSize: 20 }}>
                        ประกอบด้วย การใช้ประโยชน์ที่ดิน
                        เศรษฐานะของครัวเรือนการตัดสินใจของครัวเรือน
                        ภายใต้สถานการณ์ความเสี่ยงและนโยบาย/มาตรการทางการเกษตร
                      </p>
                    </div>
                    <div className="showme">
                      <Image src={ImageHome4} width={500} />
                    </div>
                  </CardContent>
                </Card>
              </Col>
            </Row>
          </CardContent>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={850}>
        <Row className="mt-3">
          <Col>
            <Card
              sx={{ height: "fit-content", width: 460 }}
              className="card-center-page"
            >
              <CardContent style={{ alignItems: "center" }}>
                <div className="show-two">
                  <p className="text-topic fs-2 mb-0">การนำไปใช้</p>
                  <p style={{ fontSize: 20 }}>"Resilience"</p>
                  <p style={{ fontSize: 20 }}>
                    นโยบายเกษตรสามารถช่วยให้เกษตรกรรับมือกับความเสี่ยงที่เกิดจากตลาดและสามารถปรับตัวให้เท่าทันต่อความเสี่ยงที่เกิดจากการเปลี่ยนแปลงของสภาพอากาศที่มีผลกระทบต่อการผลิตได้หรือไม่
                  </p>
                </div>
                <div className="">
                  <Image src={ImageHome3} width={200} className="mt-4" />
                </div>
              </CardContent>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Card
              sx={{ height: "fit-content", width: 460 }}
              className="card-center-page"
            >
              <CardContent>
                <div className="">
                  <p className="text-topic fs-2 mb-0">ผลลัพธ์</p>
                  <p style={{ fontSize: 20 }}>
                    ประกอบด้วย การใช้ประโยชน์ที่ดิน
                    เศรษฐานะของครัวเรือนการตัดสินใจของครัวเรือน
                    ภายใต้สถานการณ์ความเสี่ยงและนโยบาย/มาตรการทางการเกษตร
                  </p>
                </div>
                <div className="showme">
                  <Image src={ImageHome4} width={200} className="mt-4" />
                </div>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </MediaQuery>

      <MediaQuery minWidth={851}>
        <Card sx={{ minWidth: 275, height: 450 }} className="b-g mt-3">
          <CardContent className="clickhere4">
            <Row container spacing={2}>
              <Col>
                <div>
                  <div className="p-3 text-white text-center">
                    <p className="fs-3">กระบวนการภายในแบบจำลอง</p>
                    <p
                      style={{ fontSize: 15 }}
                      className="text-white mb-3 mt-3"
                    >
                      แนวทางในการออกแบบกระบวนการตัดสินใจด้วย Overview, Design
                      Concepts, Details, and Decision-making (ODD+D) Protocol
                    </p>
                    <div
                      className="d-flex"
                      style={{ width: 400, margin: "auto" }}
                    >
                      <div className="show-two" style={{textAlign: "start"}}>
                        <p
                          style={{ fontSize: 15, width: 400 }}
                          className="text-white"
                        >
                          โปรโตคอล ODD+D
                          เป็นเฟรมเวิร์กมาตรฐานสำหรับการอธิบายแบบจำลองตัวแทน
                          หรือ Agent-Based Model (ABM) ซึ่งครอบคลุมภาพรวม
                          แนวคิดการออกแบบ รายละเอียด และการตัดสินใจ
                          โดยมีจุดมุ่งหมายเพื่อช่วยถ่ายทอดแนวทางการสร้างแบบจำลอง
                          การจำลองแบบ และการทำความเข้าใจแบบจำลอง ABMs โดย
                          โปรโตคอล ODD+D
                          มีแนวทางที่ครอบคลุมและโปร่งใสในการอธิบายวัตถุประสงค์
                          เอนทิตี แนวคิดการออกแบบ และการเริ่มต้นของโมเดล
                          ในขณะที่เน้นที่กระบวนการตัดสินใจของตัวแทนอย่างชัดเจน
                          ด้วยการใช้โปรโตคอล ODD+D
                          นักพัฒนาแบบจำลองสามารถแบ่งปันแบบจำลองของตนได้อย่างมีประสิทธิภาพและส่งเสริมความเข้าใจที่ลึกซึ้งยิ่งขึ้นเกี่ยวกับพฤติกรรมของตัวแทนและพลวัตของแบบจำลองภายใต้สถานการณ์จริงให้มากที่สุด
                        </p>
                      </div>
                      <div className="showme">
                        <Image src={ImageHome5} width={400} />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </CardContent>
        </Card>
      </MediaQuery>
      <MediaQuery maxWidth={850}>
        <Card
          sx={{ minWidth: 275, height: "fit-content" }}
          className="b-g mt-3"
        >
          <CardContent className="">
            <Row>
              <Col>
                <div className="p-3 text-white text-center">
                  <p style={{ fontSize: 15 }}>กระบวนการภายในแบบจำลอง</p>
                  <p style={{ fontSize: 12 }} className="text-white mb-3 mt-3">
                    แนวทางในการออกแบบกระบวนการตัดสินใจด้วย Overview, Design
                    Concepts, Details, and Decision-making (ODD+D) Protocol
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <div className="d-flex" style={{ width: 400, margin: "auto" }}>
                <div className="">
                  <p
                    style={{ fontSize: 12, width: 400 }}
                    className="text-white"
                  >
                    การตัดสินใจของมนุษย์มีความสำคัญขั้นพื้นฐานในแบบจำลอง ABM โดย
                    Muller et al. (2013) ขยายและปรับแต่งโปรโตคอล ODD ของ Grimm
                    et al. (20 10) เพื่อสร้างมาตรฐานสำหรับการอธิบาย ABM
                    ซึ่งรวมถึงการตัดสินใจของมนุษย์เป็นโปรโตคอล ODD + D
                    เพื่อให้จับภาพการตัดสินใจของมนุษย์ได้ดียิ่งขึ้น Muller et
                    al. (2013)
                    ขยายและจัดเรียงแนวคิดการออกแบบและคำถามชี้นำที่เกี่ยวข้องเพื่อสร้างความแตกต่างและอธิบายการตัดสินใจ
                    การปรับตัว
                    และการเรียนรู้ของตัวแทนในลักษณะที่ครอบคลุมและมีโครงสร้างชัดเจน
                    โปรโตคอล ODD + D ยังรวมส่วนเกี่ยวกับ Theoretical and
                    Empirical Background หรือภูมิหลังเชิงทฤษฎีและเชิงประจักษ์
                    เพื่อส่งเสริมการออกแบบแบบจำลองและสมมติฐานของแบบจำลองที่เกี่ยวข้องกับทฤษฎีมากขึ้น
                  </p>
                </div>
              </div>
            </Row>
            <Row>
              <Image src={ImageHome5} width={150} className="mt-4" />
            </Row>
          </CardContent>
        </Card>
      </MediaQuery>

      <MediaQuery minWidth={851}>
        <Card sx={{ minWidth: 275, height: 450 }} className="mt-3">
          <CardContent className="clickhere3">
            <Row
              container
              spacing={2}
              style={{
                alignItems: "center",
                height: 450,
              }}
            >
              <Col>
                <div className="text-left p-5 show-two">
                  <p className="text-topic fs-2 mb-0">
                    วิธีการวิเคราะห์นโยบาย/มาตรการ
                  </p>
                  <p style={{ fontSize: 15 }}>
                    แบบจำลอง ABM
                    ให้ผลลัพธ์ซึ่งเป็นผลกระทบจากนโยบายที่ผู้ใช้งานสามารถปรับค่าและเงื่อนไขการดำเนินนโยบายต่าง
                    ๆ ได้ ภายใต้การ เปลี่ยนแปลงของสภาพอากาศและราคาสินค้าเกษตร
                    ทำให้สามารถทำวิเคราะห์ผลลัพธ์ในหลาย ๆ เงื่อนไขได้
                    เพื่อหานโยบายที่สร้าง
                    ความสามารถให้กับครัวเรือนเกษตรในการรับมือต่อความเสี่ยงได้
                  </p>
                </div>
                <div className="showme">
                  <Row>
                    <Col>
                      <Image src={ImageHome6} width={100} />
                      <p className="text-topic">
                        ออกแบบการวิเคราะห์เพื่อทำการ
                        <br /> เปรียบเทียบผลกระทบของนโยบาย
                      </p>
                      <p style={{ fontSize: 13 }}>
                        กำหนด Baseline และ Varying
                        <br /> policy scenarios
                      </p>
                    </Col>
                    <Col>
                      <Image src={ImageHome7} width={100} />
                      <p className="text-topic">
                        คัดเลือกนโยบายเพื่อสร้างชุด
                        <br /> นโยบายที่ดีที่สุด
                      </p>
                      <p style={{ fontSize: 13 }}>
                        ทำ Robust check ด้วยการปรับและไม่
                        <br />
                        ปรับเปลี่ยนปัจจัย Climate และ Price
                        <br />
                        เพื่อดูผลลัพธ์การตัดสินใจและเศรษฐกิจ
                      </p>
                    </Col>
                    <Col>
                      <Image src={ImageHome8} width={100} />
                      <p className="text-topic">
                        ทำ Policy Stress testing เพื่อดู
                        <br /> Resilency ของเกษตรกร
                      </p>
                      <p style={{ fontSize: 13 }}>
                        ทำการ shock ปัจจัย Climate และ
                        <br />
                        Price เพื่อดูผลลัพธ์การตัดสินใจและ
                        <br />
                        เศษฐกิจ
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </CardContent>
        </Card>
      </MediaQuery>
      <MediaQuery maxWidth={850}>
        <Card sx={{ minWidth: 275, height: "fit-content" }} className="mt-3">
          <CardContent className="">
            <Row
              container
              spacing={2}
              style={{
                alignItems: "center",
              }}
            >
              <Col>
                <div className="text-left p-5 ">
                  <p className="text-topic mb-0" style={{ fontSize: 15 }}>
                    วิธีการวิเคราะห์นโยบาย/มาตรการ
                  </p>
                  <p style={{ fontSize: 12 }}>
                    แบบจำลอง ABM
                    ให้ผลลัพธ์ซึ่งเป็นผลกระทบจากนโยบายที่ผู้ใช้งานสามารถปรับค่าและเงื่อนไขการดำเนินนโยบายต่าง
                    ๆ ได้ ภายใต้การ เปลี่ยนแปลงของสภาพอากาศและราคาสินค้าเกษตร
                    ทำให้สามารถทำวิเคราะห์ผลลัพธ์ในหลาย ๆ เงื่อนไขได้
                    เพื่อหานโยบายที่สร้าง
                    ความสามารถให้กับครัวเรือนเกษตรในการรับมือต่อความเสี่ยงได้
                  </p>
                </div>
                <div className="showme">
                  <Row>
                    <Col>
                      <Image src={ImageHome6} width={100} />
                      <p className="text-topic" style={{ fontSize: 15 }}>
                        ออกแบบการวิเคราะห์เพื่อทำการ
                        <br /> เปรียบเทียบผลกระทบของนโยบาย
                      </p>
                      <p style={{ fontSize: 12 }}>
                        กำหนด Baseline และ Varying
                        <br /> policy scenarios
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <Image src={ImageHome7} width={100} />
                      <p className="text-topic" style={{ fontSize: 15 }}>
                        คัดเลือกนโยบายเพื่อสร้างชุด
                        <br /> นโยบายที่ดีที่สุด
                      </p>
                      <p style={{ fontSize: 12 }}>
                        ทำ Robust check ด้วยการปรับและไม่
                        <br />
                        ปรับเปลี่ยนปัจจัย Climate และ Price
                        <br />
                        เพื่อดูผลลัพธ์การตัดสินใจและเศรษฐกิจ
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <Image src={ImageHome8} width={100} />
                      <p className="text-topic" style={{ fontSize: 15 }}>
                        ทำ Policy Stress testing เพื่อดู
                        <br /> Resilency ของเกษตรกร
                      </p>
                      <p style={{ fontSize: 12 }}>
                        ทำการ shock ปัจจัย Climate และ
                        <br />
                        Price เพื่อดูผลลัพธ์การตัดสินใจและ
                        <br />
                        เศษฐกิจ
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </CardContent>
        </Card>
      </MediaQuery>
    </div>
  );
};

export default HomePage;
