import React, { useEffect, useState } from "react";
import { Card, CardContent } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import _ from "lodash";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import image1 from "../../../assets/facegft.png";
import pp1 from "../../../assets/pp1.jpg";
import pp2 from "../../../assets/pp2.jpg";
import pp3 from "../../../assets/pp3.jpg";
import pp4 from "../../../assets/pp4.jpg";
import pp5 from "../../../assets/pp5.jpg";

const InfoModal = (props) => {
  useEffect(() => {
    if (!_.isEmpty(props?.data)) {
      const { isOpen, type } = props?.data || {};
      // setData((prevState) => ({ ...prevState, ...props?.data }));
    }
  }, [props?.data]);

  const { isOpen, toggle, data } = props;

  const renderContentByType = () => {
    switch (props?.data?.type) {
      case "agent":
        return (
          <>
            <div className="text-center">
              <img src={pp1} className="img-thumbnail mb-3" width={"90%"} />
            </div>
            <p className="mx-2">{getContent(1)}</p>
          </>
        );
        break;

      case "landMarket":
        return (
          <>
            <div className="text-center">
              <img src={pp1} className="img-thumbnail mb-3" width={"90%"} />
            </div>
            <p className="mx-2">{getContent(2)}</p>
          </>
        );
        break;

      case "disaster":
        return (
          <>
            <div className="text-center">
              <img src={pp1} className="img-thumbnail mb-3" width={"90%"} />
            </div>
            <p className="mx-2">{getContent(3)}</p>
          </>
        );
        break;

      // case "landMarket":
      //   return (
      //     <>
      //       <div className="text-center">
      //         <img src={pp2} className="img-thumbnail mb-3" width={"90%"} />
      //       </div>
      //       <p className="mx-2">{getContent(4)}</p>
      //     </>
      //   );
      //   break;

      case "emergencySubsidyPolicy":
        return (
          <>
            <div className="text-center">
              <img src={pp2} className="img-thumbnail mb-3" width={"70%"} />
            </div>
            <p className="mx-2">{getContent(4)}</p>
          </>
        );
        break;

      case "supportAgicultureInsurancePolicy":
        return (
          <>
            <div className="text-center">
              <img src={pp3} className="img-thumbnail mb-3" width={"70%"} />
            </div>
            <p className="mx-2">{getContent(5)}</p>
          </>
        );
        break;

      case "incomeInsurancePolicy":
        return (
          <>
            <div className="text-center">
              <img src={pp4} className="img-thumbnail mb-3" width={"90%"} />
            </div>
            <p className="mx-2">{getContent(6)}</p>
          </>
        );
        break;

      case "climateSmartAgriculturePolicy":
        return (
          <>
            <div className="text-center">
              <img src={pp5} className="img-thumbnail mb-3" width={"70%"} />
            </div>
            <p className="mx-2">{getContent(7)}</p>
          </>
        );
        break;

      default:
        return "-";
        break;
    }
  };

  const getContent = (number) => {
    if (number === 1) {
      return (
        <>
          ตัวแทน (Agent) นับอยู่ในรูปของครัวเรือนเกษตร
          โดยการกำหนดค่าเริ่มต้นของตัวแทนที่แตกต่างกัน
          จะทำให้ตัวแทนมีเงื่อนไขในการตัดสินใจที่เกี่ยวข้องกับกิจกรรมทางการเกษตรที่แตกต่างกันในแต่ละคาบของการจำลอง
          โดยตัวแทนจะตัดสินใจในหลาย ๆ ด้าน อาทิ จะเพาะปลูกหรือไม่
          หากเลือกที่จะเพาะปลูก ตัวแทนจะเพาะปลูกพืชอะไร
          ใช้เทคโนโลยีหรือเครื่องจักรทางการเกษตรหรือไม่
          จะทำงานนอกภาคเกษตรเป็นจำนวนชั่วโมงเท่าไหร่ เป็นต้น
        </>
      );
    } else if (number === 2) {
      return (
        <>
          ในด้านนี้จะเป็นปัจจัยทางด้านตลาดที่ตัวแทนไม่สามารถควบคุมได้ อาทิ
          ราคาที่ดิน และเงินกู้จากธนาคาร เป็นต้น
          ซึ่งปัจจัยเหล่านี้มีอิทธิพลในการตัดสินใจของตัวแทนในแต่ละคาบของการจำลองสถานการณ์
          ไม่ว่าจะเป็นการลงทุนซื้อหรือขายที่ดิน
          การลงทุนซื้อเครื่องจักรทางการเกษตร
        </>
      );
    } else if (number === 3) {
      return (
        <>
          ภัยพิบัติเป็นปัจจัยทางด้านสิ่งแวดล้อมที่ตัวแทนไม่สามารถควบคุมได้
          ซึ่งจะมีผลต่อการเจริญเติบโตของพืชที่เกษตรกรเลือก
          การคาดการณ์ผลผลิตในอนาคตก่อนการเพาะปลูกจริง
          ความเชื่อเกี่ยวกับภัยพิบัติ และการเข้าร่วมมาตรการของรัฐต่าง ๆ
          ของเกษตรกร
        </>
      );
    } else if (number === 4) {
      return (
        <>
          มาตรการการจ่ายเงินช่วยเหลือเกษตรกรผู้ประสบภัยพิบัติกรณีฉุกเฉิน
          โดยเฉพาะอย่างยิ่งอาชีพเกษตรกรที่อาจได้รับความเสียหายในพืชผลผลิต
          จนไม่สามารถใช้เพาะปลูกได้ โดยเกษตรกรต้องขึ้นทะเบียน
          และปรับปรุงทะเบียนกับหน่วยงานที่กำกับดูแลเกษตรกรของกระทรวงเกษตรและสหกรณ์ก่อนเกิดภัยพิบัติแล้วเท่านั้น
          และพื้นที่ที่ประสบภัยด้านการเกษตรต้องอยู่ในพื้นที่ประกาศเขตการให้ความช่วยเหลือผู้ประสบภัยพิบัติกรณีฉุกเฉิน
        </>
      );
    } else if (number === 5) {
      return (
        <>
          มีการจัดทำโครงการประกันข้าวนาปีขึ้นตั้งแต่ปี พ.ศ. 2554 เป็นต้นมา
          เพื่อเป็นเครื่องมือในการบริหารความเสี่ยงในการประกอบอาชีพและเป็นการลดงบประมาณการเยียวยาความเสียหายกรณีเกิดภัยพิบัติ
          โดยรัฐบาลเป็นผู้ออกค่าเบี้ยประกันภัยให้บางส่วนและเกษตรกรออกในส่วนที่เหลือแต่สำหรับลูกค้าของธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร
          (ธ.ก.ส.) จะได้รับการสนับสนุน
          คณะรัฐมนตรีได้มีมติเห็นชอบโครงการประกันภัยข้าวนาปี
          และโครงการประกันภัยข้าวโพดเลี้ยงสัตว์ ปีการผลิต 2565
          เพื่อใช้เป็นเครื่องมือในการบริหารจัดการความเสี่ยงจากภัยธรรมชาติให้แก่เกษตรกรและเป็นการต่อยอดความช่วยเหลือจากภาครัฐ
          ซึ่งทั้ง 2 โครงการจะให้ความคุ้มครองภัยธรรมชาติทั้งหมด 7 ภัย ได้แก่
          น้ำท่วม หรือฝนตกหนักภัยแล้ง
          ฝนแล้งหรือฝนทิ้งช่วงลมพายุหรือพายุไต้ฝุ่นภัยอากาศหนาวหรือน้ำค้างแข็งลูกเห็บไฟไหม้
          และช้างป่า และภัยศัตรูพืชและโรคระบาด โดยแบ่งเป็นการรับประกันภัยพื้นฐาน
          (Tier 1) และการรับประกันภัยโดยภาคสมัครใจของเกษตรกร (Tier 2)
        </>
      );
    } else if (number === 6) {
      return (
        <>
          มาตรการนี้มีจุดประสงค์เพื่อประกันรายได้ให้แก่เกษตรกรให้มีรายได้ที่เหมาะสมตามราคาประกันส่วนกลาง
          และพัฒนากลไกตลาดสินค้าเกษตรให้มีเสถียรภาพ เช่น
          รัฐบาลชดเชยส่วนต่างระหว่างราคาประกันรายได้และเกณฑ์กลางอ้างอิง
          โดยประกันรายได้เกษตรกรผู้ปลูกพืชผลที่ต้องการช่วยเหลือ
          รวมทั้งการกำหนดปริมาณและราคาประกันรายได้เกษตรกรต่อครัวเรือน
          อีกทั้งมีเป้าหมายให้เกษตรกรมีรายได้จากผลผลิตทางการเกษตรข้าว ยางพารา
          มันสำปะหลัง ปาล์ม อ้อย และข้าวโพดเลี้ยงสัตว์ ด้วยการชดเชยประกันรายได้
          นอกจากข้าวนาปียังมีพืชผลทางการเกษตรอื่น ๆ เช่น
          โครงการประกันรายได้เกษตรกรชาวสวนยาง หรือโครงการประกันรายได้ปาล์มน้ำมัน
        </>
      );
    } else if (number === 7) {
      return (
        <>
          การเปลี่ยนแปลงสภาพภูมิอากาศส่งผลกระทบต่อผลผลิตทางการเกษตรที่พึ่งพาธรรมชาติและได้เพิ่มข้อจำกัดในการผลิตเพิ่มมากขึ้น
          อีกทั้งส่งผลกระทบต่อวิถีชีวิตของเกษตรกรและความมั่นคงทางอาหาร
          ประเทศไทยแหล่งผลิตข้าวที่สำคัญของโลก
          ดังนั้นจึงหลีกเลี่ยงไม่ได้ที่จะปรับตัวให้เท่าทันต่อการเปลี่ยนแปลงภูมิอากาศด้วยระบบเกษตรกรรมที่่เท่าทันต่อภูมิอากาศ
          (Climate Smart Agriculture: CSA)
          จึงเป็นแนวทางที่่สำคัญในการขับเคลื่อนเพื่อลดผลกระทบ
          และสร้างความมั่นคงทางด้านอาหาร
        </>
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: "5em", maxWidth: "45%", height: "100px" }}
    >
      <ModalHeader toggle={toggle}>
        <p className="text-topic-2">{data?.name}</p>
      </ModalHeader>
      <ModalBody className={"p-0"}>
        <Card
          sx={{
            minWidth: 275,
            height: "fit-content",
            overflow: "scroll",
            border: "0px",
            paddingBottom: "20px",
          }}
          variant="outlined"
        >
          <Container className="mt-4">{renderContentByType()}</Container>
        </Card>
      </ModalBody>
      {/*<ModalFooter>*/}
      {/*  <Button color="primary">Do Something</Button>{" "}*/}
      {/*  <Button color="secondary" onClick={toggle}>*/}
      {/*    Cancel*/}
      {/*  </Button>*/}
      {/*</ModalFooter>*/}
    </Modal>
  );
};

export default InfoModal;
