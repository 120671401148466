import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Container,
  Grid,
  Icon,
  Slider,
} from "@mui/material";
import ImageHome from "../../assets/Frame-4.png";
import { Col, Image, Row } from "react-bootstrap";
import "../../scss/_homePage.scss";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Chart,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import _ from "lodash";
import { Bar, Doughnut } from "react-chartjs-2";
import MediaQuery from "react-responsive";

import Form from "react-bootstrap/Form";
// import ChartDataLabels from "chartjs-plugin-datalabels";
import { CategoryScale } from "chart.js";
import { useResultContext } from "./index";
import options from "../../helpers/options";
import { resultService } from "../../services/result.service";
import { CollectionsBookmarkOutlined, Summarize } from "@mui/icons-material";
import ExportImage from "./ExportImage";
import Iframe from "react-iframe";
import { ToggleButton, ToggleButtonGroup } from "@mui/lab";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
ChartJS.register(ArcElement, Tooltip, Legend);

const Tab1 = () => {
  const {
    optionSimulation,
    selectSim1,
    setSelectSim1,
    selectSim2,
    setSelectSim2,
    fromTo,
    allStep,
    setPeriodTab1,
  } = useResultContext();

  const [period, setPeriod] = useState(1);
  const [dataResult1, setDataResult1] = useState([]);
  const [dataResult2, setDataResult2] = useState([]);
  const [dataAllStep1, setDataAllStep1] = useState([]);
  const [dataAllStep2, setDataAllStep2] = useState([]);
  const [doughnut1, setDoughnut1] = useState([]);
  const [doughnut2, setDoughnut2] = useState([]);
  const [doughnut1Label, setDoughnut1Label] = useState([]);
  const [doughnut2Label, setDoughnut2Label] = useState([]);
  const [doughnut1Color, setDoughnut1Color] = useState([]);
  const [doughnut2Color, setDoughnut2Color] = useState([]);
  const [cropsList, setCropsList] = useState([]);

  //income Chart
  const [labelsYears, setlabelYear] = useState([]);
  const [dataChartIncome1, setDataChartIncome1] = useState([]);
  const [dataChartIncome2, setDataChartIncome2] = useState([]);
  const [dataChartBudget1, setDataChartBudget1] = useState([]);
  const [dataChartBudget2, setDataChartBudget2] = useState([]);
  const [dataChartCrops1, setdataChartCrops1] = useState({
    ri1: [],
    ri2: [],
    ri3: [],
    ri4: [],
    mp: [],
    cf: [],
    sc: [],
    rb: [],
    op: [],
  });
  const [dataChartCrops2, setdataChartCrops2] = useState({
    ri1: [],
    ri2: [],
    ri3: [],
    ri4: [],
    mp: [],
    cf: [],
    sc: [],
    rb: [],
    op: [],
  });
  const [windowHeight, setWindowHeight] = useState(null);
  const [view, setView] = React.useState("list");

  const handleChange = (event, nextView) => {
    setView(nextView);
  };

  let refIncomeLong = useRef(null);
  let refIncome = useRef(null);
  let refCostLong = useRef(null);
  let refCost = useRef(null);
  let refCropsLong = useRef(null);
  let refCrops = useRef(null);

  useEffect(() => {
    setWindowHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    clearDataAllChart();
    getDataSimulate(period);
    getDataAllStep1();
    getDataAllStep2();
  }, [selectSim1, selectSim2]);

  useEffect(() => {
    if (!_.isEmpty(dataAllStep1) || !_.isEmpty(dataAllStep2)) {
      getLabelYearAllStep();
      handleIncomeChart();
      handleBudgetChart();
      handleChartCrops1();
      handleChartCrops2();
    }
  }, [dataAllStep1, dataAllStep2]);

  useEffect(() => {
    if (!_.isEmpty(doughnut1Label) || !_.isEmpty(doughnut2Label)) {
      var crops = [...new Set([...doughnut1Label, ...doughnut2Label])];
      let crplist = [];
      crops.map((name) => {
        let found = options.riceOptions.find(({ label }) => label === name);
        if (!_.isEmpty(found)) {
          crplist.push(found);
        }
      });
      setCropsList(crplist);
    }
  }, [doughnut1Label, doughnut2Label]);

  useEffect(() => {
    if (!_.isEmpty(dataResult1.countCrops)) {
      const dough1 = getPercentCrops(dataResult1.countCrops);
      setDoughnut1(dough1);
      const label1 = getLabelCrops(dataResult1.countCrops);
      setDoughnut1Label(label1);
      const color1 = getColorCrops(dataResult1.countCrops);
      setDoughnut1Color(color1);
    }
  }, [dataResult1]);

  useEffect(() => {
    if (!_.isEmpty(dataResult2.countCrops)) {
      const dough2 = getPercentCrops(dataResult2.countCrops);
      setDoughnut2(dough2);
      const label2 = getLabelCrops(dataResult2.countCrops);
      setDoughnut2Label(label2);
      const color2 = getColorCrops(dataResult2.countCrops);
      setDoughnut2Color(color2);
    }
  }, [dataResult2]);

  //Chart
  const clearDataAllChart = () => {
    setDataAllStep1([]);
    setDataAllStep2([]);

    setDataChartIncome1([]);
    setDataChartIncome2([]);
    setDataChartBudget1([]);
    setDataChartBudget2([]);
    setdataChartCrops1([]);
    setdataChartCrops2([]);
  };
  const getLabelYearAllStep = () => {
    let labels = [];
    // let allStep = allStep;
    // if (selectSim1?.allStep > selectSim2?.allStep) {
    //   allStep = selectSim1?.allStep;
    // } else {
    //   allStep = selectSim2?.allStep;
    // }
    for (let i = 1; i <= allStep; i++) {
      labels.push(i);
    }
    setlabelYear(labels);
  };

  const handleIncomeChart = () => {
    let income1 = [];
    dataAllStep1.map((item) => income1.push(item["incomeAvg"]));

    let income2 = [];
    dataAllStep2.map((item) => income2.push(item["incomeAvg"]));

    setDataChartIncome1(income1);
    setDataChartIncome2(income2);
  };

  const handleBudgetChart = () => {
    let budget1 = [];
    dataAllStep1.map((item) => {
      let sum = 0;
      sum =
        item.budgetPolicy1 +
        item.budgetPolicy2 +
        item.budgetPolicy3 +
        item.budgetPolicy4;
      budget1.push(sum);
    });

    let budget2 = [];
    dataAllStep2.map((item) => {
      let sum = 0;
      sum =
        item.budgetPolicy1 +
        item.budgetPolicy2 +
        item.budgetPolicy3 +
        item.budgetPolicy4;
      budget2.push(sum);
    });

    setDataChartBudget1(budget1);
    setDataChartBudget2(budget2);
  };

  const handleChartCrops1 = () => {
    let ri1_arr = [];
    let ri2_arr = [];
    let ri3_arr = [];
    let ri4_arr = [];
    let mp_arr = [];
    let cf_arr = [];
    let sc_arr = [];
    let rb_arr = [];
    let op_arr = [];
    if (!_.isEmpty(dataAllStep1)) {
      dataAllStep1.map((item, index) => {
        //Year
        // ======= ri1 =======
        let ri1_sum = 0;
        let ri1_find1 = findCrop(dataAllStep1, index, "ri1");
        ri1_sum += ri1_find1;
        ri1_arr.push(ri1_sum);

        // ======= ri2 =======
        let ri2_sum = 0;
        let ri2_find1 = findCrop(dataAllStep1, index, "ri2");
        ri2_sum += ri2_find1;
        ri2_arr.push(ri2_sum);

        // ======= ri3 =======
        let ri3_sum = 0;
        let ri3_find1 = findCrop(dataAllStep1, index, "ri3");
        ri3_sum += ri3_find1;
        ri3_arr.push(ri3_sum);

        // ======= ri4 =======
        let ri4_sum = 0;
        let ri4_find1 = findCrop(dataAllStep1, index, "ri4");
        ri4_sum += ri4_find1;
        ri4_arr.push(ri4_sum);

        // ======= mp =======
        let mp_sum = 0;
        let mp_find1 = findCrop(dataAllStep1, index, "mp");
        mp_sum += mp_find1;
        mp_arr.push(mp_sum);

        // ======= cf =======
        let cf_sum = 0;
        let cf_find1 = findCrop(dataAllStep1, index, "cf");
        cf_sum += cf_find1;
        cf_arr.push(cf_sum);

        // ======= sc =======
        let sc_sum = 0;
        let sc_find1 = findCrop(dataAllStep1, index, "sc");
        sc_sum += sc_find1;
        sc_arr.push(sc_sum);

        // ======= rb =======
        let rb_sum = 0;
        let rb_find1 = findCrop(dataAllStep1, index, "rb");
        rb_sum += rb_find1;
        rb_arr.push(rb_sum);

        // ======= op =======
        let op_sum = 0;
        let op_find1 = findCrop(dataAllStep1, index, "op");
        op_sum += op_find1;
        op_arr.push(op_sum);
      });
    }

    let newCrops = {
      ri1: ri1_arr,
      ri2: ri2_arr,
      ri3: ri3_arr,
      ri4: ri4_arr,
      mp: mp_arr,
      cf: cf_arr,
      sc: sc_arr,
      rb: rb_arr,
      op: op_arr,
    };
    setdataChartCrops1((prev) => ({ ...prev, ...newCrops }));
  };

  const handleChartCrops2 = () => {
    let ri1_arr = [];
    let ri2_arr = [];
    let ri3_arr = [];
    let ri4_arr = [];
    let mp_arr = [];
    let cf_arr = [];
    let sc_arr = [];
    let rb_arr = [];
    let op_arr = [];
    if (!_.isEmpty(dataAllStep2)) {
      dataAllStep2.map((item, index) => {
        //Year
        // ======= ri1 =======
        let ri1_sum = 0;
        let ri1_find2 = findCrop(dataAllStep2, index, "ri1");
        ri1_sum += ri1_find2;
        ri1_arr.push(ri1_sum);

        // ======= ri2 =======
        let ri2_sum = 0;
        let ri2_find2 = findCrop(dataAllStep2, index, "ri2");
        ri2_sum += ri2_find2;
        ri2_arr.push(ri2_sum);

        // ======= ri3 =======
        let ri3_sum = 0;
        let ri3_find2 = findCrop(dataAllStep2, index, "ri3");
        ri3_sum += ri3_find2;
        ri3_arr.push(ri3_sum);

        // ======= ri4 =======
        let ri4_sum = 0;
        let ri4_find2 = findCrop(dataAllStep2, index, "ri4");
        ri4_sum += ri4_find2;
        ri4_arr.push(ri4_sum);

        // ======= mp =======
        let mp_sum = 0;
        let mp_find2 = findCrop(dataAllStep2, index, "mp");
        mp_sum += mp_find2;
        mp_arr.push(mp_sum);

        // ======= cf =======
        let cf_sum = 0;
        let cf_find2 = findCrop(dataAllStep2, index, "cf");
        cf_sum += cf_find2;
        cf_arr.push(cf_sum);

        // ======= sc =======
        let sc_sum = 0;
        let sc_find2 = findCrop(dataAllStep2, index, "sc");
        sc_sum += sc_find2;
        sc_arr.push(sc_sum);

        // ======= rb =======
        let rb_sum = 0;
        let rb_find2 = findCrop(dataAllStep2, index, "rb");
        rb_sum += rb_find2;
        rb_arr.push(rb_sum);

        // ======= op =======
        let op_sum = 0;
        let op_find2 = findCrop(dataAllStep2, index, "op");
        op_sum += op_find2;
        op_arr.push(op_sum);
      });
    }

    let newCrops = {
      ri1: ri1_arr,
      ri2: ri2_arr,
      ri3: ri3_arr,
      ri4: ri4_arr,
      mp: mp_arr,
      cf: cf_arr,
      sc: sc_arr,
      rb: rb_arr,
      op: op_arr,
    };
    setdataChartCrops2((prev) => ({ ...prev, ...newCrops }));
  };

  const findCrop = (data, index, field) => {
    if (!_.isEmpty(data[index].countCrops)) {
      let found = data[index].countCrops.find(({ crop }) => crop === field);
      return found?.amount ? found?.amount : 0;
    } else {
      return 0;
    }
  };

  const getDataAllStep1 = () => {
    if (selectSim1) {
      const req = {
        simulationID: selectSim1?.id,
        from: 1,
        to: selectSim1?.allStep,
      };
      resultService.resultFromTo(req).then((res) => {
        const { success } = res;
        if (success) {
          const { entities } = res?.data || {};
          if (!_.isEmpty(entities)) {
            setDataAllStep1(entities);
          }
        }
      });
    }
  };

  const getDataAllStep2 = () => {
    if (selectSim2) {
      const req = {
        simulationID: selectSim2?.id,
        from: 1,
        to: selectSim2?.allStep,
      };
      resultService.resultFromTo(req).then((res) => {
        const { success } = res;
        if (success) {
          const { entities } = res?.data || {};
          if (!_.isEmpty(entities)) {
            setDataAllStep2(entities);
          }
        }
      });
    }
  };

  const getDataSimulate = (period) => {
    //context setState preriod in index
    setPeriodTab1(period);

    if (!_.isEmpty(selectSim1)) {
      handleAPIGetDataBySim1(period);
    }

    if (!_.isEmpty(selectSim2)) {
      handleAPIGetDataBySim2(period);
    }
  };

  const handleAPIGetDataBySim1 = (period) => {
    const req = {
      simulationID: selectSim1.id,
      step: period,
    };
    resultService.resultStep(req).then((res) => {
      const { success } = res;
      if (success) {
        const { entity } = res?.data || {};
        if (!_.isEmpty(entity)) {
          setDataResult1(entity);
        }
      }
    });
  };

  const handleAPIGetDataBySim2 = (period) => {
    const req = {
      simulationID: selectSim2.id,
      step: period,
    };
    resultService.resultStep(req).then((res) => {
      const { success } = res;
      if (success) {
        const { entity } = res?.data || {};
        if (!_.isEmpty(entity)) {
          setDataResult2(entity);
        }
      }
    });
  };

  const getColorCrops = (arr) => {
    let color = [];
    arr.map((item) => {
      let crop = item.crop;
      let found = options.riceOptions.find(({ value }) => value === crop);
      color.push(found?.color);
    });
    return color;
  };

  const getLabelCrops = (arr) => {
    let label = [];
    arr.map((item) => {
      let crop = item.crop;
      let found = options.riceOptions.find(({ value }) => value === crop);
      label.push(found?.label);
    });
    return label;
  };

  const getPercentCrops = (arr) => {
    let total = arr.reduce((accumulator, obj) => {
      return accumulator + obj.amount;
    }, 0);
    let res = [];
    arr.map((item) => {
      let percentOfItem = ((item.amount / total) * 100).toFixed(1);
      res.push(percentOfItem);
    });
    return res;
  };

  const handleSliderChange = (event, newValue) => {
    if (!_.isEmpty(selectSim1) || !_.isEmpty(selectSim2)) {
      setPeriod(newValue);
    }
  };

  const handleCommitted = () => {
    if (!_.isEmpty(selectSim1) || !_.isEmpty(selectSim2)) {
      getDataSimulate(period);
    }
  };

  const makePropDoughnut = (data, label, color) => {
    return {
      labels: label,
      datasets: [
        {
          label: "# of Votes",
          data: data,
          backgroundColor: color,
          borderWidth: 1,
        },
      ],
      text: "ฉากทัศน์หลัก",
    };
  };

  // const data1 = {
  //   labels: labelDoughnut,
  //   datasets: [
  //     {
  //       label: "# of Votes",
  //       data: [12, 19, 3, 5, 2, 3, 10],
  //       backgroundColor: colorDoughnut,
  //       borderWidth: 1,
  //     },
  //   ],
  //   text: "ฉากทัศน์หลัก",
  // };

  // const doughnutTextCenter = {
  //   id: "textCenter",
  //   beforeDatasetsDraw(chart, args, pluginOptions) {
  //     const { ctx, data } = chart;
  //     ctx.save();
  //     ctx.font = "bolder 30px sans-serif";
  //     ctx.fillStyle = "red";
  //     ctx.textAlign = "center";
  //     ctx.textBaseline = "middle";
  //     console.log(ctx);
  //     ctx.fillText(
  //       "text",
  //       chart.getDatasetMeta(0).data(0).x,
  //       chart.getDatasetMeta(0).data(0).y
  //     );
  //   },
  // };

  const doughnutTextCenter = (chartName) => {
    return {
      beforeDraw: function (chart) {
        let width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        let fontSize = (height / 220).toFixed(2);
        fontSize = fontSize - 0.1;
        ctx.font = fontSize + "em 'Prompt', sans-serif";
        ctx.textBaseline = "top";
        let text = chartName,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    };
  };

  const optionsDoughnut = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "#fff",
        formatter: (value) => {
          return value + "%";
        },
      },
      responsive: false,
      // responsiveAnimationDuration: 2,
      // maintainAspectRatio: true
      // afterDraw: function (chart) {
      //   const width = chart.width,
      //     height = chart.height,
      //     ctx = chart.ctx;
      //   ctx.restore();
      //   const fontSize = (height / 200).toFixed(2);
      //   ctx.font = fontSize + "em sans-serif";
      //   ctx.textBaseline = "top";
      //   const text = "ฉากทัศน์หลัก",
      //     textX = Math.round((width - ctx.measureText(text).width) / 2),
      //     textY = height / 2;
      //   ctx.fillText(text, textX, textY);
      //   ctx.save();
      // },
    },
  };

  const optionsBar1 = {
    plugins: {
      title: {
        display: true,
        text: "รายได้สุทธิเฉลี่ยต่อคาบ",
        font: {
          family: "'Prompt', sans-serif",
          size: 14,
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          font: {
            family: "'Prompt', sans-serif",
            size: 12,
          },
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: "บาท",
          font: {
            family: "'Prompt', sans-serif",
            size: 14,
          },
        },
      },
    },
    responsive: false,
    animation: false,
    width: "100%",
  };

  const optionsBar2 = {
    plugins: {
      title: {
        display: true,
        text: "งบประมาณรวม",
        font: {
          family: "'Prompt', sans-serif",
          size: 14,
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          font: {
            family: "'Prompt', sans-serif",
            size: 12,
          },
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: "บาท",
          font: {
            family: "'Prompt', sans-serif",
            size: 14,
          },
        },
      },
    },
    responsive: false,
    animation: false,
  };

  const optionsBar3 = {
    plugins: {
      // width: 1000,
      // height: 500,
      title: {
        display: true,
        text: "ผลผลิตทางการเกษตรแยกรายพืช",
        font: {
          family: "'Prompt', sans-serif",
          size: 14,
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          font: {
            family: "'Prompt', sans-serif",
            size: 13,
          },
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
    scales: {
      x: {
        // stacked: true,
      },
      y: {
        // stacked: true,
        title: {
          display: true,
          text: "ตัน",
          font: {
            family: "'Prompt', sans-serif",
            size: 14,
          },
        },
      },
    },
    responsive: false,
    animation: false,
  };

  const dataIncome = {
    labels: labelsYears,
    datasets: [
      {
        label: selectSim1?.label ? selectSim1?.label : "",
        data: dataChartIncome1,
        backgroundColor: "#EECC16",
        borderRadius: 5,
      },
      {
        label: selectSim2?.label ? selectSim2?.label : "",
        data: dataChartIncome2,
        backgroundColor: "#A4C61A",
        borderRadius: 5,
      },
    ],
  };

  const dataBudget = {
    labels: labelsYears,
    datasets: [
      {
        label: selectSim1?.label ? selectSim1?.label : "",
        data: dataChartBudget1,
        backgroundColor: "#4178BC",
        borderRadius: 5,
      },
      {
        label: selectSim2?.label ? selectSim2?.label : "",
        data: dataChartBudget2,
        backgroundColor: "#E8384F",
        borderRadius: 5,
      },
    ],
  };

  const labels = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
  ];

  const getDataSetBySim = (dataCrops, stackName) => {
    return [
      {
        label: stackName + ":ข้าวเจ้าหอมมะลิ",
        data: dataCrops.ri1,
        backgroundColor: "#FDAE33",
        stack: stackName,
      },
      {
        label: stackName + ":ข้าวเจ้าปทุมธานี 1",
        data: dataCrops.ri2,
        backgroundColor: "#ff5d5d",
        stack: stackName,
      },
      {
        label: stackName + ":ข้าวเจ้าขาวอื่น",
        data: dataCrops.ri3,
        backgroundColor: "#f6bb71",
        stack: stackName,
      },
      {
        label: stackName + ":ข้าวเหนียว",
        data: dataCrops.ri4,
        backgroundColor: "#707426",
        stack: stackName,
      },
      {
        label: stackName + ":ข้าวโพดเลี้ยงสัตว์",
        data: dataCrops.mp,
        backgroundColor: "#eecc16",
        stack: stackName,
      },
      {
        label: stackName + ":มันสำปะหลังโรงงาน",
        data: dataCrops.cf,
        backgroundColor: "#4178BC",
        stack: stackName,
      },
      {
        label: stackName + ":อ้อยโรงงาน",
        data: dataCrops.sc,
        backgroundColor: "#208ea3",
        stack: stackName,
      },
      {
        label: stackName + ":ยางพารา",
        data: dataCrops.rb,
        backgroundColor: "#37a862",
        stack: stackName,
      },
      {
        label: stackName + ":sปาล์มนํ้ามัน",
        data: dataCrops.op,
        backgroundColor: "#7a71f6",
        stack: stackName,
      },
    ];
  };

  const dataCrops = {
    labels: labelsYears,
    datasets: [
      ...getDataSetBySim(dataChartCrops1, "Sim1"),
      ...getDataSetBySim(dataChartCrops2, "Sim2"),
    ],
  };

  const plugins = [
    {
      beforeDraw: function (chart) {
        const width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        const fontSize = (height / 200).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        const text = "ฉากทัศน์หลัก",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
      datalabels: {
        formatter: (value) => {
          return value + "%";
        },
      },
    },
  ];

  const number2digitWithCommas = (val) => {
    return val
      ? Number(val.toFixed(2)).toLocaleString("en", {
          minimumFractionDigits: 2,
        })
      : "0";
  };

  const numberx100 = (val) => {
    return val
      ? Number((val * 100).toFixed(2)).toLocaleString("en", {
          minimumFractionDigits: 0,
        })
      : "0";
  };

  const renderResultEntitiesCard = (simulation, result, options) => {
    return (
      <CardContent style={{ paddingBottom: 30 }}>
        <p className="text-topic text-left mb-2" style={{ fontSize: 20 }}>
          {/* ฉากทัศน์หลัก */}
          {simulation?.label ? simulation?.label : "-"}
        </p>
        <MediaQuery minWidth={851}>
          <Row style={{ display: "contents" }}>
            <Col>
              <Card
                style={{
                  backgroundColor: options.bg1,
                  height: 130,
                  padding: 20,
                }}
              >
                <div className="m-auto">
                  <p
                    className="text-white font-w-bold mb-2"
                    style={{ fontSize: 30 }}
                  >
                    รายได้สุทธิเฉลี่ยครัวเรือนเกษตร
                  </p>
                  <p
                    className="text-white font-w-bold"
                    style={{ fontSize: 30 }}
                  >
                    {/* 36,500 บาท/ครัวเรือน */}
                    {result?.incomeAvg
                      ? `${number2digitWithCommas(
                          result?.incomeAvg
                        )} บาท/ครัวเรือน`
                      : "0 บาท/ครัวเรือน"}
                  </p>
                </div>
              </Card>
            </Col>
            <Col>
              <Card
                style={{
                  backgroundColor: options.bg2,
                  height: 190,
                  padding: 10,
                }}
              >
                <Row>
                  <p
                    className="text-white font-w-bold mb-2"
                    style={{ fontSize: 20 }}
                  >
                    งบประมาณแยกตามนโยบาย/มาตรการทางการเกษตร
                  </p>
                </Row>
                <Row>
                  <Col>
                    <div className="div-in-card p-1">
                      <p
                        className="text-left text-white font-w-bold text-center"
                        style={{ fontSize: 18 }}
                      >
                        มาตรการการจ่ายเงินช่วยเหลือ ฯ
                      </p>
                      <p
                        className="text-white font-w-bold"
                        style={{ fontSize: 18 }}
                      >
                        {/* 50,000,000 บาท */}
                        {`${number2digitWithCommas(result?.budgetPolicy1)} บาท`}
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div className="div-in-card p-1">
                      <p
                        className="text-left text-white font-w-bold text-center"
                        style={{ fontSize: 18 }}
                      >
                        มาตรการสนับสนุนการทำประกันภัย ฯ
                      </p>
                      <p
                        className="text-white font-w-bold"
                        style={{ fontSize: 18 }}
                      >
                        {/* 60,000,000 บาท */}
                        {`${number2digitWithCommas(result?.budgetPolicy2)} บาท`}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <div className="div-in-card p-1">
                      <p
                        className="text-left text-white font-w-bold text-center"
                        style={{ fontSize: 18 }}
                      >
                        มาตรการรักษาเสถียรภาพรายได้ ฯ
                      </p>
                      <p
                        className="text-white font-w-bold"
                        style={{ fontSize: 18 }}
                      >
                        {/* 40,000,000 บาท */}
                        {`${number2digitWithCommas(result?.budgetPolicy3)} บาท`}
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div className="div-in-card p-1">
                      <p
                        className="text-left text-white font-w-bold text-center"
                        style={{ fontSize: 18 }}
                      >
                        มาตรการสนับสนุน CSA
                      </p>
                      <p
                        className="text-white font-w-bold"
                        style={{ fontSize: 18 }}
                      >
                        {/* 50,000,000 บาท */}
                        {`${number2digitWithCommas(result?.budgetPolicy4)} บาท`}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                style={{
                  backgroundColor: options.bg3,
                  height: 190,
                  padding: 10,
                }}
              >
                <Row>
                  <p
                    className="text-white font-w-bold mb-2"
                    style={{ fontSize: 20 }}
                  >
                    อัตราการเข้าร่วมแยกตามนโยบาย/มาตรการทางการเกษตร
                  </p>
                </Row>
                <Row>
                  <Col>
                    <div className="div-in-card p-1">
                      <p
                        className="text-left text-white font-w-bold text-center"
                        style={{ fontSize: 18 }}
                      >
                        มาตรการการจ่ายเงินช่วยเหลือ ฯ
                      </p>
                      <p
                        className="text-white font-w-bold"
                        style={{ fontSize: 18 }}
                      >
                        {`${numberx100(result?.participatePolicy1)} %`}
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div className="div-in-card p-1">
                      <p
                        className="text-left text-white font-w-bold text-center"
                        style={{ fontSize: 18 }}
                      >
                        มาตรการสนับสนุนการทำประกันภัย ฯ
                      </p>
                      <p
                        className="text-white font-w-bold"
                        style={{ fontSize: 18 }}
                      >
                        {`${numberx100(result?.participatePolicy2)} %`}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <div className="div-in-card p-1">
                      <p
                        className="text-left text-white font-w-bold text-center"
                        style={{ fontSize: 18 }}
                      >
                        มาตรการรักษาเสถียรภาพรายได้ ฯ
                      </p>
                      <p
                        className="text-white font-w-bold"
                        style={{ fontSize: 18 }}
                      >
                        {`${numberx100(result?.participatePolicy3)} %`}
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div className="div-in-card p-1">
                      <p
                        className="text-left text-white font-w-bold text-center"
                        style={{ fontSize: 18 }}
                      >
                        มาตรการสนับสนุน CSA
                      </p>
                      <p
                        className="text-white font-w-bold"
                        style={{ fontSize: 18 }}
                      >
                        {`${numberx100(result?.participatePolicy4)} %`}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </MediaQuery>
        <MediaQuery maxWidth={850}>
          <Row style={{ display: "contents" }}>
            <Col>
              <Card
                style={{
                  backgroundColor: options.bg1,
                  height: 190,
                  padding: 45,
                }}
              >
                <div className="m-auto">
                  <p
                    className="text-white font-w-bold mb-2"
                    style={{ fontSize: 20 }}
                  >
                    รายได้สุทธิเฉลี่ยครัวเรือนเกษตร
                  </p>
                  <p
                    className="text-white font-w-bold"
                    style={{ fontSize: 30 }}
                  >
                    {/* 36,500 บาท/ครัวเรือน */}
                    {result?.incomeAvg
                      ? `${number2digitWithCommas(
                          result?.incomeAvg
                        )} บาท/ครัวเรือน`
                      : "0 บาท/ครัวเรือน"}
                  </p>
                </div>
              </Card>
            </Col>
            <Col>
              <Card
                style={{
                  backgroundColor: options.bg2,
                  height: 190,
                  padding: 10,
                }}
              >
                <Row>
                  <p
                    className="text-white font-w-bold mb-2"
                    style={{ fontSize: 20 }}
                  >
                    งบประมาณแยกตามนโยบาย/มาตรการทางการเกษตร
                  </p>
                </Row>
                <Row>
                  <Col>
                    <div className="div-in-card p-2">
                      <p
                        className="text-left text-white font-w-bold text-center"
                        style={{ fontSize: 14 }}
                      >
                        มาตรการการจ่ายเงินช่วยเหลือ ฯ
                      </p>
                      <p
                        className="text-white font-w-bold"
                        style={{ fontSize: 12 }}
                      >
                        {/* 50,000,000 บาท */}
                        {`${number2digitWithCommas(result?.budgetPolicy1)} บาท`}
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div className="div-in-card p-2">
                      <p
                        className="text-left text-white font-w-bold text-center"
                        style={{ fontSize: 14 }}
                      >
                        มาตรการสนับสนุนการทำประกันภัย ฯ
                      </p>
                      <p
                        className="text-white font-w-bold"
                        style={{ fontSize: 12 }}
                      >
                        {/* 60,000,000 บาท */}
                        {`${number2digitWithCommas(result?.budgetPolicy2)} บาท`}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <div className="div-in-card p-2">
                      <p
                        className="text-left text-white font-w-bold text-center"
                        style={{ fontSize: 14 }}
                      >
                        มาตรการรักษาเสถียรภาพรายได้ ฯ
                      </p>
                      <p
                        className="text-white font-w-bold"
                        style={{ fontSize: 12 }}
                      >
                        {/* 40,000,000 บาท */}
                        {`${number2digitWithCommas(result?.budgetPolicy3)} บาท`}
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div className="div-in-card p-2">
                      <p
                        className="text-left text-white font-w-bold text-center"
                        style={{ fontSize: 14 }}
                      >
                        มาตรการสนับสนุน CSA
                      </p>
                      <p
                        className="text-white font-w-bold"
                        style={{ fontSize: 12 }}
                      >
                        {/* 50,000,000 บาท */}
                        {`${number2digitWithCommas(result?.budgetPolicy4)} บาท`}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                style={{
                  backgroundColor: options.bg3,
                  height: 190,
                  padding: 10,
                }}
              >
                <Row>
                  <p
                    className="text-white font-w-bold mb-2"
                    style={{ fontSize: 20 }}
                  >
                    อัตราการเข้าร่วมแยกตามนโยบาย/มาตรการทางการเกษตร
                  </p>
                </Row>
                <Row>
                  <Col>
                    <div className="div-in-card p-2">
                      <p
                        className="text-left text-white font-w-bold text-center"
                        style={{ fontSize: 14 }}
                      >
                        มาตรการการจ่ายเงินช่วยเหลือ ฯ
                      </p>
                      <p
                        className="text-white font-w-bold"
                        style={{ fontSize: 12 }}
                      >
                        {`${numberx100(result?.participatePolicy1)} %`}
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div className="div-in-card p-2">
                      <p
                        className="text-left text-white font-w-bold text-center"
                        style={{ fontSize: 14 }}
                      >
                        มาตรการสนับสนุนการทำประกันภัย ฯ
                      </p>
                      <p
                        className="text-white font-w-bold"
                        style={{ fontSize: 12 }}
                      >
                        {`${numberx100(result?.participatePolicy2)} %`}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <div className="div-in-card p-2">
                      <p
                        className="text-left text-white font-w-bold text-center"
                        style={{ fontSize: 14 }}
                      >
                        มาตรการรักษาเสถียรภาพรายได้ ฯ
                      </p>
                      <p
                        className="text-white font-w-bold"
                        style={{ fontSize: 12 }}
                      >
                        {`${numberx100(result?.participatePolicy3)} %`}
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div className="div-in-card p-2">
                      <p
                        className="text-left text-white font-w-bold text-center"
                        style={{ fontSize: 14 }}
                      >
                        มาตรการสนับสนุน CSA
                      </p>
                      <p
                        className="text-white font-w-bold"
                        style={{ fontSize: 12 }}
                      >
                        {`${numberx100(result?.participatePolicy4)} %`}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </MediaQuery>
      </CardContent>
    );
  };

  return (
    <div>
      <Card
        sx={{
          minWidth: 275,
        }}
        className="p-down mt-3"
      >
        <CardContent style={{ paddingBottom: 10 }}>
          <Row>
            <Col>
              <p className="text-topic-2 text-left">
                เลือกช่วงเวลาของการจำลองสถานการณ์
              </p>
              <div style={{ backgroundColor: "#20AAEA26" }}>
                <div className="w-90">
                  <p>คาบที่ {period} (1 คาบ = 6 เดือน)</p>
                  <Row>
                    <Col md={1}>
                      <p style={{ paddingTop: 4 }}>1</p>
                    </Col>
                    <Col>
                      <Slider
                        value={typeof period === "number" ? period : 1}
                        onChange={handleSliderChange}
                        onChangeCommitted={handleCommitted}
                        aria-labelledby="input-slider"
                        min={1}
                        max={fromTo?.to ? parseInt(fromTo?.to) : 13}
                      />
                    </Col>
                    <Col md={1}>
                      <p style={{ paddingTop: 4 }}>
                        {fromTo?.to ? parseInt(fromTo?.to) : 13}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            {/*<Col>*/}
            {/*  <Row className="mt-2">*/}
            {/*    <Col>*/}
            {/*      <Form.Select aria-label="Default select example" size="sm">*/}
            {/*        <option>ขนาดครัวเรือน</option>*/}
            {/*        <option value="1">One</option>*/}
            {/*        <option value="2">Two</option>*/}
            {/*        <option value="3">Three</option>*/}
            {/*      </Form.Select>*/}
            {/*    </Col>*/}
            {/*    <Col>*/}
            {/*      <Form.Select aria-label="Default select example" size="sm">*/}
            {/*        <option>ภาระหนี้</option>*/}
            {/*        <option value="1">One</option>*/}
            {/*        <option value="2">Two</option>*/}
            {/*        <option value="3">Three</option>*/}
            {/*      </Form.Select>*/}
            {/*    </Col>*/}
            {/*  </Row>*/}
            {/*  <Row className="mt-3">*/}
            {/*    <Col>*/}
            {/*      <Form.Select aria-label="Default select example" size="sm">*/}
            {/*        <option>กลุ่มรายได้</option>*/}
            {/*        <option value="1">One</option>*/}
            {/*        <option value="2">Two</option>*/}
            {/*        <option value="3">Three</option>*/}
            {/*      </Form.Select>*/}
            {/*    </Col>*/}
            {/*    <Col>*/}
            {/*      <Form.Select aria-label="Default select example" size="sm">*/}
            {/*        <option>ประเภทพืช</option>*/}
            {/*        <option value="1">One</option>*/}
            {/*        <option value="2">Two</option>*/}
            {/*        <option value="3">Three</option>*/}
            {/*      </Form.Select>*/}
            {/*    </Col>*/}
            {/*  </Row>*/}
            {/*</Col>*/}
          </Row>
        </CardContent>
      </Card>
      {/* {JSON.stringify(selectSim1, 1, 1)}
      <br />
      {JSON.stringify(selectSim2, 1, 1)} */}
      <Row>
        <Col sm={12} md={12} lg={4}>
          <Card
            sx={{
              // minWidth: 275,
              height: 700,
            }}
            className="p-down mt-3"
          >
            <CardContent style={{ paddingBottom: 10 }}>
              <Row>
                <Col>
                  <div className="d-flex">
                    <Form.Group className="mb-3 w-50">
                      <Form.Select
                        style={{ color: "#0c4c9f" }}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value) {
                            const select = optionSimulation.find(
                              ({ id }) => id === value
                            );
                            setSelectSim1(select);
                          }
                        }}
                      >
                        <option value="" disabled selected>
                          เลือกฉากทัศน์
                        </option>
                        {optionSimulation.map((sim) => (
                          <option
                            key={sim.id}
                            value={sim?.id}
                            selected={selectSim1?.id === sim?.id}
                          >
                            {sim?.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <Row>
                <Iframe
                  url={`https://simulationabm.oae.go.th/?sim_id=${selectSim1?.id}`}
                  width="640px"
                  height="700px"
                  id=""
                  className=""
                  display="block"
                  position="relative"
                />
              </Row>
            </CardContent>
          </Card>
        </Col>
        <Col sm={12} md={12} lg={4}>
          <Card
            sx={{
              // minWidth: 275,
              height: 700,
            }}
            className="p-down mt-3"
          >
            <CardContent style={{ paddingBottom: 10 }}>
              <Row>
                <Col>
                  <div className="d-flex">
                    <Form.Group className="mb-3 w-50">
                      <Form.Select
                        style={{ color: "#0c4c9f" }}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value) {
                            const select = optionSimulation.find(
                              ({ id }) => id === value
                            );
                            setSelectSim2(select);
                          }
                        }}
                      >
                        <option value="" disabled selected>
                          เลือกฉากทัศน์
                        </option>
                        {optionSimulation.map((sim) => (
                          <option
                            key={sim.id}
                            value={sim?.id}
                            selected={selectSim2?.id === sim?.id}
                          >
                            {sim?.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <Row>
                <Iframe
                  url={`https://simulationabm.oae.go.th/?sim_id=${selectSim2?.id}`}
                  width="640px"
                  height="700px"
                  id=""
                  className=""
                  display="block"
                  position="relative"
                />
              </Row>
            </CardContent>
          </Card>
        </Col>
        <Col sm={12} md={12} lg={4}>
          <Card
            sx={{
              // minWidth: 275,
              height: 700,
            }}
            className="p-down mt-3"
          >
            <CardContent style={{ paddingBottom: 10 }}>
              <Row className="text-left pb-3 font-w-bold">
                <Col>ภาพรวมการใช้ประโยชน์ที่ดิน</Col>
              </Row>
              <Row style={{ marginTop: "35px" }}>
                {_.isEmpty(doughnut1) && _.isEmpty(doughnut2) ? (
                  <div className="m-auto d-flex justify-content-center mt-1 mb-3">
                    No Data
                  </div>
                ) : (
                  ""
                )}
                {/*<div className="w-50 m-auto d-flex justify-content-center mt-1 mb-3">*/}
                {!_.isEmpty(doughnut1) ? (
                  <div
                    className="m-auto d-flex justify-content-center mt-1 mb-3"
                    style={{
                      position: "relative",
                      width: "80vw",
                      height: "20vh",
                    }}
                  >
                    <Doughnut
                      width={300}
                      data={makePropDoughnut(
                        doughnut1,
                        doughnut1Label,
                        doughnut1Color
                      )}
                      options={optionsDoughnut}
                      plugins={[
                        doughnutTextCenter("ฉากทัศน์หลัก"),
                        // ChartDataLabels,
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}
              </Row>
              <Row style={{ marginTop: "5%" }}>
                {/*<div className="w-50 m-auto mt-2 d-flex justify-content-center mb-3">*/}
                {!_.isEmpty(doughnut2) ? (
                  <div
                    className="m-auto d-flex justify-content-center mt-1 mb-3"
                    style={{
                      position: "relative",
                      width: "80vw",
                      height: "20vh",
                    }}
                  >
                    <Doughnut
                      data={makePropDoughnut(
                        doughnut2,
                        doughnut2Label,
                        doughnut2Color
                      )}
                      options={optionsDoughnut}
                      plugins={[
                        doughnutTextCenter("ฉากทัศน์ที่ 1"),
                        // ChartDataLabels,
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}
              </Row>
              <Row style={{ position: "relative", bottom: -15 }}>
                {cropsList.map((item) => {
                  return (
                    <Col md="4">
                      <div className="d-flex">
                        <div
                          className="circle"
                          style={{ backgroundColor: item?.color }}
                        />
                        <p style={{ fontSize: 12 }}>{item?.label}</p>
                      </div>
                    </Col>
                  );
                })}
              </Row>
              {/* <Col>
                  <div className="d-flex">
                    <div
                      className="circle"
                      style={{ backgroundColor: "#FDAE33" }}
                    />
                    <p style={{ fontSize: 10 }}>ข้าวกล้อง</p>
                  </div>
                </Col>
                <Col>
                  <div className="d-flex">
                    <div
                      className="circle"
                      style={{ backgroundColor: "#EECC16" }}
                    />
                    <p style={{ fontSize: 10 }}>ข้าวโพด</p>
                  </div>
                </Col>
                <Col>
                  <div className="d-flex">
                    <div
                      className="circle"
                      style={{ backgroundColor: "#A4C61A" }}
                    />
                    <p style={{ fontSize: 10 }}>กัญชา</p>
                  </div>
                </Col> */}
              {/* </Row> */}
              {/* <Row>
                <Col>
                  <div className="d-flex">
                    <div
                      className="circle"
                      style={{ backgroundColor: "#37A862" }}
                    />
                    <p style={{ fontSize: 10 }}>ยางพารา</p>
                  </div>
                </Col>
                <Col>
                  <div className="d-flex">
                    <div
                      className="circle"
                      style={{ backgroundColor: "#208EA3" }}
                    />
                    <p style={{ fontSize: 10 }}>อ้อย</p>
                  </div>
                </Col>
                <Col>
                  <div className="d-flex">
                    <div
                      className="circle"
                      style={{ backgroundColor: "#4178BC" }}
                    />
                    <p style={{ fontSize: 10 }}>มันสำปะหลัง</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="d-flex">
                    <div
                      className="circle"
                      style={{ backgroundColor: "#7A71F6" }}
                    />
                    <p style={{ fontSize: 10 }}>ปาล์มน้ำมัน</p>
                  </div>
                </Col>
                <Col></Col>
                <Col></Col>
              </Row> */}
            </CardContent>
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
        <ToggleButtonGroup value={view} exclusive onChange={handleChange}>
          <ToggleButton value="list" aria-label="list">
            <ViewListIcon />
          </ToggleButton>
          <ToggleButton value="module" aria-label="module">
            <ViewModuleIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Row>

      <Row style={view === "list" ? null : { display: "none" }}>
        <Col>
          <Card className="p-down mt-3 chart-panel">
            <div className="m-auto" style={{ width: "99%", maxHeight: 310 }}>
              <ExportImage
                ref={refIncomeLong}
                fileName={"Income"}
                chartSettings={{
                  type: "bar",
                  data: dataIncome,
                  options: optionsBar1,
                }}
              />
              <CardContent>
                <Bar
                  id="canvas"
                  ref={refIncomeLong}
                  data={dataIncome}
                  options={{ ...optionsBar1, responsive: true }}
                  style={{ maxHeight: 300, width: "99%" }}
                />
              </CardContent>
            </div>
          </Card>
        </Col>
      </Row>

      <Row style={view === "list" ? null : { display: "none" }}>
        <Col>
          <Card className="p-down mt-3 chart-panel">
            <div className="m-auto" style={{ width: "99%", maxHeight: 310 }}>
              <CardContent>
                <ExportImage
                  ref={refCostLong}
                  fileName={"Cost"}
                  chartSettings={{
                    type: "bar",
                    data: dataBudget,
                    options: optionsBar2,
                  }}
                />
                <Bar
                  ref={refCostLong}
                  data={dataBudget}
                  options={{ ...optionsBar2, responsive: true }}
                  style={{ maxHeight: 300, width: "99%" }}
                />
              </CardContent>
            </div>
          </Card>
        </Col>
      </Row>

      <Row style={view === "list" ? null : { display: "none" }}>
        <Col>
          <Card className="p-down mt-3 chart-panel">
            <div className="m-auto" style={{ width: "99%", maxHeight: 310 }}>
              <CardContent>
                <ExportImage
                  ref={refCropsLong}
                  fileName={"Crops"}
                  chartSettings={{
                    type: "bar",
                    data: dataCrops,
                    options: optionsBar3,
                  }}
                />
                <Bar
                  ref={refCropsLong}
                  data={dataCrops}
                  options={{ ...optionsBar3, responsive: true }}
                  style={{ maxHeight: 300, width: "99%" }}
                />
              </CardContent>
            </div>
          </Card>
        </Col>
      </Row>

      <Row style={view === "list" ? { display: "none" } : null}>
        <Col sm={12} md={12} lg={4}>
          <Card className="p-down mt-3 chart-panel">
            <div className="m-auto">
              <ExportImage
                ref={refIncome}
                fileName={"Income"}
                chartSettings={{
                  type: "bar",
                  data: dataIncome,
                  options: optionsBar1,
                }}
              />
              <CardContent>
                <Bar
                  id="canvas"
                  ref={refIncome}
                  data={dataIncome}
                  options={{ ...optionsBar1, responsive: true }}
                />
              </CardContent>
            </div>
          </Card>
        </Col>
        <Col sm={12} md={12} lg={4}>
          <Card className="p-down mt-3 chart-panel">
            <div className="m-auto">
              <CardContent>
                <ExportImage
                  ref={refCost}
                  fileName={"Cost"}
                  chartSettings={{
                    type: "bar",
                    data: dataBudget,
                    options: optionsBar2,
                  }}
                />
                <Bar
                  ref={refCost}
                  data={dataBudget}
                  options={{ ...optionsBar2, responsive: true }}
                />
              </CardContent>
            </div>
          </Card>
          {/* <pre>{JSON.stringify(dataBudget, 1, 1)}</pre> */}
        </Col>
        <Col sm={12} md={12} lg={4}>
          <Card className="p-down mt-3 chart-panel">
            <div className="m-auto">
              <CardContent>
                <ExportImage
                  ref={refCrops}
                  fileName={"Crops"}
                  chartSettings={{
                    type: "bar",
                    data: dataCrops,
                    options: optionsBar3,
                  }}
                />
                <Bar
                  ref={refCrops}
                  data={dataCrops}
                  options={{ ...optionsBar3, responsive: true }}
                />
              </CardContent>
            </div>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card
            sx={{
              minWidth: 275,
              height: "fit-content",
            }}
            className="p-down mt-3"
          >
            {renderResultEntitiesCard(selectSim1, dataResult1, {
              bg1: "#37A862",
              bg2: "#62BB35",
              bg3: "#A4C61A",
            })}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card
            sx={{
              minWidth: 275,
              height: "fit-content",
            }}
            className="p-down mt-3"
          >
            {renderResultEntitiesCard(selectSim2, dataResult2, {
              bg1: "#EAC919",
              bg2: "#FDAE33",
              bg3: "#EA6C5B",
            })}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Tab1;
