const riceOptions = [
  { label: "ไม่เพาะปลูก", value: "no", color: "#686868" },
  { label: "ข้าวเจ้าหอมมะลิ", value: "ri1", color: "#FDAE33" },
  { label: "ข้าวเจ้าปทุมธานี 1", value: "ri2", color: "#ff5d5d" },
  { label: "ข้าวเจ้าขาวอื่น", value: "ri3", color: "#f6bb71" },
  { label: "ข้าวเหนียว", value: "ri4", color: "#707426" },
  { label: "ข้าวโพดเลี้ยงสัตว์", value: "mp", color: "#eecc16" },
  { label: "มันสำปะหลังโรงงาน", value: "cf", color: "#4178BC" },
  { label: "อ้อยโรงงาน", value: "sc", color: "#208ea3" },
  { label: "ยางพารา", value: "rb", color: "#37a862" },
  { label: "ปาล์มนํ้ามัน", value: "op", color: "#7a71f6" },
];

const cropsList = ["ri1", "ri2", "ri3", "ri4", "mp", "cf", "sc", "rb", "op"];

const yearFrom = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30,
];
const yearTo = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30,
];

const options = {
  riceOptions,
  cropsList,
  yearFrom,
  yearTo,
};

export default options;
