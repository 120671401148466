import React, { useState, useRef } from "react";
import { Card, CardContent } from "@mui/material";
import { Col, Image, Row } from "react-bootstrap";
import "../../scss/_homePage.scss";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Bar, Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  BarElement,
  ArcElement,
} from "chart.js";
import { value } from "lodash/seq";
import Select1 from "./selectChart/select1";
import Select2 from "./selectChart/select2";
import Select3 from "./selectChart/select3";
import { useResultContext } from ".";
import _ from "lodash";
import ExportImage from "./ExportImage";
import options from "../../helpers/options";

const labels = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
];

const data1 = {
  labels,
  datasets: [
    {
      label: "  ",
      data: [
        10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10,
        10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10,
      ],
      backgroundColor: "#79CFFF",
    },
    {
      label: "1",
      data: [
        10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30,
        10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10,
      ],
      backgroundColor: "#559FFF",
    },
    {
      label: "2",
      data: [
        30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10,
        30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30,
      ],
      backgroundColor: "#98C5FF",
    },
  ],
};

const data2 = {
  labels,
  datasets: [
    {
      label: "  ",
      data: [
        10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10,
        10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10,
      ],
      backgroundColor: "#FF7183",
    },
    {
      label: "1",
      data: [
        10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30,
        10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10,
      ],
      backgroundColor: "#C17575",
    },
    {
      label: "2",
      data: [
        30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10,
        30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30,
      ],
      backgroundColor: "#FF9B99",
    },
  ],
};

const data3 = {
  labels,
  datasets: [
    {
      label: "0",
      data: [
        10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10,
        10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10,
      ],
      backgroundColor: "#98ffd4",
    },
    {
      label: "1",
      data: [
        10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30,
        10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10,
      ],
      backgroundColor: "#60ff70",
    },
    {
      label: "2",
      data: [
        30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10,
        30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30,
      ],
      backgroundColor: "#e4ff79",
    },
  ],
};

const data4 = {
  labels,
  datasets: [
    {
      label: "นโยบาย 1",
      data: [
        10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10,
        10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10,
      ],
      backgroundColor: "#ddc56f",
    },
    {
      label: "นโยบาย 2",
      data: [
        10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30,
        10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10,
      ],
      backgroundColor: "#fff96a",
    },
    {
      label: "นโยบาย 3",
      data: [
        30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10,
        30, 10, 10, 30, 10, 10, 30, 10, 10, 30, 10, 10, 30,
      ],
      backgroundColor: "#ffd871",
    },
  ],
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  Filler
);

const dropdownStatic = [
  { name: "รายได้และหนี้สิน", value: 1 },
  { name: "จำนวนสมาชิกในครัวเรือน", value: 2 },
  { name: "ขนาดพื้นที่เพราะปลูก", value: 3 },
];

const StaticCross = () => {
  const {
    yearLabel,
    labelsYearChartArr,
    dataResult1,
    dataResult2,
    fromTo,
    selectSim1,
    selectSim2,
  } = useResultContext();

  const [selectChart, setSelectChart] = useState("1");

  let refBarIncome1 = useRef(null);
  let refBarIncome2 = useRef(null);
  let refBarDebt1 = useRef(null);
  let refBarDebt2 = useRef(null);

  const getArrayOfString = (arr, objName, cropName) => {
    let res = [];
    arr.map((item) => {
      if (!_.isEmpty(item?.[objName])) {
        const found = item?.[objName].find(({ crop }) => crop === cropName);
        if (found?.amount) {
          res.push(found?.amount);
        } else {
          res.push("");
        }
      }
    });
    return res;
  };

  const optionsIncome = {
    plugins: {
      datalabels: {
        formatter: (value) => {
          return nFormatter(value, 1) !== "0" ? nFormatter(value, 1) : "";
        },
        display: false,
        color: "#000000",
        font: {
          size: 7,
          family: "'Prompt', sans-serif",
        },
      },
      title: {
        display: true,
        text: "รายได้สุทธิ",
        font: {
          size: 14,
          family: "'Prompt', sans-serif",
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          font: {
            size: 11,
            family: "'Prompt', sans-serif",
          },
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          align: "center",
          display: true,
          text: "คาบ",
          font: {
            family: "'Prompt', sans-serif",
            size: 12,
          },
        },
      },
      y: {
        stacked: true,
        title: {
          align: "center",
          display: true,
          text: "บาท",
          font: {
            family: "'Prompt', sans-serif",
            size: 12,
          },
        },
      },
    },
    responsive: false,
    animation: false,
  };

  const optionsDebt = {
    plugins: {
      datalabels: {
        display: false,
        formatter: (value) => {
          return nFormatter(value, 1) !== "0" ? nFormatter(value, 1) : "";
        },
        display: false,
        color: "#000000",
        font: {
          size: 7,
          family: "'Prompt', sans-serif",
        },
      },
      title: {
        display: true,
        text: "หนี้สิน",
        font: {
          size: 14,
          family: "'Prompt', sans-serif",
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          font: {
            size: 11,
            family: "'Prompt', sans-serif",
          },
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          align: "center",
          display: true,
          text: "คาบ",
          font: {
            family: "'Prompt', sans-serif",
            size: 12,
          },
        },
      },
      y: {
        stacked: true,
        title: {
          align: "center",
          display: true,
          text: "บาท",
          font: {
            family: "'Prompt', sans-serif",
            size: 12,
          },
        },
      },
    },
    responsive: false,
    animation: false,
  };

  const findColorCrops = (val) => {
    let found = options.riceOptions.find(({ value }) => value === val);
    return found?.color ? found?.color : "";
  };

  const dataIncome1 = {
    labels: labelsYearChartArr(),
    datasets: [
      {
        label: "ไม่เพาะปลูก",
        data: getArrayOfString(dataResult1, "incomeCrops", "no"),
        backgroundColor: findColorCrops("no"),
      },
      {
        label: "ข้าวเจ้าหอมมะลิ",
        data: getArrayOfString(dataResult1, "incomeCrops", "ri1"),
        backgroundColor: findColorCrops("ri1"),
      },
      {
        label: "ข้าวเจ้าปทุมธานี 1",
        data: getArrayOfString(dataResult1, "incomeCrops", "ri2"),
        backgroundColor: findColorCrops("ri2"),
      },
      {
        label: "ข้าวเจ้าขาวอื่น",
        data: getArrayOfString(dataResult1, "incomeCrops", "ri3"),
        backgroundColor: findColorCrops("ri3"),
      },
      {
        label: "ข้าวเหนียว",
        data: getArrayOfString(dataResult1, "incomeCrops", "ri4"),
        backgroundColor: findColorCrops("ri4"),
      },
      {
        label: "ข้าวโพดเลี้ยงสัตว์",
        data: getArrayOfString(dataResult1, "incomeCrops", "mp"),
        backgroundColor: findColorCrops("mp"),
      },
      {
        label: "มันสำปะหลังโรงงาน",
        data: getArrayOfString(dataResult1, "incomeCrops", "cf"),
        backgroundColor: findColorCrops("cf"),
      },
      {
        label: "อ้อยโรงงาน",
        data: getArrayOfString(dataResult1, "incomeCrops", "sc"),
        backgroundColor: findColorCrops("sc"),
      },
      {
        label: "ยางพารา",
        data: getArrayOfString(dataResult1, "incomeCrops", "rb"),
        backgroundColor: findColorCrops("rb"),
      },
      {
        label: "ปาล์มนํ้ามัน",
        data: getArrayOfString(dataResult1, "incomeCrops", "op"),
        backgroundColor: findColorCrops("op"),
      },
    ],
  };
  const dataIncome2 = {
    labels: labelsYearChartArr(),
    datasets: [
      {
        label: "ไม่เพาะปลูก",
        data: getArrayOfString(dataResult2, "incomeCrops", "no"),
        backgroundColor: findColorCrops("no"),
      },
      {
        label: "ข้าวเจ้าหอมมะลิ",
        data: getArrayOfString(dataResult2, "incomeCrops", "ri1"),
        backgroundColor: findColorCrops("ri1"),
      },
      {
        label: "ข้าวเจ้าปทุมธานี 1",
        data: getArrayOfString(dataResult2, "incomeCrops", "ri2"),
        backgroundColor: findColorCrops("ri2"),
      },
      {
        label: "ข้าวเจ้าขาวอื่น",
        data: getArrayOfString(dataResult2, "incomeCrops", "ri3"),
        backgroundColor: findColorCrops("ri3"),
      },
      {
        label: "ข้าวเหนียว",
        data: getArrayOfString(dataResult2, "incomeCrops", "ri4"),
        backgroundColor: findColorCrops("ri4"),
      },
      {
        label: "ข้าวโพดเลี้ยงสัตว์",
        data: getArrayOfString(dataResult2, "incomeCrops", "mp"),
        backgroundColor: findColorCrops("mp"),
      },
      {
        label: "มันสำปะหลังโรงงาน",
        data: getArrayOfString(dataResult2, "incomeCrops", "cf"),
        backgroundColor: findColorCrops("cf"),
      },
      {
        label: "อ้อยโรงงาน",
        data: getArrayOfString(dataResult2, "incomeCrops", "sc"),
        backgroundColor: findColorCrops("sc"),
      },
      {
        label: "ยางพารา",
        data: getArrayOfString(dataResult2, "incomeCrops", "rb"),
        backgroundColor: findColorCrops("rb"),
      },
      {
        label: "ปาล์มนํ้ามัน",
        data: getArrayOfString(dataResult2, "incomeCrops", "op"),
        backgroundColor: findColorCrops("op"),
      },
    ],
  };

  const dataDept1 = {
    labels: labelsYearChartArr(),
    datasets: [
      {
        label: "ไม่เพาะปลูก",
        data: getArrayOfString(dataResult1, "debtCrops", "no"),
        backgroundColor: findColorCrops("no"),
      },
      {
        label: "ข้าวเจ้าหอมมะลิ",
        data: getArrayOfString(dataResult1, "debtCrops", "ri1"),
        backgroundColor: findColorCrops("ri1"),
      },
      {
        label: "ข้าวเจ้าปทุมธานี 1",
        data: getArrayOfString(dataResult1, "debtCrops", "ri2"),
        backgroundColor: findColorCrops("ri2"),
      },
      {
        label: "ข้าวเจ้าขาวอื่น",
        data: getArrayOfString(dataResult1, "debtCrops", "ri3"),
        backgroundColor: findColorCrops("ri3"),
      },
      {
        label: "ข้าวเหนียว",
        data: getArrayOfString(dataResult1, "debtCrops", "ri4"),
        backgroundColor: findColorCrops("ri4"),
      },
      {
        label: "ข้าวโพดเลี้ยงสัตว์",
        data: getArrayOfString(dataResult1, "debtCrops", "mp"),
        backgroundColor: findColorCrops("mp"),
      },
      {
        label: "มันสำปะหลังโรงงาน",
        data: getArrayOfString(dataResult1, "debtCrops", "cf"),
        backgroundColor: findColorCrops("cf"),
      },
      {
        label: "อ้อยโรงงาน",
        data: getArrayOfString(dataResult1, "debtCrops", "sc"),
        backgroundColor: findColorCrops("sc"),
      },
      {
        label: "ยางพารา",
        data: getArrayOfString(dataResult1, "debtCrops", "rb"),
        backgroundColor: findColorCrops("rb"),
      },
      {
        label: "ปาล์มนํ้ามัน",
        data: getArrayOfString(dataResult1, "debtCrops", "op"),
        backgroundColor: findColorCrops("op"),
      },
    ],
  };

  const dataDept2 = {
    labels: labelsYearChartArr(),
    datasets: [
      {
        label: "ไม่เพาะปลูก",
        data: getArrayOfString(dataResult2, "debtCrops", "no"),
        backgroundColor: findColorCrops("no"),
      },
      {
        label: "ข้าวเจ้าหอมมะลิ",
        data: getArrayOfString(dataResult2, "debtCrops", "ri1"),
        backgroundColor: findColorCrops("ri1"),
      },
      {
        label: "ข้าวเจ้าปทุมธานี 1",
        data: getArrayOfString(dataResult2, "debtCrops", "ri2"),
        backgroundColor: findColorCrops("ri2"),
      },
      {
        label: "ข้าวเจ้าขาวอื่น",
        data: getArrayOfString(dataResult2, "debtCrops", "ri3"),
        backgroundColor: findColorCrops("ri3"),
      },
      {
        label: "ข้าวเหนียว",
        data: getArrayOfString(dataResult2, "debtCrops", "ri4"),
        backgroundColor: findColorCrops("ri4"),
      },
      {
        label: "ข้าวโพดเลี้ยงสัตว์",
        data: getArrayOfString(dataResult2, "debtCrops", "mp"),
        backgroundColor: findColorCrops("mp"),
      },
      {
        label: "มันสำปะหลังโรงงาน",
        data: getArrayOfString(dataResult2, "debtCrops", "cf"),
        backgroundColor: findColorCrops("cf"),
      },
      {
        label: "อ้อยโรงงาน",
        data: getArrayOfString(dataResult2, "debtCrops", "sc"),
        backgroundColor: findColorCrops("sc"),
      },
      {
        label: "ยางพารา",
        data: getArrayOfString(dataResult2, "debtCrops", "rb"),
        backgroundColor: findColorCrops("rb"),
      },
      {
        label: "ปาล์มนํ้ามัน",
        data: getArrayOfString(dataResult2, "debtCrops", "op"),
        backgroundColor: findColorCrops("op"),
      },
    ],
  };

  const nFormatter = (num, digits) => {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  };

  return (
    <div>
      <Card
        sx={{
          minWidth: 275,
        }}
        className="p-down mt-3"
      >
        <CardContent style={{ paddingBottom: 10 }}>
          <p className="text-topic text-left mb-3" style={{ fontSize: 20 }}>
            สถิติไขว้: รายได้สุทธิและหนี้สินแยกตามการผลิต
          </p>
          <Row>
            <Col>
              {/* <p className="text-left font-w-bold">รายได้และหนี้สิน</p> */}
              {/* <Form.Group className="mb-3">
                <Form.Select
                  onChange={(e) => setSelectChart(e.target.value)}
                  style={{ color: "#0c4c9f" }}
                >
                  {dropdownStatic.map((item) => (
                    <option value={item.value}>{item.name}</option>
                  ))}
                </Form.Select>
              </Form.Group> */}
            </Col>
           
          </Row>
          <Row>
            <Col>
              <div className="text-left">
                <p className="font-w-bold">
                  {selectSim1?.label ? selectSim1?.label : "-"}
                </p>
              </div>
            </Col>
            <Col>
              <div className="text-left">
                <p className="font-w-bold">
                  {selectSim2?.label ? selectSim2?.label : "-"}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="border-card mt-3 text-center chart-panel">
                <div className="m-auto" style={{ width: 500 }}>
                  <ExportImage
                    ref={refBarIncome1}
                    fileName={"CrossIncome_Bar1"}
                    chartSettings={{
                      type: "bar",
                      data: dataIncome1,
                      options: optionsIncome,
                    }}
                  />
                  <Bar
                    ref={refBarIncome1}
                    data={dataIncome1}
                    options={{ ...optionsIncome, responsive: true }}
                    plugins={[ChartDataLabels]}
                  />
                </div>
              </div>
            </Col>
            <Col>
              <div className="border-card mt-3 text-center chart-panel">
                <div className="m-auto" style={{ width: 500 }}>
                  <ExportImage
                    ref={refBarIncome2}
                    fileName={"CrossIncome_Bar2"}
                    chartSettings={{
                      type: "bar",
                      data: dataIncome2,
                      options: optionsIncome,
                    }}
                  />
                  <Bar
                    ref={refBarIncome2}
                    data={dataIncome2}
                    options={{ ...optionsIncome, responsive: true }}
                    plugins={[ChartDataLabels]}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="border-card mt-3 text-center chart-panel">
                <div className="m-auto" style={{ width: 500 }}>
                  <ExportImage
                    ref={refBarDebt1}
                    fileName={"CrossDebt_Bar1"}
                    chartSettings={{
                      type: "bar",
                      data: dataDept1,
                      options: optionsDebt,
                    }}
                  />
                  <Bar
                    ref={refBarDebt1}
                    data={dataDept1}
                    options={{ ...optionsDebt, responsive: true }}
                    plugins={[ChartDataLabels]}
                  />
                </div>
              </div>
            </Col>
            <Col>
              <div className="border-card mt-3 text-center chart-panel">
                <div className="m-auto" style={{ width: 500 }}>
                  <ExportImage
                    ref={refBarDebt2}
                    fileName={"CrossDebt_Bar2"}
                    chartSettings={{
                      type: "bar",
                      data: dataDept2,
                      options: optionsDebt,
                    }}
                  />
                  <Bar
                    ref={refBarDebt2}
                    data={dataDept2}
                    options={{ ...optionsDebt, responsive: true }}
                    plugins={[ChartDataLabels]}
                  />
                </div>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <div className="border-card mt-3 text-center" >
                <div className="m-auto" style={{ width: 500 }}>
                  <Bar
                    data={data1}
                    options={{
                      plugins: {
                        datalabels: {
                          formatter: (value) => {
                            return value + "%";
                          },
                          font: {
                            size: 5,
                          },
                        },
                        title: {
                          display: true,
                          text: "รายได้",
                        },
                        legend: {
                          display: false,
                        },
                      },
                      scales: {
                        x: {
                          stacked: true,
                          title: {
                            align: "end",
                            display: true,
                            text: "ปี",
                          },
                        },
                        y: {
                          stacked: true,
                          title: {
                            align: "end",
                            display: true,
                            text: "บาท",
                          },
                        },
                      },
                      responsive: true,
                    }}
                    plugins={[ChartDataLabels]}
                  />
                </div>
              </div>
            </Col>
            <Col>
              <div className="border-card mt-3 text-center" >
                <div className="m-auto" style={{ width: 500 }}>
                  <Bar
                    data={data2}
                    options={{
                      plugins: {
                        datalabels: {
                          formatter: (value) => {
                            return value + "%";
                          },
                          font: {
                            size: 5,
                          },
                        },
                        title: {
                          display: true,
                          text: "รายได้",
                        },
                        legend: {
                          display: false,
                        },
                      },
                      scales: {
                        x: {
                          stacked: true,
                          title: {
                            align: "end",
                            display: true,
                            text: "ปี",
                          },
                        },
                        y: {
                          stacked: true,
                          title: {
                            align: "end",
                            display: true,
                            text: "บาท",
                          },
                        },
                      },
                      responsive: true,
                    }}
                    plugins={[ChartDataLabels]}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="border-card mt-3 text-center" >
                <div className="m-auto" style={{ width: 500 }}>
                  <Bar
                    data={data3}
                    options={{
                      plugins: {
                        datalabels: {
                          formatter: (value) => {
                            return value + "%";
                          },
                          font: {
                            size: 5,
                          },
                        },
                        title: {
                          display: true,
                          text: "หนี้สิน",
                        },
                        legend: {
                          display: false,
                        },
                      },
                      scales: {
                        x: {
                          stacked: true,
                          title: {
                            align: "end",
                            display: true,
                            text: "ปี",
                          },
                        },
                        y: {
                          stacked: true,
                          title: {
                            align: "end",
                            display: true,
                            text: "บาท",
                          },
                        },
                      },
                      responsive: true,
                    }}
                    plugins={[ChartDataLabels]}
                  />
                </div>
              </div>
            </Col>
            <Col>
              <div className="border-card mt-3 text-center" >
                <div className="m-auto" style={{ width: 500 }}>
                  <Bar
                    data={data4}
                    options={{
                      plugins: {
                        datalabels: {
                          formatter: (value) => {
                            return value + "%";
                          },
                          font: {
                            size: 5,
                          },
                        },
                        title: {
                          display: true,
                          text: "หนี้สิน",
                        },
                        legend: {
                          display: false,
                        },
                      },
                      scales: {
                        x: {
                          stacked: true,
                          title: {
                            align: "end",
                            display: true,
                            text: "ปี",
                          },
                        },
                        y: {
                          stacked: true,
                          title: {
                            align: "end",
                            display: true,
                            text: "บาท",
                          },
                        },
                      },
                      responsive: true,
                    }}
                    plugins={[ChartDataLabels]}
                  />
                </div>
              </div>
            </Col>
          </Row> */}
        </CardContent>
      </Card>
    </div>
  );
};

export default StaticCross;
