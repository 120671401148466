import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Box, Card, CardContent } from "@mui/material";
import { useResultContext } from "..";
import _ from "lodash";

const Select3 = () => {
  const {
    selectSim1,
    selectSim2,
    dataResult1,
    dataResult2,
    labelsYearChartArr,
  } = useResultContext();

  const getArrayOfString = (arr, objName, cropName) => {
    let res = [];
    arr.map((item) => {
      if (!_.isEmpty(item?.[objName])) {
        const found = item?.[objName].find(({ crop }) => crop === cropName);
        if (found?.amount) {
          res.push(found?.amount);
        }
      }
    });
    return res;
  };

  const getAverageByType = (data, fieldName) => {
    if (!_.isEmpty(data)) {
      let sum = 0;
      let itemTotal = 0;
      data?.map((item, index) => {
        sum = sum + item[fieldName];
        itemTotal = index + 1;
      });
      const avg = sum && itemTotal ? sum / itemTotal : 0;
      return avg
        ? Number(avg.toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
          })
        : "0";
    } else {
      return 0;
    }
  };

  const getPercentageByType = (data, fieldName, avg) => {
    if (!_.isEmpty(data)) {
      let sum = 0;
      let total = 0;
      data?.map((item, index) => {
        sum = sum + item[fieldName];
        total = index + 1;
      });
      let percent = ((sum / total) * 100).toFixed(1);
      return percent;
    } else {
      return 0;
    }
  };

  const renderAvgAgentCount = (sim) => {
    return <span>{sim?.agentCount ? sim?.agentCount : "-"}</span>;
  };

  return (
    <div className="mt-3">
      <Row>
        <Col>
          <Card
            style={{
              backgroundColor: "#1755a4",
              // height: 80,
              padding: 25,
            }}
          >
            <Box sx={{ mb: 3, fontSize: 20 }}>
              <p className="text-white font-w-bold">
                จำนวนครัวเรือนทั้งหมด {renderAvgAgentCount(selectSim1)}{" "}
                ครัวเรือน
              </p>
            </Box>
            <Box sx={{ mb: 3, fontSize: 20 }}>
              <p className="text-white font-w-bold">ครัวเรือนในภาคเกษตร</p>
              <Box
                sx={{ mt: 2, fontSize: 18 }}
                className="text-white font-w-bold"
              >
                <span style={{ marginRight: "30px" }}>
                  ร้อยละ{" "}
                  {getPercentageByType(
                    dataResult1,
                    "workOnFarmAvg",
                    getAverageByType(dataResult1, "workOnFarmAvg")
                  )}
                </span>
              </Box>
            </Box>
            <Box sx={{ mb: 3, fontSize: 20 }}>
              <p className="text-white font-w-bold">
                ครัวเรือนทำงานในและนอกภาคเกษตร
              </p>
              <Box
                sx={{ mt: 2, fontSize: 18 }}
                className="text-white font-w-bold"
              >
                <span style={{ marginRight: "30px" }}>
                  ร้อยละ{" "}
                  {getPercentageByType(
                    dataResult1,
                    "workOffFarmAvg",
                    getAverageByType(dataResult1, "workOffFarmAvg")
                  )}
                </span>
              </Box>
            </Box>
            <Box sx={{ mb: 1, fontSize: 20 }}>
              <p className="text-white font-w-bold">
                ครัวเรือนทำงานนอกภาคเกษตร
              </p>
              <Box
                sx={{ mt: 2, fontSize: 18 }}
                className="text-white font-w-bold"
              >
                <span style={{ marginRight: "30px" }}>
                  ร้อยละ{" "}
                  {getPercentageByType(
                    dataResult1,
                    "workAllAvg",
                    getAverageByType(dataResult1, "workAllAvg")
                  )}
                </span>
              </Box>
            </Box>
          </Card>
        </Col>
        <Col>
          <Card
            style={{
              backgroundColor: "#c04327",
              // height: 80,
              padding: 25,
            }}
          >
            <Box sx={{ mb: 3, fontSize: 20 }}>
              <p className="text-white font-w-bold">
                จำนวนครัวเรือนทั้งหมด {renderAvgAgentCount(selectSim2)}{" "}
                ครัวเรือน
              </p>
            </Box>
            <Box sx={{ mb: 3, fontSize: 20 }}>
              <p className="text-white font-w-bold">ครัวเรือนในภาคเกษตร</p>
              <Box
                sx={{ mt: 2, fontSize: 18 }}
                className="text-white font-w-bold"
              >
                <span style={{ marginRight: "30px" }}>
                  ร้อยละ{" "}
                  {getPercentageByType(
                    dataResult2,
                    "workOnFarmAvg",
                    getAverageByType(dataResult2, "workOnFarmAvg")
                  )}
                </span>
              </Box>
            </Box>
            <Box sx={{ mb: 3, fontSize: 20 }}>
              <p className="text-white font-w-bold">
                ครัวเรือนทำงานในและนอกภาคเกษตร
              </p>
              <Box
                sx={{ mt: 2, fontSize: 18 }}
                className="text-white font-w-bold"
              >
                <span style={{ marginRight: "30px" }}>
                  ร้อยละ{" "}
                  {getPercentageByType(
                    dataResult2,
                    "workOffFarmAvg",
                    getAverageByType(dataResult2, "workOffFarmAvg")
                  )}
                </span>
              </Box>
            </Box>
            <Box sx={{ mb: 1, fontSize: 20 }}>
              <p className="text-white font-w-bold">
                ครัวเรือนทำงานนอกภาคเกษตร
              </p>
              <Box
                sx={{ mt: 2, fontSize: 18 }}
                className="text-white font-w-bold"
              >
                <span style={{ marginRight: "30px" }}>
                  ร้อยละ{" "}
                  {getPercentageByType(
                    dataResult2,
                    "workAllAvg",
                    getAverageByType(dataResult1, "workAllAvg")
                  )}
                </span>
              </Box>
            </Box>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Select3;
