import React, { useState, useEffect } from "react";
import { Button, Card, CardContent, CardHeader, Link } from "@mui/material";
// import { makeStyles } from "@mui/styles";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../../scss/_uploadData.scss";
import { Col, Image, Row } from "react-bootstrap";
import {
  faChartPie,
  faClockRotateLeft,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck as faCircleCheckLight } from "@fortawesome/free-solid-svg-icons";
import { useCustomDataContext } from "./index";

// const mockData = [
//   {
//     agentCount: 0,
//     count: 778,
//     createdOn: "2023-04-03T17:46:30.184Z",
//     description: "ชุดข้อมูลเริ่มต้นจาก สศก",
//     gridSearchProgress: 100,
//     id: "642b10f64350dbb90172bec8",
//     isDefault: true,
//     name: "ชุดข้อมูลเริ่มต้น",
//     sensitivityProgress: 100,
//     sensitivityState: "todo",
//     updatedOn: "2023-04-18T18:09:54.52Z",
//     userID: "63df10977184c293418e14ec",
//     workState: "inprogress",
//   },
//   {
//     agentCount: 0,
//     count: 778,
//     createdOn: "2023-04-03T17:46:30.184Z",
//     description: "ชุดข้อมูลเริ่มต้นจาก สศก",
//     gridSearchProgress: 100,
//     id: "642b10f64350dbb90172bec3",
//     isDefault: true,
//     name: "ชุดข้อมูลเริ่มต้น",
//     sensitivityProgress: 100,
//     sensitivityState: "",
//     updatedOn: "2023-04-18T18:09:54.52Z",
//     userID: "63df10977184c293418e14ec",
//     workState: "done",
//   },
//   {
//     agentCount: 0,
//     count: 778,
//     createdOn: "2023-04-03T17:46:30.184Z",
//     description: "ชุดข้อมูลเริ่มต้นจาก สศก",
//     gridSearchProgress: 100,
//     id: "642b10f64350dbb90172bec9",
//     isDefault: true,
//     name: "ชุดข้อมูลเริ่มต้น",
//     sensitivityProgress: 100,
//     sensitivityState: "done",
//     updatedOn: "2023-04-18T18:09:54.52Z",
//     userID: "63df10977184c293418e14ec",
//     workState: "done",
//   },
//   {
//     agentCount: 0,
//     count: 778,
//     createdOn: "2023-04-03T17:46:30.184Z",
//     description: "ชุดข้อมูลเริ่มต้นจาก สศก",
//     gridSearchProgress: 100,
//     id: "642b10f64350dbb90172bec7",
//     isDefault: true,
//     name: "ชุดข้อมูลเริ่มต้น",
//     sensitivityProgress: 100,
//     sensitivityState: "todo",
//     updatedOn: "2023-04-18T18:09:54.52Z",
//     userID: "63df10977184c293418e14ec",
//     workState: "done",
//   },
//   {
//     agentCount: 0,
//     count: 778,
//     createdOn: "2023-04-03T17:46:30.184Z",
//     description: "ชุดข้อมูลเริ่มต้นจาก สศก",
//     gridSearchProgress: 100,
//     id: "642b10f64350dbb90172bec7",
//     isDefault: true,
//     name: "ชุดข้อมูลเริ่มต้น",
//     sensitivityProgress: 54,
//     sensitivityState: "inprogress",
//     updatedOn: "2023-04-18T18:09:54.52Z",
//     userID: "63df10977184c293418e14ec",
//     workState: "done",
//   },
// ];
export default function Tab2() {
  const [fileName, setFileName] = useState("");

  const { dataManage, onHandleCalibrate, onHandleSensitivity, onHandleRemove } =
    useCustomDataContext();

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };

  const changeCreatedOn = (time) => {
    const year = new Date(time).getFullYear();
    return year;
  };

  const changeUpdatedOn = (time) => {
    const timenew = time.replace("T", " ").replace("Z", "");
    const timestamp = new Date(timenew);
    const day = timestamp.getDate();
    const month = timestamp.getMonth() + 1;
    const year = timestamp.getFullYear() + 543;
    const hours = timestamp.getHours();
    const minutes = timestamp.getMinutes();

    const formattedDate = `${day < 10 ? "0" + day : day}/${
      month < 10 ? "0" + month : month
    }/${year}`;
    const formattedTime = `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }`;

    const formattedTimestamp = `${formattedTime} ${formattedDate}`;

    return formattedTimestamp;
  };
  console.log("top2", dataManage);
  const renderPresetCardList = () => {
    if (!_.isEmpty(dataManage)) {
      return (
        <>
          {dataManage?.map((item) => {
            return (
              <>
                <div className="mt-2 d-flex">
                  <Card
                    style={{ width: "100%", marginRight: 10 }}
                    sx={{
                      minWidth: 275,
                      borderRadius: 2,
                      boxShadow: "unset",
                      height: 130,
                      border: "1px solid #BBBBBB",
                      padding: "unset !important",
                      "@media (max-width: 954px)": {
                        height: 210,
                      },
                    }}
                    className="p-down"
                  >
                    <CardContent>
                      <Row>
                        <Col className="text-left">
                          <p style={{ fontSize: 20, fontWeight: "bold" }}>
                            {item?.name} ({changeCreatedOn(item?.createdOn)})
                          </p>
                        </Col>
                        {item.workState === "done" && (
                          <Col>
                            <Row>
                              <div class="d-flex flex-row-reverse">
                                <a
                                  href={
                                    "https://abm.oae.go.th/sensitivity/" +
                                    item?.id
                                  }
                                  target="_blank"
                                >
                                  View Image
                                </a>
                              </div>
                            </Row>
                          </Col>
                        )}
                        <hr />
                        <Col md={9} className="text-update">
                          <div
                            style={{ overflow: "scroll", height: 40 }}
                            className="text-left"
                          >
                            <p style={{ fontSize: 14 }}>{item?.description} </p>
                          </div>
                          <div>
                            <div className="d-flex">
                              <FontAwesomeIcon
                                icon={faClockRotateLeft}
                                style={{
                                  fontSize: 12,
                                  marginRight: 5,
                                  marginTop: 4,
                                }}
                              />
                              <p
                                style={{
                                  fontSize: 14,
                                }}
                              >
                                update {changeUpdatedOn(item?.updatedOn)}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          {item.workState === "todo" && (
                            <Row class="d-flex flex-row-reverse">
                              <div class="button-control">
                                <Button
                                  variant="contained"
                                  style={{
                                    textTransform: "none",
                                    marginRight: 5,
                                  }}
                                  className="button-calibrate"
                                  onClick={() => onHandleCalibrate(item?.id)}
                                >
                                  <p style={{ fontSize: 14 }}>Calibrate</p>
                                </Button>
                              </div>
                            </Row>
                          )}

                          {item.workState === "inprogress" &&
                            item.sensitivityState !== "inprogress" && (
                              <Row>
                                <div class="d-flex flex-row-reverse">
                                  <p className="calibrate-progress">
                                    Calibrate in progress
                                  </p>
                                  <FontAwesomeIcon
                                    icon={faSpinner}
                                    style={{ fontSize: 15 }}
                                    className="calibrate-faSpinner"
                                  />
                                  <p
                                    className="calibrate-progress"
                                    style={{ marginRight: 6 }}
                                  >
                                    {item.gridSearchProgress.toFixed(0)}%
                                  </p>
                                </div>
                              </Row>
                            )}

                          {item.workState === "done" &&
                            item.sensitivityState !== "done" &&
                            item.sensitivityState !== "todo" &&
                            item.sensitivityState !== "inprogress" && (
                              <Row>
                                <div class="d-flex flex-row-reverse ">
                                  <p className="calibrate-succeed">Calibrate</p>
                                  <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    className="calibrate-faCheckCircle"
                                  />
                                  <p
                                    className="calibrate-succeed"
                                    style={{ marginRight: 6 }}
                                  >
                                    {item.gridSearchProgress.toFixed(0)}%
                                  </p>
                                </div>
                              </Row>
                            )}

                          {item.workState === "done" &&
                            (item.sensitivityState === "done" ||
                              item.sensitivityState === "todo") && (
                              <>
                                <Row>
                                  <div class="d-flex flex-row-reverse ">
                                    <p className="calibrate-succeed">
                                      Calibrate
                                    </p>
                                    <FontAwesomeIcon
                                      icon={faCircleCheck}
                                      className="calibrate-faCheckCircle"
                                    />
                                    <p
                                      className="calibrate-succeed"
                                      style={{ marginRight: 6 }}
                                    >
                                      {item.gridSearchProgress.toFixed(0)}%
                                    </p>
                                  </div>
                                </Row>

                                <Row>
                                  <div class="d-flex flex-row-reverse">
                                    <Button
                                      variant="contained"
                                      style={{
                                        textTransform: "none",
                                        marginRight: 5,
                                        marginTop: 5,
                                      }}
                                      className="button-calibrate"
                                      onClick={() =>
                                        onHandleSensitivity(item?.id)
                                      }
                                    >
                                      <p style={{ fontSize: 14 }}>
                                        Sensitivity
                                      </p>
                                    </Button>
                                    {item.sensitivityState === "done" ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          margin: "auto",
                                          marginRight: 10,
                                        }}
                                      >
                                        <p className="calibrate-succeed">
                                          {item.sensitivityProgress.toFixed(0)}%
                                        </p>
                                        <FontAwesomeIcon
                                          icon={faCircleCheck}
                                          className="calibrate-faCheckCircle"
                                          style={{ marginTop: 2 }}
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                </Row>
                              </>
                            )}

                          {item.workState === "done" &&
                            item.sensitivityState === "inprogress" && (
                              <>
                                <Row>
                                  <div class="d-flex flex-row-reverse ">
                                    <p className="calibrate-succeed">
                                      Calibrate
                                    </p>
                                    <FontAwesomeIcon
                                      icon={faCircleCheck}
                                      className="calibrate-faCheckCircle"
                                    />
                                    <p
                                      className="calibrate-succeed"
                                      style={{ marginRight: 6 }}
                                    >
                                      {item.gridSearchProgress.toFixed(0)}%
                                    </p>
                                  </div>
                                </Row>
                                <Row>
                                  <div class="d-flex flex-row-reverse">
                                    <p
                                      className="calibrate-progress"
                                      style={{
                                        marginTop: 5,
                                      }}
                                    >
                                      Sensitivity in progress
                                    </p>
                                    <FontAwesomeIcon
                                      icon={faSpinner}
                                      style={{ fontSize: 15, marginTop: 8 }}
                                      className="calibrate-faSpinner"
                                    />
                                    <p
                                      className="calibrate-progress"
                                      style={{ margin: 6 }}
                                    >
                                      {item.sensitivityProgress.toFixed(0)}%
                                    </p>
                                  </div>
                                </Row>
                              </>
                            )}
                        </Col>
                      </Row>
                    </CardContent>
                  </Card>
                  {item?.name === "ชุดข้อมูลเริ่มต้น" ? (
                    <div className="m-auto" style={{ marginLeft: 10 }}>
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{ fontSize: 20 }}
                        color="white"
                      />
                    </div>
                  ) : (
                    <div className="m-auto" style={{ marginLeft: 10 }}>
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{ fontSize: 20, cursor: "pointer" }}
                        color="red"
                        onClick={() => onHandleRemove(item?.id)}
                      />
                    </div>
                  )}
                </div>
              </>
            );
          })}
        </>
      );
    } else {
      return (
        <Card
          className={"text-center"}
          variant="outlined"
          sx={{ border: "0px" }}
        >
          <p className={"my-3 font-w-bold"}>No data</p>
        </Card>
      );
    }
  };

  return (
    <div>
      <Card sx={{ minWidth: 275 }} className="mt-3">
        <CardHeader title={<p>จัดการชุดข้อมูล</p>} />
        <CardContent className="w-90">{renderPresetCardList()}</CardContent>
      </Card>
    </div>
  );
}
