import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faMicrochip,
  faChartPie,
  faClockRotateLeft,
  faChevronDown,
  faRightFromBracket,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Avatar, Card, CardContent, Container, Icon } from "@mui/material";
import HomePage from "../homePpage";
import CustomizeModel from "../customizeModel";
import { Col, Row } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import "../../scss/_display-none.scss";
import { userService } from "../../services/user.service";
import { useNavigate } from "react-router-dom";
import ResultPage from "../result";
import UploadData from "../uploadData/index";
import Logo from "../../assets/ABM_LOGO.svg";
import Image from "react-bootstrap/Image";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export default function MiniDrawer() {
  const navigate = useNavigate();

  const [content, setContent] = useState(0);
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (width <= 600) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [width]);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onLogout = () => {
    const status = userService.logout();
    if (status) {
      navigate("/login", { replace: true });
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            style={{ fontSize: 25, display: "contents" }}
          >
            <Image src={Logo} width={100} style={{backgroundColor: "white" , padding:8, borderRadius: 10}} />
            
            <div style={width <= 600 ? { display: "none" } : null}>
              <p style={{ marginLeft: 10 }}>
                ระบบรายงานผลกระทบจากการใช้นโยบาย/มาตรการทางการเกษตร
              </p>
            </div>
          </Typography>
          <div style={{ marginLeft: "auto" }}>
            <Row className="align-items-center">
              <Col md={width <= 600 ? 0 : 1}>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    style={{ background: "none", border: "none" }}
                  >
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      style={{
                        fontSize: 15,
                      }}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={onLogout}>
                      Logout{" "}
                      <FontAwesomeIcon
                        icon={faRightFromBracket}
                        style={{
                          fontSize: 15,
                          marginLeft: "40%",
                        }}
                      />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col md={width <= 600 ? 0 : 2}>
                <div style={{ marginLeft: 10 }}>
                  <Avatar
                    alt={userService.getUserAccount()}
                    src="/static/images/avatar/2.jpg"
                  />
                </div>
              </Col>
              <Col
                md={8}
                style={
                  width <= 600
                    ? { display: "none" }
                    : { textAlign: "right", paddingLeft: 0 }
                }
              >
                <p>{userService.getUserAccount()}</p>
                {/* <p>admin</p> */}
              </Col>
            </Row>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => setContent(0)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <FontAwesomeIcon icon={faHome} style={{ fontSize: 35 }} />
              </ListItemIcon>
              <ListItemText
                primary="ภาพรวมแบบจำลอง"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }} className="mt-2">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => setContent(3)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <FontAwesomeIcon icon={faUpload} style={{ fontSize: 35 }} />
              </ListItemIcon>
              <ListItemText
                primary="อัพโหลดข้อมูล"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }} className="mt-2">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => setContent(1)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <FontAwesomeIcon icon={faMicrochip} style={{ fontSize: 35 }} />
              </ListItemIcon>
              <ListItemText
                primary="ปรับแต่งแบบจำลอง"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }} className="mt-2">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => setContent(2)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <FontAwesomeIcon icon={faChartPie} style={{ fontSize: 35 }} />
              </ListItemIcon>
              <ListItemText primary="ผลลัพธ์" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Container className="MuiContainer-100">
          {content === 0 ? (
            <HomePage />
          ) : content === 1 ? (
            <CustomizeModel />
          ) : content === 2 ? (
            <ResultPage />
          ) : (
            <UploadData />
          )}
        </Container>
      </Box>
    </Box>
  );
}
