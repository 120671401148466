import { Navigate } from "react-router-dom";
import { userService } from "../services/user.service";

export const ProtectedRoute = ({ children }) => {
  const user = userService.user();
  if (!user) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};
