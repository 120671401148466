import { fetchWrapper } from "../helpers/fetch-wrapper";
import service from "./index";

const apiPreset = "/api/v1/abm/preset";
const apiParameter = "/api/v1/abm/parameter";

export const presetService = {
  create,
  getPresetList,
  getPresetDetail,
  getPresetActive,
  updatePresetActive,
  updatePreset,
  delete: deletePreset,
  getPresetDefault,
};

function create(obj) {
  return fetchWrapper.post(`${apiPreset}/entity`, obj).then(service.handleErrors);
}

function getPresetList() {
  return fetchWrapper.get(`${apiPreset}/entities`).then(service.handleErrors);
}

function deletePreset(id) {
  const obj = {
    entityID: id,
  };
  return fetchWrapper.delete(`${apiPreset}/entity`, obj).then(service.handleErrors);
}

function getPresetDetail(abmId) {
  return fetchWrapper.get(`${apiParameter}/${abmId}`).then(service.handleErrors);
}

function getPresetDefault() {
  return fetchWrapper
    .get(`${apiParameter}/default`)
    .then((res) => {
      const { success, data } = res || {};
      if (success) {
        return data;
      } else {
        service.handleErrors(res);
      }
    })
    .catch((err) => {});
}

function getPresetActive() {
  return fetchWrapper
    .get(`${apiPreset}/active`)
    .then((res) => {
      const { success, data } = res || {};
      if (success) {
        const { entity } = data || {};
        if (entity?.active) {
          return entity;
        } else {
          return null;
        }
      } else {
        service.handleErrors(res);
      }
    })
    .catch((err) => {});
}

function updatePresetActive(presetId) {
  let formData = new FormData();
  formData.set("presetID", presetId);
  return fetchWrapper
    .putByParam(`${apiPreset}/active`, formData)
    .then(service.handleErrors);
}

function updatePreset(obj) {
  return fetchWrapper.put(`${apiPreset}/entity`, obj).then(service.handleErrors);
}

// function handleErrors(res) {
//   const { success, error } = res;
//   if (!success) {
//     const { message, internalErrorMessage } = error || {};
//     Swal.fire(message, internalErrorMessage, "error");
//   }
//   return res;
// }
