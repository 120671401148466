import { fetchWrapper } from "../helpers/fetch-wrapper";
import service from "./index";

const apiSimulation = "/api/v1/abm/simulation";
const apiSimulationBatch = "/api/v1/abm/batchrun";

export const simulationService = {
  simulation,
  simulationBatch,
  getSimulation,
  cancel,
  running,
};

function simulation(idDataSet) {
  const data = {
    dataSetID: idDataSet,
  };
  return fetchWrapper.post(`${apiSimulation}`, data).then(service.handleErrors);
}
function simulationBatch(idDataSet) {
  const data = {
    dataSetID: idDataSet,
  };
  return fetchWrapper
    .post(`${apiSimulationBatch}`, data)
    .then(service.handleErrors);
}

function getSimulation() {
  return fetchWrapper
    .get(`${apiSimulation}/entities`)
    .then(service.handleErrors);
}

function cancel() {
  return fetchWrapper.put(`${apiSimulation}/cancel`).then(service.handleErrors);
}

function running() {
  return fetchWrapper.get(`${apiSimulation}/running`);
}

// function handleErrors(res) {
//   const { success, error } = res;
//   if (!success) {
//     const { message, internalErrorMessage } = error || {};
//     Swal.fire(message, internalErrorMessage, "error");
//   }
//   return res;
// }
