import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Col, Image, Row, InputGroup, Form, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { Card } from "@mui/material";
import C2S from "../../helpers/canvas2svg";
import { Chart } from "chart.js";
// Chart.register(ScatterController, PointElement, LineElement, LinearScale);
// import { Canvg } from "canvg";

const ExportImage = React.forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClickDownload = async (extension) => {
    switch (extension) {
      case "svg":
        const width = 600;
        const height = 300;
        const chartSettings = props?.chartSettings;

        const ctx = C2S(width, height);
        ctx.resetTransform = function () {};
        ctx.getContext = function (contextId) {
          if (contextId == "2d" || contextId == "2D") {
            return this;
          }
          return null;
        };
        ctx.style = function () {
          return this.__canvas.style;
        };
        ctx.getAttribute = function (name) {
          return this[name];
        };
        ctx.addEventListener = function (type, listener, eventListenerOptions) {
          console.log("canvas2svg.addEventListener() not implemented.");
        };

        const chart = new Chart(ctx, chartSettings);
        // const canvas = chart.canvas;
        let blob;
        if (chartSettings?.type === "line") {
          let svg = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "svg"
          );
          svg.setAttribute("width", width);
          svg.setAttribute("height", height);
          var img = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "image"
          );
          img.setAttribute("href", ref?.current.toBase64Image());
          img.setAttribute("x", 0);
          img.setAttribute("y", 0);
          img.setAttribute("width", width);
          img.setAttribute("height", height);
          svg.appendChild(img);
          var serializer = new XMLSerializer();
          var svgString = serializer.serializeToString(svg);
          blob = new Blob([svgString], {
            type: "image/svg+xml",
          });
        } else {
          blob = new Blob([ctx.getSerializedSvg()], {
            type: "image/svg+xml",
          });
        }
        const link1 = document.createElement("a");
        link1.href = window.URL.createObjectURL(blob);
        link1.download = `${props?.fileName}.${extension}`;
        link1.click();
        break;
      case "html":
        var base64 = ref?.current.toBase64Image();
        const htmlContent = `
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Image in HTML</title>
                </head>
                <body>
                    <img src="${base64}" alt="Sample Image" />
                </body>
                </html>`
            ;
        var bblob = new Blob([htmlContent], { type: 'text/html;charset=utf-8' });
        var link2 = document.createElement('a');
        link2.href = URL.createObjectURL(bblob);
        link2.download = `${props?.fileName}.${extension}`;
        link2.click();
        handleToggle();
        break;
     

      default:
        const link = document.createElement("a");
        link.download = `${props?.fileName}.${extension}`;
        link.href = ref?.current.toBase64Image();
        link.click();
        handleToggle();
        break;
    }
  };

  return (
    <>
      <div className="text-right btn-downlod">
        <Button
          variant="contained"
          style={{ backgroundColor: "#2865B5", border: 0 }}
          onClick={() => handleToggle()}
        >
          <div>
            <p className={"font-w-bold"} style={{ fontSize: 12 }}>
              <FontAwesomeIcon
                icon={faFileExport}
                style={{ fontSize: 12, marginRight: 5 }}
              />
              บันทึกรูปภาพ
            </p>
          </div>
        </Button>
        <ExportModal
          isOpen={isOpen}
          toggle={handleToggle}
          onHandleExportFile={handleClickDownload}
        />
      </div>
    </>
  );
});

const ExportModal = (props) => {
  const [extension, setExtension] = useState("jpeg");
  const { isOpen, onHandleExportFile, toggle } = props;
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: "5em" }}>
      <ModalHeader toggle={toggle}>
        <p className="text-topic-2">บันทึกรูปภาพ</p>
      </ModalHeader>
      <ModalBody className={"p-0 mt-2"}>
        <Container>
          <Card
            sx={{
              // minWidth: 200,
              height: "auto",
              overflow: "scroll",
              border: "0px",
            }}
            variant="outlined"
          >
            <Row>
              <Col md={6} className="d-flex">
                <p
                  className="font-s-small my-auto"
                  style={{
                    fontSize: 14,
                  }}
                >
                  เลือกประเภทไฟล์
                </p>
              </Col>
              <Col>
                <InputGroup>
                  <Form.Select
                    size="sm"
                    id={"exportResult"}
                    name={"exportResult"}
                    onChange={(e) => {
                      const { value } = e.target;
                      setExtension(value);
                    }}
                    value={extension}
                  >
                    <option value={"jpeg"}>.jpeg</option>
                    <option value={"png"}>.png</option>
                    <option value={"svg"}>.svg</option>
                    <option value={"html"}>.html</option>
                  </Form.Select>
                </InputGroup>
              </Col>
            </Row>
            <Row className="my-4">
              <Col>
                <Button
                  variant="contained"
                  className={"w-100"}
                  onClick={() => toggle()}
                >
                  <p
                    style={{
                      fontSize: 18,
                      color: "white",
                    }}
                    className={"font-w-bold"}
                  >
                    ยกเลิก
                  </p>
                </Button>
              </Col>
              <Col>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4186E0" }}
                  onClick={() => onHandleExportFile(extension)}
                  className={"w-100"}
                >
                  <div>
                    <p className={"font-w-bold"} style={{ fontSize: 18 }}>
                      ดาวน์โหลด
                    </p>
                  </div>
                </Button>
              </Col>
            </Row>
          </Card>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default ExportImage;
