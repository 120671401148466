import React, { useEffect, useState, useMemo } from "react";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import Form from "react-bootstrap/Form";
import { Col, Image, Row } from "react-bootstrap";
import "../../scss/_uploadData.scss";
import faArrowUpLong from "@mui/icons-material/CloudUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useCustomDataContext } from "./index";
import Swal from "sweetalert2";

const Tab1 = () => {
  const [nameData, setNameData] = useState(null);
  const [description, setDescription] = useState(null);
  const [selectData, setSelectData] = useState("1");
  const [fileName, setFileName] = useState(null);
  const [showfile, setshowfile] = useState("1");
  const [selectedYearStart, setSelectedYearStart] = useState(
    new Date().getFullYear()-2
  );
  const [selectedYearEnd, setSelectedYearEnd] = useState(
    new Date().getFullYear()-2
  );
  const [file, setFile] = useState(null);
  const { onHandleSaveFile, onHandleSaveAgriculture } = useCustomDataContext();


  const provinces = [
    { id: 1, name: "กรุงเทพมหานคร" },
    { id: 2, name: "สมุทรปราการ" },
    { id: 3, name: "นนทบุรี" },
  ];

  const handleInputNameData = (e) => {
    const { value } = e.target;
    setNameData(value);
  };

  const handleInputDescription = (e) => {
    const { value } = e.target;
    setDescription(value);
  };

  const handleSelectData = (e) => {
    const { value } = e.target;
    if (value === "1") {
      setshowfile("1");
    }
    if (value === "2") {
      setshowfile("2");
      setFileName("");
      setFile(null);
    }
    setSelectData(value);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (
      (file && file.type === "text/csv") ||
      file.type === "application/json" ||
      file.type === "text/plain" ||
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFileName(file.name);
      setFile(file);
    } else {
      Swal.fire(
        "ไม่สามารถดำเนินรายการได้",
        "กรุณาเลือก CSV, Excel, Json, Txt file!",
        "error"
      );
      setFileName(null);
    }
  };

  const availableYears = useMemo(() => {
    const currentYear = new Date().getFullYear();
    console.log('current year ==> ',currentYear);
    const years = [];
    for (let year = currentYear-2; year >= currentYear -2; year--) {
      years.push(year + 543);
    }
    console.log('year ==> ',years);
    return years;
  }, []);

  function handleYearChangeStart(event) {
    setSelectedYearStart(parseInt(event.target.value));
  }
  function handleYearChangeEnd(event) {
    setSelectedYearEnd(parseInt(event.target.value));
  }

  function onHandleSave() {
    if (showfile === "1") {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", nameData);
      formData.append("description", description);
      formData.append("agentCount", 0);
      onHandleSaveFile(formData);
    } else if (showfile === "2") {
      const currentYear = new Date().getFullYear();
      const formData = new FormData();
      if (
        selectedYearStart === currentYear &&
        selectedYearEnd === currentYear
      ) {
        formData.append("name", nameData);
        formData.append("description", description);
        formData.append("begin_year", selectedYearStart + 543);
        formData.append("end_year", selectedYearEnd + 543);
      }
      if (
        selectedYearStart === currentYear &&
        selectedYearEnd !== currentYear
      ) {
        formData.append("name", nameData);
        formData.append("description", description);
        formData.append("begin_year", selectedYearStart + 543);
        formData.append("end_year", selectedYearEnd);
      }
      if (
        selectedYearEnd === currentYear &&
        selectedYearStart !== currentYear
      ) {
        formData.append("name", nameData);
        formData.append("description", description);
        formData.append("begin_year", selectedYearStart);
        formData.append("end_year", selectedYearEnd + 543);
      }
      if (
        selectedYearStart !== currentYear &&
        selectedYearEnd !== currentYear
      ) {
        formData.append("name", nameData);
        formData.append("description", description);
        formData.append("begin_year", selectedYearStart);
        formData.append("end_year", selectedYearEnd);
      }

      onHandleSaveAgriculture(formData);
    }
  }

  return (
    <div>
      <Card
        sx={{
          minWidth: 275,
        }}
        className="mt-3"
      >
        <CardHeader title={<p>เลือกแหล่งข้อมูล เพื่อสร้างชุดข้อมูล</p>} />
        <CardContent className="w-90">
          <div className="text-left mt-3">
            <Row>
              <Form.Group className="mb-3">
                <Form.Label>
                  <p className="font-w-bold">ชื่อชุดข้อมูล</p>
                </Form.Label>
                <Form.Control
                  id={"nameData"}
                  name={"nameData"}
                  value={nameData}
                  onChange={(e) => handleInputNameData(e)}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>
                  <p className="font-w-bold">รายละเอียด</p>
                </Form.Label>
                <Form.Control
                  id={"description"}
                  name={"description"}
                  value={description}
                  onChange={(e) => handleInputDescription(e)}
                />
              </Form.Group>
            </Row>

            <Row>
              <Col md="5">
                <Form.Group>
                  <Form.Label>
                    <p className="font-w-bold">แหล่งข้อมูล</p>
                  </Form.Label>
                  <Form.Select
                    onChange={(e) => handleSelectData(e)}
                    className="font-select"
                  >
                    <option value="1">ไฟล์ CSV, Excel, Json, Txt</option>
                    <option value="2">
                      ฐานข้อมูลจาก สำนักงานเศษฐกิจการเกษตร
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>

              {showfile === "1" ? (
                <Col md="7">
                  <Form.Group>
                    <Form.Label>
                      <p className="font-w-bold">เลือกไฟล์</p>
                    </Form.Label>

                    <Form.Group className="file-upload">
                      <div class="container-upload">
                        <div class="file-name-display">
                          <p className="fo-fm">{fileName || "Select a file"}</p>
                        </div>

                        <input
                          type="file"
                          id="fileInput"
                          onChange={handleFileSelect}
                          className="hiddenInput"
                        />
                        <FontAwesomeIcon icon="fa-regular fa-arrow-up-from-bracket" />
                        <label htmlFor="fileInput" className="text-right w-100">
                          <Button
                            variant="contained"
                            component="span"
                            className="button-uplode "
                            style={{ marginRight: 10, fontFamily: "Prompt" }}
                            startIcon={
                              <FontAwesomeIcon
                                icon={faArrowUpFromBracket}
                                style={{ fontSize: 15 }}
                              />
                            }
                          >
                            นำเข้าข้อมูล
                          </Button>
                        </label>
                      </div>
                    </Form.Group>
                  </Form.Group>
                </Col>
              ) : showfile === "2" ? (
                <Col md="7">
                  <Row>
                    <Col>
                      <Form.Label>
                        <p className="font-w-bold">ปีเริ่มต้น</p>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={selectedYearStart}
                        onChange={handleYearChangeStart}
                      >
                        {availableYears.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>
                        <p className="font-w-bold">ปีที่สิ้นสุด</p>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={selectedYearEnd}
                        onChange={handleYearChangeEnd}
                      >
                        {availableYears.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </Col>
              ) : (
                ""
              )}
            </Row>

            <Row>
              <Col className="d-flex justify-content-end">
                <Button
                  variant="contained"
                  className="button-save"
                  onClick={() => onHandleSave()}
                >
                  <p className="font-w-bold" style={{ fontSize: 18 }}>
                    บันทึกข้อมูล
                  </p>
                </Button>
              </Col>
            </Row>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Tab1;
