import React, { useEffect, useState, useContext, createContext } from "react";
import {
  Tab,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Icon,
} from "@mui/material";
import { Col, Image, Row } from "react-bootstrap";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "../../scss/_cmodel.scss";
import _ from "lodash";
import moment from "moment";
import fileDownload from "js-file-download";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { presetService } from "../../services/preset.service";
import { simulationService } from "../../services/simulation.service";
import { resultService } from "../../services/result.service";
import ExportModal from "./exportModal";
import { downloadFile } from "../../helpers/download";
import Tab1 from "./tab1";
import Tab2 from "./tab2";

const ResultContext = createContext(null);

const ResultPage = () => {
  const [tab, setTab] = useState("1");
  const [periodTab1, setPeriodTab1] = useState(1);
  const [presetList, setPresetList] = useState([]);
  const [simulationList, setSimulationList] = useState([]);
  const [optionSimulation, setOptionSimulation] = useState([]);
  const [selectSim1, setSelectSim1] = useState(null);
  const [selectSim2, setSelectSim2] = useState(null);

  //Tab2
  const [allStep, setAllStep] = useState(0);
  const [fromTo, setFromTo] = useState({ from: null, to: null });
  const [dataResult1, setDataResult1] = useState([]);
  const [dataResult2, setDataResult2] = useState([]);

  //Export
  const [extension, setExtension] = useState("xlsx");
  const [exportModal, setExportModal] = useState({
    isOpen: false,
  });
  useEffect(() => {
    getSimulationList();
    getPresetList();
  }, []);

  useEffect(() => {
    getResultFromTo1(selectSim1);
  }, [selectSim1, fromTo]);

  useEffect(() => {
    getResultFromTo2(selectSim2);
  }, [selectSim2, fromTo]);

  useEffect(() => {
    if (!_.isEmpty(selectSim1) || !_.isEmpty(selectSim2)) {
      let step = 0;
      if (!_.isEmpty(selectSim1) && !_.isEmpty(selectSim2)) {
        step =
          selectSim1?.allStep < selectSim2?.allStep
            ? selectSim1?.allStep
            : selectSim2?.allStep;
      } else if (!_.isEmpty(selectSim1)) {
        step = selectSim1?.allStep;
      } else if (!_.isEmpty(selectSim2)) {
        step = selectSim2?.allStep;
      }
      setAllStep(step);
      setFromTo({ from: 1, to: step });
    }
  }, [selectSim1, selectSim2]);

  useEffect(() => {
    if (!_.isEmpty(presetList) && !_.isEmpty(simulationList)) {
      getOptionSimulation();
    }
  }, [presetList, simulationList]);

  const handleToggleModalExport = () => {
    setExportModal({ isOpen: !exportModal?.isOpen });
  };

  const onExportFile = () => {
    const ext = extension;
    if (!_.isEmpty(selectSim1) && fromTo.from && fromTo.to) {
      const dateTime = moment().format("YYYYMMDDHHmmss");
      const reqSim1 = {
        simulationID: selectSim1?.id,
        fileName: `export_${selectSim1?.label}_${
          tab === "1" ? `(${periodTab1})` : `(${fromTo.from}-${fromTo.to})`
        }_${dateTime}.${ext}`,
        from: tab === "1" ? periodTab1 : fromTo.from,
        to: tab === "1" ? periodTab1 : fromTo.to,
        extension: ext,
      };
      handleExport(reqSim1);
    }

    if (!_.isEmpty(selectSim2) && fromTo.from && fromTo.to) {
      const dateTime = moment().format("YYYYMMDDHHmmss");
      const reqSim2 = {
        simulationID: selectSim2?.id,
        fileName: `export_${selectSim2?.label}_${
          tab === "1" ? `(${periodTab1})` : `(${fromTo.from}-${fromTo.to})`
        }_${dateTime}.${ext}`,
        from: tab === "1" ? periodTab1 : fromTo.from,
        to: tab === "1" ? periodTab1 : fromTo.to,
        extension: ext,
      };
      handleExport(reqSim2);
    }
  };

  const handleExport = (req) => {
    resultService.exportFile(req).then((res) => {
      if (!_.isEmpty(res)) {
        downloadFile(res, req?.fileName, req?.extension);
      }
    });
  };

  const getResultFromTo1 = (entity) => {
    if (!_.isEmpty(entity) && fromTo?.from && fromTo?.to) {
      const req = {
        simulationID: entity?.id,
        from: fromTo.from,
        to: fromTo.to,
      };
      resultService.resultFromTo(req).then((res) => {
        const { success } = res;
        if (success) {
          const { entities } = res?.data || {};
          if (!_.isEmpty(entities)) {
            setDataResult1(entities);
          }
        }
      });
    }
  };

  const getResultFromTo2 = (entity) => {
    if (!_.isEmpty(entity) && fromTo?.from && fromTo?.to) {
      const req = {
        simulationID: entity?.id,
        from: fromTo.from,
        to: fromTo.to,
      };
      resultService.resultFromTo(req).then((res) => {
        const { success } = res;
        if (success) {
          const { entities } = res?.data || {};
          if (!_.isEmpty(entities)) {
            setDataResult2(entities);
          }
        }
      });
    }
  };

  const getOptionSimulation = () => {
    let simList = [];
    simulationList.map((sim) => {
      if (sim?.workState === "done") {
        let found = presetList.find(({ id }) => sim?.presetID === id);
        sim.label = `${found?.name ? found?.name : "-"} (${
          sim?.doneOn
            ? moment(sim.doneOn, "YYYY-MM-DD HH:mm:ss").format(
                "DD-MM-YYYY HH:mm:ss"
              )
            : "-"
        })`;
        simList.push(sim);
      }
    });
    setOptionSimulation(simList);
  };

  const getSimulationList = () => {
    simulationService
      .getSimulation()
      .then((res) => {
        const { success } = res;
        if (success) {
          const { entities } = res?.data || {};
          if (!_.isEmpty(entities)) {
            setSimulationList(entities);
          }
        }
      })
      .catch((err) => {});
  };

  const getPresetList = () => {
    presetService
      .getPresetList()
      .then((res) => {
        const { success } = res;
        if (success) {
          const { entities } = res?.data || {};
          if (!_.isEmpty(entities)) {
            setPresetList(entities);
          }
        }
      })
      .catch((err) => {});
  };

  const handleChange = (event, tab) => {
    setTab(tab);
  };

  const yearLabel = () => {
    return fromTo?.from && fromTo?.to ? fromTo?.to - fromTo?.from + 1 : "-";
  };

  const labelsYearChartArr = () => {
    const labels = [];
    for (let i = fromTo.from; i <= fromTo.to; i++) {
      labels.push(i);
    }
    return labels;
  };

  const context = {
    presetList,
    simulationList,
    optionSimulation,
    selectSim1,
    setSelectSim1,
    selectSim2,
    setSelectSim2,
    allStep,
    fromTo,
    setFromTo,
    yearLabel,
    labelsYearChartArr,
    dataResult1,
    dataResult2,
    setPeriodTab1,
  };

  return (
    <div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={tab}>
          <Card>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="fullWidth"
              >
                <Tab
                  className={tab === "1" ? "custom-tab-bg" : "custom-tab-text"}
                  label={
                    <div>
                      <p className="mb-0" style={{ fontSize: 20 }}>
                        ผลลัพธ์ภาพรวม
                      </p>
                    </div>
                  }
                  value="1"
                />
                <Tab
                  className={tab === "2" ? "custom-tab-bg" : "custom-tab-text"}
                  label={
                    <div>
                      <p className="mb-0" style={{ fontSize: 20 }}>
                        ผลลัพธ์อื่นๆ
                      </p>
                    </div>
                  }
                  value="2"
                />
              </TabList>
            </Box>
          </Card>
          <Row className="align-items-center">
            <Col className="text-right">
              <Button
                className="mt-3"
                variant="contained"
                style={{ backgroundColor: "#4186E0" }}
                onClick={() => handleToggleModalExport()}
              >
                <div className="mx-5">
                  <p className={"font-w-bold"} style={{ fontSize: 18 }}>
                    <FontAwesomeIcon
                      icon={faFileExport}
                      style={{ fontSize: 15, marginRight: 5 }}
                    />
                    ส่งออกข้อมูล
                  </p>
                </div>
              </Button>
            </Col>
          </Row>
          <ResultContext.Provider value={context}>
            <TabPanel value="1">
              <Tab1 />
            </TabPanel>
            <TabPanel value="2">
              <Tab2 />
            </TabPanel>
          </ResultContext.Provider>
          <ExportModal
            isOpen={exportModal?.isOpen}
            tab={tab}
            extension={extension}
            onHandleChangeExtension={setExtension}
            onHandleExportFile={onExportFile}
            toggle={handleToggleModalExport}
          />
        </TabContext>
      </Box>
    </div>
  );
};
export const useResultContext = () => useContext(ResultContext);
export default ResultPage;
