import { get } from "lodash";

export const downloadFile = async (
  response,
  fileName = "export",
  fileType = null
) => {
  const data =
    get(response, "payload.data", null) || get(response, "data", null);
  if (!(data instanceof Blob) && !fileType) return;
  //const blob = new Blob([data], {
  //  type: switchTypeFile(fileType),
  //});
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(response.data);
  link.setAttribute('download',`${fileName}`)
  //document.body.appendChild(link)
  //link.download = `${fileName}.${fileType}`;
  link.click();
};

export const switchTypeFile = (key) => {
  switch (key) {
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;";
    case "json":
      return "application/json";
    case "txt":
      return "application/txt";
    case "csv":
      return "application/csv";
    case "svg":
      return "image/svg+xml";
  }
};
