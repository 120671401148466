import Swal from "sweetalert2";
import { userService } from "./user.service";

const handleErrorsCatch = (err) => {
  Swal.fire("เกิดข้อผิดพลาด", "ไม่สามารถดำเนินการได้", "error");
};

const handleErrors = (res) => {
  const { success, error } = res;
  if (!success) {
    const { message, internalErrorMessage } = error || {};
    Swal.fire(message, internalErrorMessage, "error").then((result) => {
      if (result.isConfirmed) {
        if (
          (error?.status === 401 &&
            error?.internalErrorMessage === "Session already logged out") ||
          error?.status === 404
        ) {
          userService.logout();
          window.location.assign("/login");
        }
      }
    });
  }
  return res;
};

export default {
  handleErrorsCatch,
  handleErrors,
};
