const machineOptions = [
  { label: "ระบบการทำนาเปียกสลับแห้ง (AWD)", value: "m1" },
  { label: "ระบบน้ำหยดในแปลงเกษตร", value: "m2" },
  { label: "เครื่องจักรทั่วไป", value: "m3" },
];

const riceOptions = [
  { label: "ข้าวเจ้าหอมมะลิ", value: "ri1" },
  { label: "ข้าวเจ้าปทุมธานี 1", value: "ri2" },
  { label: "ข้าวเจ้าขาวอื่น", value: "ri3" },
  { label: "ข้าวเหนียว", value: "ri4" },
  { label: "ข้าวโพดเลี้ยงสัตว์", value: "mp" },
  { label: "มันสำปะหลังโรงงาน", value: "cf" },
  { label: "อ้อยโรงงาน", value: "sc" },
  { label: "ยางพารา", value: "rb" },
  { label: "ปาล์มนํ้ามัน", value: "op" },
];

const mmsOptions = [
  { label: "Multinomial Distribution", value: 0 },
  { label: "Normal Distribution", value: 1 },
  { label: "Poisson Distribution", value: 2 },
  { label: "Uniform Distribution", value: 3 },
  { label: "Logistic Distribution", value: 4 },
  { label: "กำหนดค่าเอง", value: 5 },
];

const csOptions = [
  { label: "Mean", value: "mean" },
  { label: "Standard deviation", value: "sd" },
];

const options = {
  machineOptions,
  riceOptions,
  mmsOptions,
  csOptions,
};

export default options;
