import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { Bar, Line } from "react-chartjs-2";
import _ from "lodash";
import { useResultContext } from "..";
import ExportImage from "../ExportImage";

const Select7 = () => {
  const { labelsYearChartArr, dataResult1, dataResult2 } = useResultContext();

  let refLinePolicy1_1 = useRef(null);
  let refLinePolicy1_2 = useRef(null);
  let refLinePolicy2_1 = useRef(null);
  let refLinePolicy2_2 = useRef(null);
  let refLinePolicy3_1 = useRef(null);
  let refLinePolicy3_2 = useRef(null);
  let refLinePolicy4_1 = useRef(null);
  let refLinePolicy4_2 = useRef(null);

  const findParticipatePolicyByType = (data, fieldName) => {
    if (!_.isEmpty(data)) {
      let res = [];
      data.map((item) => {
        console.log(fieldName);
        console.log(item[fieldName]);
        const val = item[fieldName] ? item[fieldName] * 100 : 0;
        res.push(val);
      });
      return res;
    } else {
      return [];
    }
  };

  const labels = labelsYearChartArr();

  const colors = {
    blue: "#1b57a5",
    red: "#eb5165",
    sky: "#4286e0",
    orange: "#ff965c",
    ocean: "#55bdde",
    blood: "#cb4e56",
    purple: "#7f6cff",
    gold: "#cc8720",
  };
  const lineDataPolicy1_1 = {
    labels,
    datasets: [
      {
        label: "มาตรการการจ่ายเงินช่วยเหลือเกษตรกรผู้ประสบภัยพิบัติกรณีฉุกเฉิน",
        data: findParticipatePolicyByType(dataResult1, "participatePolicy1"),
        borderColor: colors.blue,
        backgroundColor: colors.blue,
        pointRadius: 2,
        tension: 0.4,
      },
    ],
  };

  const lineDataPolicy1_2 = {
    labels,
    datasets: [
      {
        label: "มาตรการการจ่ายเงินช่วยเหลือเกษตรกรผู้ประสบภัยพิบัติกรณีฉุกเฉิน",
        data: findParticipatePolicyByType(dataResult2, "participatePolicy1"),
        borderColor: colors.red,
        backgroundColor: colors.red,
        pointRadius: 2,
        tension: 0.4,
      },
    ],
  };

  const lineDataPolicy2_1 = {
    labels,
    datasets: [
      {
        label: "มาตรการสนับสนุนการทำประกันภัยการเกษตร",
        data: findParticipatePolicyByType(dataResult1, "participatePolicy2"),
        borderColor: colors.sky,
        backgroundColor: colors.sky,
        pointRadius: 2,
        tension: 0.4,
      },
    ],
  };

  const lineDataPolicy2_2 = {
    labels,
    datasets: [
      {
        label: "มาตรการสนับสนุนการทำประกันภัยการเกษตร",
        data: findParticipatePolicyByType(dataResult2, "participatePolicy2"),
        borderColor: colors.orange,
        backgroundColor: colors.orange,
        pointRadius: 2,
        tension: 0.4,
      },
    ],
  };

  const lineDataPolicy3_1 = {
    labels,
    datasets: [
      {
        label: "มาตรการรักษาเสถียรภาพรายได้ของเกษตรกร",
        data: findParticipatePolicyByType(dataResult1, "participatePolicy3"),
        borderColor: colors.ocean,
        backgroundColor: colors.ocean,
        pointRadius: 2,
        tension: 0.4,
      },
    ],
  };

  const lineDataPolicy3_2 = {
    labels,
    datasets: [
      {
        label: "มาตรการรักษาเสถียรภาพรายได้ของเกษตรกร",
        data: findParticipatePolicyByType(dataResult2, "participatePolicy3"),
        borderColor: colors.blood,
        backgroundColor: colors.blood,
        pointRadius: 2,
        tension: 0.4,
      },
    ],
  };

  const lineDataPolicy4_1 = {
    labels,
    datasets: [
      {
        label: "มาตรการสนับสนุนการปรับเปลี่ยนเป็นเกษตรกรรมที่เท่าทันต่อภูมิอากาศ",
        data: findParticipatePolicyByType(dataResult1, "participatePolicy4"),
        borderColor: colors.purple,
        backgroundColor: colors.purple,
        pointRadius: 2,
        tension: 0.4,
      },
    ],
  };

  const lineDataPolicy4_2 = {
    labels,
    datasets: [
      {
        label: "มาตรการสนับสนุนการปรับเปลี่ยนเป็นเกษตรกรรมที่เท่าทันต่อภูมิอากาศ",
        data: findParticipatePolicyByType(dataResult2, "participatePolicy4"),
        borderColor: colors.gold,
        backgroundColor: colors.gold,
        pointRadius: 2,
        tension: 0.4,
      },
    ],
  };

  const optionsLine = (name) => {
    return {
      plugins: {
        title: {
          display: true,
          align: "center",
          text: name,
          font: {
            family: "'Prompt', sans-serif",
            size: 14,
          },
        },
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          title: {
            align: "center",
            display: true,
            text: "ร้อยละครัวเรือน",
            font: {
              family: "'Prompt', sans-serif",
              size: 14,
            },
          },
        },
        x: {
          title: {
            align: "center",
            display: true,
            text: "คาบ",
            font: {
              family: "'Prompt', sans-serif",
              size: 14,
            },
          },
        },
      },
      responsive: false,
      animation: false,
    };
  };

  return (
    <div>
      <Row className="mb-3">
        <Col>
          <div className="border-card mt-3 text-center chart-panel" style={{}}>
            <div className="m-auto mt-3" style={{ width: 500 }}>
              <ExportImage
                ref={refLinePolicy1_1}
                fileName={"ParticipatePolicy1_Line1"}
                chartSettings={{
                  type: "line",
                  data: lineDataPolicy1_1,
                  options: optionsLine("มาตรการการจ่ายเงินช่วยเหลือเกษตรกรผู้ประสบภัยพิบัติกรณีฉุกเฉิน"),
                }}
              />
              <Line
                ref={refLinePolicy1_1}
                data={lineDataPolicy1_1}
                options={{
                  ...optionsLine("มาตรการการจ่ายเงินช่วยเหลือเกษตรกรผู้ประสบภัยพิบัติกรณีฉุกเฉิน"),
                  responsive: true,
                  animation: true,
                }}
              />
            </div>
          </div>
        </Col>
        <Col>
          <div className="border-card mt-3 text-center chart-panel" style={{}}>
            <div className="m-auto mt-3" style={{ width: 500 }}>
              <ExportImage
                ref={refLinePolicy1_2}
                fileName={"ParticipatePolicy1_Line2"}
                chartSettings={{
                  type: "line",
                  data: lineDataPolicy1_2,
                  options: optionsLine("มาตรการการจ่ายเงินช่วยเหลือเกษตรกรผู้ประสบภัยพิบัติกรณีฉุกเฉิน"),
                }}
              />
              <Line
                ref={refLinePolicy1_2}
                data={lineDataPolicy1_2}
                options={{
                  ...optionsLine("มาตรการการจ่ายเงินช่วยเหลือเกษตรกรผู้ประสบภัยพิบัติกรณีฉุกเฉิน"),
                  responsive: true,
                  animation: true,
                }}
              />
            </div>
            <div id="wrapper"></div>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div className="border-card mt-3 text-center chart-panel" style={{}}>
            <div className="m-auto mt-3" style={{ width: 500 }}>
              <ExportImage
                ref={refLinePolicy2_1}
                fileName={"ParticipatePolicy2_Line1"}
                chartSettings={{
                  type: "line",
                  data: lineDataPolicy2_1,
                  options: optionsLine("มาตรการสนับสนุนการทำประกันภัยการเกษตร"),
                }}
              />
              <Line
                ref={refLinePolicy2_1}
                data={lineDataPolicy2_1}
                options={{
                  ...optionsLine("มาตรการสนับสนุนการทำประกันภัยการเกษตร"),
                  responsive: true,
                  animation: true,
                }}
              />
            </div>
          </div>
        </Col>
        <Col>
          <div className="border-card mt-3 text-center chart-panel" style={{}}>
            <div className="m-auto mt-3" style={{ width: 500 }}>
              <ExportImage
                ref={refLinePolicy2_2}
                fileName={"ParticipatePolicy2_Line2"}
                chartSettings={{
                  type: "line",
                  data: lineDataPolicy2_2,
                  options: optionsLine("มาตรการสนับสนุนการทำประกันภัยการเกษตร"),
                }}
              />
              <Line
                ref={refLinePolicy2_2}
                data={lineDataPolicy2_2}
                options={{
                  ...optionsLine("มาตรการสนับสนุนการทำประกันภัยการเกษตร"),
                  responsive: true,
                  animation: true,
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div className="border-card mt-3 text-center chart-panel" style={{}}>
            <div className="m-auto mt-3" style={{ width: 500 }}>
              <ExportImage
                ref={refLinePolicy3_1}
                fileName={"ParticipatePolicy3_Line1"}
                chartSettings={{
                  type: "line",
                  data: lineDataPolicy3_1,
                  options: optionsLine("มาตรการรักษาเสถียรภาพรายได้ของเกษตรกร"),
                }}
              />
              <Line
                ref={refLinePolicy3_1}
                data={lineDataPolicy3_1}
                options={{
                  ...optionsLine("มาตรการรักษาเสถียรภาพรายได้ของเกษตรกร"),
                  responsive: true,
                  animation: true,
                }}
              />
            </div>
          </div>
        </Col>
        <Col>
          <div className="border-card mt-3 text-center chart-panel" style={{}}>
            <div className="m-auto mt-3" style={{ width: 500 }}>
              <ExportImage
                ref={refLinePolicy3_2}
                fileName={"ParticipatePolicy3_Line2"}
                chartSettings={{
                  type: "line",
                  data: lineDataPolicy3_2,
                  options: optionsLine("มาตรการรักษาเสถียรภาพรายได้ของเกษตรกร"),
                }}
              />
              <Line
                ref={refLinePolicy3_2}
                data={lineDataPolicy3_2}
                options={{
                  ...optionsLine("มาตรการรักษาเสถียรภาพรายได้ของเกษตรกร"),
                  responsive: true,
                  animation: true,
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div className="border-card mt-3 text-center chart-panel" style={{}}>
            <div className="m-auto mt-3" style={{ width: 500 }}>
              <ExportImage
                ref={refLinePolicy4_1}
                fileName={"ParticipatePolicy4_Line1"}
                chartSettings={{
                  type: "line",
                  data: lineDataPolicy4_1,
                  options: optionsLine("มาตรการสนับสนุนการปรับเปลี่ยนเป็นเกษตรกรรมที่เท่าทันต่อภูมิอากาศ"),
                }}
              />
              <Line
                ref={refLinePolicy4_1}
                data={lineDataPolicy4_1}
                options={{
                  ...optionsLine("มาตรการสนับสนุนการปรับเปลี่ยนเป็นเกษตรกรรมที่เท่าทันต่อภูมิอากาศ"),
                  responsive: true,
                  animation: true,
                }}
              />
            </div>
          </div>
        </Col>
        <Col>
          <div className="border-card mt-3 text-center chart-panel" style={{}}>
            <div className="m-auto mt-3" style={{ width: 500 }}>
              <ExportImage
                ref={refLinePolicy4_2}
                fileName={"ParticipatePolicy4_Line2"}
                chartSettings={{
                  type: "line",
                  data: lineDataPolicy4_2,
                  options: optionsLine("มาตรการสนับสนุนการปรับเปลี่ยนเป็นเกษตรกรรมที่เท่าทันต่อภูมิอากาศ"),
                }}
              />
              <Line
                ref={refLinePolicy4_2}
                data={lineDataPolicy4_2}
                options={{
                  ...optionsLine("มาตรการสนับสนุนการปรับเปลี่ยนเป็นเกษตรกรรมที่เท่าทันต่อภูมิอากาศ"),
                  responsive: true,
                  animation: true,
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Select7;
