import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useResultContext } from "..";
import _ from "lodash";
import ExportImage from "../ExportImage";

const labelDoughnut = ["เช่า", "เป็นเจ้าของ", "เป็นเจ้าของและเช่า"];
const colorDoughnut1 = ["#4078bc", "#455c78", "#023a83"];
const colorDoughnut2 = ["#fd6e6e", "#e8384f", "#923742"];

const Select1 = () => {
  const { dataResult1, dataResult2, labelsYearChartArr } = useResultContext();

  const [doughnut1, setDoughnut1] = useState([0, 0, 0]);
  const [doughnut2, setDoughnut2] = useState([0, 0, 0]);

  let refDoughnut1 = useRef(null);
  let refDoughnut2 = useRef(null);
  let refLine1 = useRef(null);
  let refLine2 = useRef(null);

  useEffect(() => {
    if (!_.isEmpty(dataResult1)) {
      let dougnut = [];

      //Percent Of landRenter
      let landRenterPercent = [];
      dataResult1.map(({ landRenter, landTotal }) => {
        let percent = ((landRenter / landTotal) * 100).toFixed(2);
        landRenterPercent.push(percent);
      });
      let landRenterTotalPercent = landRenterPercent.reduce(
        (accumulator, percent) => {
          return accumulator + parseFloat(percent);
        },
        0
      );
      let landRenterPercentAvg = Number(
        landRenterTotalPercent / landRenterPercent.length
      ).toFixed(2);
      dougnut.push(landRenterPercentAvg);

      //Percent Of landOwner
      let landOwnerPercent = [];
      dataResult1.map(({ landOwner, landTotal }) => {
        let percent = ((landOwner / landTotal) * 100).toFixed(2);
        landOwnerPercent.push(percent);
      });
      let landOwnerTotalPercent = landOwnerPercent.reduce(
        (accumulator, percent) => {
          return accumulator + parseFloat(percent);
        },
        0
      );
      let landOwnerPercentAvg = Number(
        landOwnerTotalPercent / landOwnerPercent.length
      ).toFixed(2);
      dougnut.push(landOwnerPercentAvg);

      //Percent Of landRentee
      let landRenteePercent = [];
      dataResult1.map(({ landRentee, landTotal }) => {
        let percent = ((landRentee / landTotal) * 100).toFixed(2);
        landRenteePercent.push(percent);
      });
      let landRenteeTotalPercent = landRenteePercent.reduce(
        (accumulator, percent) => {
          return accumulator + parseFloat(percent);
        },
        0
      );
      let landRenteePercentAvg = Number(
        landRenteeTotalPercent / landRenteePercent.length
      ).toFixed(2);
      dougnut.push(landRenteePercentAvg);

      setDoughnut1(dougnut);
    }
  }, [dataResult1]);

  useEffect(() => {
    if (!_.isEmpty(dataResult2)) {
      let dougnut = [];

      //Percent Of landRenter
      let landRenterPercent = [];
      dataResult2.map(({ landRenter, landTotal }) => {
        let percent = ((landRenter / landTotal) * 100).toFixed(2);
        landRenterPercent.push(percent);
      });
      let landRenterTotalPercent = landRenterPercent.reduce(
        (accumulator, percent) => {
          return accumulator + parseFloat(percent);
        },
        0
      );
      let landRenterPercentAvg = Number(
        landRenterTotalPercent / landRenterPercent.length
      ).toFixed(2);
      dougnut.push(landRenterPercentAvg);

      //Percent Of landOwner
      let landOwnerPercent = [];
      dataResult2.map(({ landOwner, landTotal }) => {
        let percent = ((landOwner / landTotal) * 100).toFixed(2);
        landOwnerPercent.push(percent);
      });
      let landOwnerTotalPercent = landOwnerPercent.reduce(
        (accumulator, percent) => {
          return accumulator + parseFloat(percent);
        },
        0
      );
      let landOwnerPercentAvg = Number(
        landOwnerTotalPercent / landOwnerPercent.length
      ).toFixed(2);
      dougnut.push(landOwnerPercentAvg);

      //Percent Of landRentee
      let landRenteePercent = [];
      dataResult2.map(({ landRentee, landTotal }) => {
        let percent = ((landRentee / landTotal) * 100).toFixed(2);
        landRenteePercent.push(percent);
      });
      let landRenteeTotalPercent = landRenteePercent.reduce(
        (accumulator, percent) => {
          return accumulator + parseFloat(percent);
        },
        0
      );
      let landRenteePercentAvg = Number(
        landRenteeTotalPercent / landRenteePercent.length
      ).toFixed(2);
      dougnut.push(landRenteePercentAvg);

      setDoughnut2(dougnut);
    }
  }, [dataResult2]);

  const labels = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ];

  const makePropDoughnut = (data, color) => {
    return {
      labels: labelDoughnut,
      datasets: [
        {
          label: "%",
          data: data,
          backgroundColor: color,
          borderWidth: 1,
        },
      ],
      text: "ฉากทัศน์หลัก",
    };
  };

  const optionsDoughnut = {
    responsiveAnimationDuration: 0,
    cutout: 50,
    plugins: {
      // title: {
      //   display: true,
      //   text: "ปี 2565",
      //   align: "start",
      // },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          font: {
            family: "'Prompt', sans-serif",
            size: 12,
          },
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
      datalabels: {
        color: "#fff",
        formatter: (value) => {
          return value + "%";
        },
        font: {
          family: "'Prompt', sans-serif",
          size: 12,
        },
      },
    },
    responsive: false,
    animation: false,
  };

  const getArrayOfString = (arr, fieldName) => {
    let res = [];
    arr.map((item) => res.push(item[fieldName]));
    return res;
  };

  const optionsLine = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          align: "center",
          display: true,
          text: "คาบ",
          font: {
            family: "'Prompt', sans-serif",
            size: 14,
          },
        },
      },
      y: {
        title: {
          align: "center",
          display: true,
          text: "จำนวนไร่ทั้งหมด",
          font: {
            family: "'Prompt', sans-serif",
            size: 14,
          },
        },
      },
    },
    responsive: false,
    animation: false,
  };

  const lineData1 = {
    labels: labelsYearChartArr(),
    datasets: [
      {
        label: "จำนวนไร่",
        data: getArrayOfString(dataResult1, "landTotal"),
        fill: true,
        borderColor: "#4186E099",
        backgroundColor: "#4186E099",
        pointRadius: 2,
        tension: 0.4,
      },
    ],
  };

  const lineData2 = {
    labels: labelsYearChartArr(),
    datasets: [
      {
        label: "จำนวนไร่",
        data: getArrayOfString(dataResult2, "landTotal"),
        fill: true,
        borderColor: "#E8384F99",
        backgroundColor: "#E8384F99",
        pointRadius: 2,
        tension: 0.4,
      },
    ],
  };

  return (
    <div>
      <Row>
        <Col>
          <div className="border-card mt-3 text-center chart-panel">
            {/* <p className="text-left p-2">ปี 2565</p> */}
            <div className="m-auto my-3" style={{ width: 270 }}>
              <ExportImage
                ref={refDoughnut1}
                fileName={"Land_Doughnut1"}
                chartSettings={{
                  type: "doughnut",
                  data: makePropDoughnut(doughnut1, colorDoughnut1),
                  options: optionsDoughnut,
                }}
              />
              <Doughnut
                ref={refDoughnut1}
                data={makePropDoughnut(doughnut1, colorDoughnut1)}
                options={{
                  ...optionsDoughnut,
                  responsive: true,
                  animation: true,
                }}
                plugins={[ChartDataLabels]}
              />
            </div>
          </div>
        </Col>
        <Col>
          <div className="border-card mt-3 text-center chart-panel">
            {/* <p className="text-left p-2">ปี 2565</p> */}
            <div className="m-auto my-3" style={{ width: 270 }}>
              <ExportImage
                ref={refDoughnut2}
                fileName={"Land_Doughnut2"}
                chartSettings={{
                  type: "doughnut",
                  data: makePropDoughnut(doughnut2, colorDoughnut2),
                  options: optionsDoughnut,
                }}
              />
              <Doughnut
                ref={refDoughnut2}
                data={makePropDoughnut(doughnut2, colorDoughnut2)}
                options={{
                  ...optionsDoughnut,
                  responsive: true,
                  animation: true,
                }}
                plugins={[ChartDataLabels]}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="border-card mt-3 text-center chart-panel">
            <div className="m-auto my-3" style={{ width: 500 }}>
              <ExportImage
                ref={refLine1}
                fileName={"Land_Line1"}
                chartSettings={{
                  type: "line",
                  data: lineData1,
                  options: optionsLine,
                }}
              />
              <Line
                ref={refLine1}
                data={lineData1}
                options={{ ...optionsLine, responsive: true, animation: true }}
              />
            </div>
          </div>
        </Col>
        <Col>
          <div className="border-card mt-3 text-center chart-panel">
            <div className="m-auto my-3" style={{ width: 500 }}>
              <ExportImage
                ref={refLine2}
                fileName={"Land_Line2"}
                chartSettings={{
                  type: "line",
                  data: lineData2,
                  options: optionsLine,
                }}
              />
              <Line
                ref={refLine2}
                data={lineData2}
                options={{ ...optionsLine, responsive: true, animation: true }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Select1;
