import { fetchWrapper } from "../helpers/fetch-wrapper";
import service from "./index";

const apiResult = "/api/v1/abm/simulate/result/simulation";
const apiExport = "/api/v1/abm/simulate/export";

export const resultService = {
  resultStep,
  resultFromTo,
  exportFile,
};

function resultStep(req) {
  const { simulationID, step } = req;
  return fetchWrapper
    .get(`${apiResult}/${simulationID}/${step}`)
    .then(service.handleErrors);
}

function resultFromTo(req) {
  const { simulationID, from, to } = req;
  return fetchWrapper
    .get(`${apiResult}/${simulationID}/${from}/${to}`)
    .then(service.handleErrors);
}

function exportFile(req) {
  const { simulationID, from, to, extension } = req;
  // return fetchWrapper.get(
  //   `${apiExport}/${simulationID}/${from}/${to}?extension=${extension}`
  // );
  return fetchWrapper.getAxios(
    `${apiExport}/${simulationID}/${from}/${to}?extension=${extension}`
  );
}

// function handleErrors(res) {
//   const { success, error } = res;
//   if (!success) {
//     const { message, internalErrorMessage } = error || {};
//     Swal.fire(message, internalErrorMessage, "error").then((result) => {
//       if (result.isConfirmed) {
//         if (error?.status === 401) {
//           checkSessionExpire()
//         }
//       }
//     });
//   }
//   return res;
// }
