import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useRef,
} from "react";
import { Card, Tab } from "@mui/material";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import _ from "lodash";
import "../../scss/_cmodel.scss";
import Tab1 from "./tab1";
import Tab2 from "./tab2";
import Tab3 from "./tab3";
import PresetModal from "./PresetModal";
import { presetService } from "../../services/preset.service";
import { simulationService } from "../../services/simulation.service";
import { datasetService } from "../../services/dataset.service";
import Swal from "sweetalert2";

const CustomizeModelContext = createContext();

const CustomizeModel = () => {
  let intervalPercentageRef = useRef();
  let intervalRunningRef = useRef();
  // let timer;
  const [tab, setTab] = React.useState("1");
  const [presetList, setPresetList] = useState(null);
  const [selectPreset, setSelectPreset] = useState(null);
  const [presetDefault, setPresetDefault] = useState(null);
  const [presetActive, setPresetActive] = useState({
    id: null,
    abmParameterID: null,
  });
  const [modelSummary, setModelSummary] = useState(null);
  const [isSimulate, setIsSimulate] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [runningLogs, setRunningLogs] = useState([]);
  const [view, setView] = useState({ isOpen: false, data: null });
  const [dataManage, setDataManage] = useState(null);
  const [allStep, setAllStep] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [runType, setRunType] = useState(null);

  useEffect(() => {
    getPresetList();
    getDefaultPreset();
    getDataList();
    checkSimIsRunning();
  }, []);

  useEffect(() => {
    if (tab === "1") {
      onGetPresetActive();
    }
  }, [tab]);

  useEffect(() => {
    if (presetActive?.abmParameterID) {
      onHandleGetModelSummary();
    }
  }, [presetActive]);

  useEffect(() => {
    if (isSimulate) {
      onHandleGetLog();
    } else {
      clearStateSimulate();
    }
  }, [isSimulate]);

  useEffect(() => {
    if (allStep && currentStep && allStep >= currentStep) {
      const percent = (currentStep * 100) / allStep;
      setPercentage(parseInt(percent) ? parseInt(percent) : 0);
    }

    //Check 100%
    if (allStep && currentStep && allStep === currentStep) {
      clearInterval(intervalPercentageRef.current);
      clearInterval(intervalRunningRef.current);
    }
  }, [allStep, currentStep]);

  const checkSimIsRunning = () => {
    simulationService.running().then((res) => {
      const { success } = res || {};
      if (success) {
        const { entity } = res?.data;
        if (
          entity?.workState === "inprogress" &&
          entity?.currentStep < entity?.allStep
        ) {
          setIsSimulate(true);
          setAllStep(entity?.allStep);
          setCurrentStep(entity?.currentStep);
          setRunType(entity?.isBatchRun ? "batch" : "normal");
        }
      }
    });
  };

  const clearStateSimulate = () => {
    setPercentage(0);
    setIsSimulate(false);
    setRunningLogs([]);
    clearInterval(intervalPercentageRef.current);
    clearInterval(intervalRunningRef.current);
  };

  const getDefaultPreset = () => {
    presetService.getPresetDefault().then((res) => {
      setPresetDefault(res);
    });
  };

  const onGetPresetActive = () => {
    presetService.getPresetActive().then((res) => {
      setPresetActive(res);
    });
  };

  const onHandleGetModelSummary = async () => {
    const data = await getPresetDetail(presetActive);
    setModelSummary(data);
  };

  const onHandleSimulate = (idDataset, runType) => {
    if (percentage >= 100 && isSimulate) {
      clearStateSimulate();
      return;
    }

    if (isSimulate) {
      onHandleCancelSimulation();
      return;
    }

    if (!_.isEmpty(presetActive) && idDataset) {
      clearStateSimulate();
      setRunType(runType);
      if (runType === "batch") {
        simulationService
          .simulationBatch(idDataset)
          .then((res) => {
            const { success, error } = res;
            if (success) {
              setIsSimulate(true);
            } else {
              if (
                error.internalErrorMessage !== "Please calibrate dataset first."
              ) {
                onHandleCancelSimulation();
              }
            }
          })
          .catch((err) => {});
      } else {
        simulationService
          .simulation(idDataset)
          .then((res) => {
            const { success, error } = res;
            if (success) {
              setIsSimulate(true);
            } else {
              // if err call cancel simulation
              if (
                error.internalErrorMessage !== "Please calibrate dataset first."
              ) {
                onHandleCancelSimulation();
              }
            }
          })
          .catch((err) => {});
      }
    }
  };

  const onHandleGetLog = () => {
    let lists = [];
    let currentS = 0;
    const intervalRunning = setInterval(() => {
      simulationService.running().then((res) => {
        const { success } = res || {};
        if (success) {
          const { entity } = res?.data;
          if (!_.isEmpty(entity)) {
            if (_.isEmpty(allStep)) {
              setAllStep(entity?.allStep);
            }
            if (entity?.currentStep !== currentS) {
              setCurrentStep(entity?.currentStep);
              currentS = entity?.currentStep;
              lists.push(entity);
              setRunningLogs([...lists]);
              // handleSetCurrentStep(entity?.currentStep);
            }
          }
        }
      });
    }, 2000);
    intervalRunningRef.current = intervalRunning;
    return () => clearInterval(intervalRunning);
  };

  const handleSetCurrentStep = (entity) => {};

  const onHandleCancelSimulation = () => {
    simulationService
      .cancel()
      .then((res) => {
        const { success } = res;
        if (success) {
          clearStateSimulate();
        }
      })
      .catch((err) => {});
  };

  const onHandleUpdatePresetActive = (presetId) => {
    presetService
      .updatePresetActive(presetId)
      .then((res) => {
        const { success } = res;
        if (success) {
          onGetPresetActive();
          clearStateSimulate();
        }
      })
      .catch((err) => {});
  };

  const onHandleView = async (objPreset) => {
    const entity = await getPresetDetail(objPreset);
    if (!_.isEmpty(entity)) {
      setView({ isOpen: true, data: entity });
    }
  };

  const onHandleCloseView = () => {
    setView({
      isOpen: false,
      data: null,
    });
  };

  const onHandleEditPreset = async (objPreset) => {
    const entity = await getPresetDetail(objPreset);
    if (!_.isEmpty(entity)) {
      const headerObj = objPreset;
      setSelectPreset({
        detail: entity,
        header: headerObj,
      });
      setTab("2");
    }
  };

  const getPresetDetail = async (objPreset) => {
    const abmId = objPreset?.abmParameterID;
    return presetService
      .getPresetDetail(abmId)
      .then((res) => {
        const { success } = res;
        if (success) {
          const { entity } = res?.data || {};
          return entity;
        }
      })
      .catch((err) => {
        return false;
      });
  };

  const getPresetList = () => {
    presetService
      .getPresetList()
      .then((res) => {
        const { success } = res;
        if (success) {
          const { entities } = res?.data || {};
          if (!_.isEmpty(entities)) {
            setPresetList(entities);
          } else {
            setPresetList(null);
            setModelSummary(null);
          }
        }
      })
      .catch((err) => {});
  };

  const handleDeletePreset = (item) => {
    Swal.fire({
      title: `ลบ ${item?.name}`,
      html: `<p>คุณแน่ใจหรือว่าต้องการลบ <span class="text-primary">${item?.name}</span> ?</p>`,
      // icon: "warning",
      customClass: {
        container: "modal-delete",
      },
      width: "400px",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: "#D45252",
      cancelButtonColor: "#BBBBBB",
      confirmButtonText: "ลบ",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        presetService
          .delete(item?.id)
          .then((res) => {
            const { success } = res;
            if (success) {
              getPresetList();
              setModelSummary(null);
              Swal.fire("ลบสำเร็จ!", "ข้อมูลถูกลบเรียบร้อย", "success");
            }
          })
          .catch((err) => {
            Swal.fire("ลบไม่สำเร็จ!", "ไม่สามารถลบได้", "error");
          });
      }
    });
  };

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  const getDataList = () => {
    datasetService
      .getDataList()
      .then((res) => {
        const { success } = res;
        if (success) {
          const { entities } = res?.data || {};
          if (!_.isEmpty(entities)) {
            setDataManage(entities);
          } else {
            setDataManage(null);
          }
        }
      })
      .catch((err) => {});
  };

  const context = {
    presetActive,
    presetDefault,
    presetList,
    getPresetList,
    selectPreset,
    setSelectPreset,
    handleDeletePreset,
    onHandleEditPreset,
    getPresetDetail,
    setTab,
    onHandleView,
    onHandleUpdatePresetActive,
    onHandleSimulate,
    onHandleCancelSimulation,
    isSimulate,
    percentage,
    runningLogs,
    modelSummary,
    dataManage,
    runType,
  };

  return (
    <Box sx={{ typography: "body1" }}>
      {/*{JSON.stringify(tab,1,1)}*/}
      <TabContext value={tab}>
        <Card>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="fullWidth"
            >
              <Tab
                className={tab === "1" ? "custom-tab-bg" : "custom-tab-text"}
                label={
                  <div>
                    <p className="mb-0" style={{ fontSize: 18 }}>
                      จำลองสถานการณ์
                    </p>
                    <p style={{ fontSize: 12 }}>(Model Simulation)</p>
                  </div>
                }
                value="1"
              />
              <Tab
                className={tab === "2" ? "custom-tab-bg" : "custom-tab-text"}
                label={
                  <div>
                    <p className="mb-0" style={{ fontSize: 18 }}>
                      ปรับแต่งค่าแบบจำลอง
                    </p>
                    <p style={{ fontSize: 12 }}>(model adjustment)</p>
                  </div>
                }
                value="2"
              />
              <Tab
                className={tab === "3" ? "custom-tab-bg" : "custom-tab-text"}
                label={
                  <div>
                    <p className="mb-0" style={{ fontSize: 18 }}>
                      จัดการฉากทัศน์
                    </p>
                    <p style={{ fontSize: 12 }}>(scenario Management)</p>
                  </div>
                }
                value="3"
              />
            </TabList>
          </Box>
        </Card>
        <CustomizeModelContext.Provider value={context}>
          <TabPanel value="1">
            <Tab1 />
          </TabPanel>
          <TabPanel value="2">
            <Tab2 />
          </TabPanel>
          <TabPanel value="3">
            <Tab3 />
          </TabPanel>
        </CustomizeModelContext.Provider>
      </TabContext>
      <PresetModal
        isOpen={view?.isOpen}
        data={view?.data}
        toggle={onHandleCloseView}
      />
    </Box>
  );
};

export function useCustomModelContext() {
  return useContext(CustomizeModelContext);
}

export default CustomizeModel;
