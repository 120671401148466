import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Icon,
} from "@mui/material";
import ImageHome from "../../assets/Frame-4.png";
import { Col, Image, Row } from "react-bootstrap";
import "../../scss/_homePage.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { useCustomModelContext } from "./index";
import _ from "lodash";
import moment from "moment";

const Tab3 = () => {
  const { presetList, handleDeletePreset, onHandleEditPreset, onHandleView } =
    useCustomModelContext();

  const renderDateUpdate = (date) => {
    return (
      moment(date, "YYYY-MM-DD HH:mm:ss").format("HH:mm") +
      " " +
      moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")
    );
  };

  const renderPresetCardList = () => {
    if (!_.isEmpty(presetList)) {
      return (
        <>
          {presetList.map((item) => {
            return (
              <div className="mt-2">
                <Card
                  sx={{
                    minWidth: 275,
                    borderRadius: 2,
                    boxShadow: "unset",
                    height: 110,
                    border: "1px solid #BBBBBB",
                    padding: "unset !important",
                  }}
                  className="p-down w-90"
                >
                  <CardContent className="text-left">
                    <Row>
                      <Col md={10}>
                        <p style={{ fontSize: 20, fontWeight: "bold" }}>
                          {item?.name}
                        </p>
                        <div style={{ overflow: "scroll", height: 40 }}>
                          <p style={{ fontSize: 14 }}>{item?.description}</p>
                        </div>
                        <div>
                          <div className="d-flex">
                            <FontAwesomeIcon
                              icon={faClockRotateLeft}
                              style={{
                                fontSize: 12,
                                marginRight: 5,
                                marginTop: 4,
                              }}
                            />
                            <p
                              style={{
                                fontSize: 14,
                              }}
                            >
                              update {renderDateUpdate(item?.updatedOn)}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <Row>
                          <Col md={2}>
                            <div className="vl"></div>
                          </Col>
                          <Col>
                            <Row>
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: "#BBBBBB",
                                  marginRight: 5,
                                  width: 100,
                                }}
                                className="mb-1 m-auto"
                                onClick={() => onHandleView(item)}
                              >
                                <p style={{ fontSize: 10 }}>ดูค่าปรับแต่ง</p>
                              </Button>
                            </Row>
                            <Row>
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: "#4186E0",
                                  marginRight: 5,
                                  width: 100,
                                }}
                                className="mb-1 m-auto"
                                onClick={() => onHandleEditPreset(item)}
                              >
                                <p style={{ fontSize: 10 }}>ปรับแต่งค่า</p>
                              </Button>
                            </Row>
                            <Row>
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: "#D45252",
                                  marginRight: 5,
                                  width: 100,
                                }}
                                className="mb-1 m-auto"
                                onClick={() => handleDeletePreset(item)}
                              >
                                <p style={{ fontSize: 10 }}>ลบ</p>
                              </Button>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardContent>
                </Card>
              </div>
            );
          })}
        </>
      );
    } else {
      return (
        <Card
          className={"text-center"}
          variant="outlined"
          sx={{ border: "0px" }}
        >
          <p className={"my-3 font-w-bold"}>No data</p>
        </Card>
      );
    }
  };

  return (
    <div>
      <Card sx={{ minWidth: 275 }} className="mt-3">
        <CardHeader title={<p>จัดการฉากทัศน์</p>} />
        <CardContent>{renderPresetCardList()}</CardContent>
        {/*<pre>{JSON.stringify(presetList, 1, 1)}</pre>*/}
      </Card>
    </div>
  );
};

export default Tab3;
