const agent = {
  agentCount: "จำนวนตัวแทน (ครัวเรือน)",
  riskAversion: "ค่าเฉลี่ยระดับรสนิยมของความเสี่ยง (Risk Aversion)",
  discountRate: "อัตราคิดลด (ร้อยละ)",
  ratioOfPopulationToAge: "(ค่าเริ่มต้น) อายุเฉลี่ยของหัวหน้าครัวเรือน (ปี)",
  education: "(ค่าเริ่มต้น) ระดับการศึกษาของหัวหน้าครัวเรือน",
  ratioOfPopulationToSex: "(ค่าเริ่มต้น) สัดส่วนผู้หญิงในครัวเรือน",
  ratioOfPopulationToDepen:
    "(ค่าเริ่มต้น) สัดส่วนสมาชิกวัยพึ่งพิงในครัวเรือนเฉลี่ย",
  householdSize: "(ค่าเริ่มต้น) จำนวนสมาชิกในครัวเรือนเฉลี่ย (คน)",
  debt: "(ค่าเริ่มต้น) มูลค่าหนี้สินของครัวเรือนเฉลี่ย (บาท)",
  wealth: "(ค่าเริ่มต้น) มูลค่าความมั่งคั่งของครัวเรือนเฉลี่ย (บาท)",
  landSize: "(ค่าเริ่มต้น) ขนาดของที่ดิน (ไร่)",
  machineCount: "(ค่าเริ่มต้น) จำนวนเครื่องมือ/เครื่องจักรการเกษตร (ชิ้น)",
  agricultureCost: "(ค่าเริ่มต้น) ต้นทุนทางการเกษตรรวม (บาท)",
};

const landMarket = {
  landPrice: "ราคากลางที่ดิน (บาท/ไร่)",
  cropcosts: "ต้นทุนการผลิตรวม ไม่รวมต้นทุนปุ๋ยและที่ดิน (บาท/ไร่)",
  fertilizerCosts: "ต้นทุนปุ๋ย (บาท/ไร่)",
  loanInterestRateRoutine:
    "อัตราดอกเบี้ยเงินกู้เพื่อใช้จ่ายในชีวิตประจำวัน (ร้อยละ)",
  //loanInterestRateInvestor: "อัตราดอกเบี้ยเงินกู้เพื่อลงทุน (ร้อยละ)",
  machineCosts: "ราคาเครื่องจักร/เทคโนโลยี (บาท/ไร่)",
  roiInvestor: "อัตราผลตอบแทนจากการลงทุน (ร้อยละ)",
  roiInFarm: "ผลตอบแทนการทำงานในภาคเกษตร (บาท/คน/เดือน)",
  roiOffFarm: "ผลตอบแทนการทำงานนอกภาคเกษตร (บาท/คน/ชั่วโมง)",
  creditLimitInvertor: "วงเงินกู้เพื่อลงทุน",
  creditLimitRoutine: "วงเงินกู้เพื่อใช้จ่ายในชีวิตประจำวัน",
  creditLimitRatioProfit: "สัดส่วนของวงเงินกู้ที่กำหนดจากรายได้",
  creditLimitRatioLandPrice: "สัดส่วนของวงเงินกู้ที่กำหนดจากมูลค่าของที่ดิน",
  downPaymentRatioInvestor: "สัดส่วนเงินดาวน์เพื่อลงทุน (ร้อยละ)",
};

const disaster = {
  rainfallThreshold: "ปริมาณน้ำฝน (มิลลิเมตร)",
  temperatureTheshold: "อุณหภูมิ (องศาเซลเซียส)",
};

const emergencySubsidyPolicy = {
  FloodDamageCompensationRate: "เงินชดเชยภัย กรณีน้ำท่วม (บาท/ไร่)",
  DroughtDamageCompensationRate: "เงินชดเชยภัย กรณีแล้ง (บาท/ไร่)",
  assistedMaximumAreaCount: "จำนวนไร่สูงสุดที่ให้ความช่วยเหลือได้ (ไร่)",
  // efficientcyRate: "ระยะเวลารอ (วัน)",
};

const supportAgicultureInsurancePolicy = {
  premiumPriceHighRiskAreaTier1:
    "เบี้ยประกัน พื้นที่เสี่ยงสูง Tier 1 (บาท/ไร่)",
  premiumPriceMediumRiskAreaTier1:
    "เบี้ยประกัน พื้นที่เสี่ยงปานกลาง Tier 1 (บาท/ไร่)",
  premiumPriceLowRiskAreaTier1: "เบี้ยประกัน พื้นที่เสี่ยงต่ำ Tier 1 (บาท/ไร่)",
  premiumPriceHighRiskAreaTier2:
    "เบี้ยประกัน พื้นที่เสี่ยงสูง Tier 2 (บาท/ไร่)",
  premiumPriceMediumRiskAreaTier2:
    "เบี้ยประกัน พื้นที่เสี่ยงปานกลาง Tier 2 (บาท/ไร่)",
  premiumPriceLowRiskAreaTier2: "เบี้ยประกัน พื้นที่เสี่ยงต่ำ Tier 2 (บาท/ไร่)",
  CompensationHighRiskAreaTier1: "เงินชดเชย พื้นที่เสี่ยงสูง Tier 1 (บาท/ไร่)",
  CompensationMediumRiskAreaTier1:
    "เงินชดเชย พื้นที่เสี่ยงปานกลาง Tier 1 (บาท/ไร่)",
  CompensationLowRiskAreaTier1: "เงินชดเชย พื้นที่เสี่ยงต่ำ Tier 1 (บาท/ไร่)",
  CompensationHighRiskAreaTier2: "เงินชดเชย พื้นที่เสี่ยงสูง Tier 2 (บาท/ไร่)",
  CompensationMediumRiskAreaTier2:
    "เงินชดเชย พื้นที่เสี่ยงปานกลาง Tier 2 (บาท/ไร่)",
  CompensationLowRiskAreaTier2: "เงินชดเชย พื้นที่เสี่ยงต่ำ Tier 2 (บาท/ไร่)",
  SupportPremiumHighRiskAreaTier1:
    "เงินสนับสนุนเบี้ยประกันจากรัฐบาลพืนที่ความเสี่ยงสูง Tier 1",
  SupportPremiumMediumRiskAreaTier1:
    "เงินสนับสนุนเบี้ยประกันจากรัฐบาลพืนที่ความเสี่ยงปานกลาง Tier 1",
  SupportPremiumLowRiskAreaTier1:
    "เงินสนับสนุนเบี้ยประกันจากรัฐบาลพื้นที่ความเสี่ยงต่ำ Tier 1",
  efficientcyRate: "ระยะเวลารอ (ปี)",
};

const incomeInsurancePolicy = {
  guaranteePrice: "ราคาประกัน (บาท/กิโลกรัม)",
  assistedMaximumAreaCount: "จำนวนไร่สูงสุดที่ให้ความช่วยเหลือได้ (ไร่)",
};

const climateSmartAgriculturePolicy = {
  awdSubsidyCost:
    "เงินสนับสนุนต้นทุนในการซื้อเครื่องจักร/เทคโนโลยี AWD (บาท)",
  dripSubsidyCost:
    "เงินสนับสนุนต้นทุนในการซื้อเครื่องจักร/เทคโนโลยีน้ำหยด (บาท)",
};

const belief = {
  priorExpectMeanScalingMachineUsage:
    "Prior expectation on the mean ของ Scaling fac...",
  priorExpectMeanScalingWeatherYield:
    "Prior expectation on the mean ของ Scaling fac...",
  priorStrengthMeanCropPrice: "Prior strength on the mean () ของ ราคาพืชผล",
  priorStrengthMeanScalingMachineUsage:
    "Prior strength on the mean () ของ Scaling fac...",
  priorStrengthMeanWeatherYield:
    "Prior strength on the mean () ของสภาพอากาศ...",
  priorExpectVarianceScalingMachineUsage:
    "Prior expected variance ของ Scaling factor...",
  priorExpectVarianceScalingWeatherYield:
    "Prior expected variance ของ Scaling factor...",
  priorStrengthVarianceCropPrice:
    "Prior strength on the variance () ของ ราคาพืชผล",
  priorStrengthVarianceScalingMachineUsage:
    "Prior strength on the variance () ของ Scaling...",
  priorStrengthVarianceScalingWeatherYield:
    "Prior strength on the variance () ของ Scaling...",
};

const lang = {
  ...agent,
  ...belief,
  ...landMarket,
  ...disaster,
  ...emergencySubsidyPolicy,
  ...supportAgicultureInsurancePolicy,
  ...incomeInsurancePolicy,
  ...climateSmartAgriculturePolicy,
};

export default lang;
