import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ModelSummary from "./tab/simulation/ModelSummary";
import { Card } from "@mui/material";

const PresetModal = (props) => {
  const { isOpen, data, toggle } = props;
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: "5em", maxWidth: "35%", height: "10px" }}
    >
      <ModalHeader toggle={toggle}>
        <p className="text-topic-2">ภาพรวมแบบจำลอง (Model Summary)</p>
      </ModalHeader>
      <ModalBody className={"p-0"}>
        <Card
          sx={{
            minWidth: 275,
            height: "81.5vh",
            overflow: "scroll",
            border: "0px",
          }}
          variant="outlined"
        >
          <ModelSummary data={data} isModal={true} />
        </Card>
      </ModalBody>
      {/*<ModalFooter>*/}
      {/*  <Button color="primary">Do Something</Button>{" "}*/}
      {/*  <Button color="secondary" onClick={toggle}>*/}
      {/*    Cancel*/}
      {/*  </Button>*/}
      {/*</ModalFooter>*/}
    </Modal>
  );
};

export default PresetModal;
