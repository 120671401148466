import { fetchWrapper } from "../helpers/fetch-wrapper";
import service from "./index";

const baseUrl = `/api/v1`;

export const userService = {
  user: checkAuthen,
  login,
  logout,
  getUserAccount,
};

export function checkAuthen() {
  const isAuthen = sessionStorage.getItem("user-abm");
  if (isAuthen) {
    return true;
  } else {
    sessionStorage.clear();
    return false;
  }
}

function login(user) {
  return fetchWrapper
    .post(`${baseUrl}/signin`, {
      id: user?.username,
      password: user?.password,
    })
    .then((res) => {
      const { success } = res;
      if (success) {
        const { token } = res?.data;
        sessionStorage.setItem("user-abm", JSON.stringify(token));
        sessionStorage.setItem("username", user?.username);
        return true;
      } else {
        service.handleErrors(res);
      }
    })
    .catch((err) => service.handleErrors);
}

function register(user) {
  return fetchWrapper
    .post(`${baseUrl}/register`, user)
    .catch((err) => service.handleErrors);
}

function update(user) {
  return fetchWrapper
    .put(`${baseUrl}/update`, user)
    .catch((err) => service.handleErrors);
}

function logout() {
  sessionStorage.clear();
  return true;
}

function getUserAccount() {
  const username = sessionStorage.getItem("username");
  return username ? username : "-";
}

// function handleErrors(res) {
//   const { success, error } = res || {};
//   if (!success) {
//     const { message, internalErrorMessage } = error || {};
//     Swal.fire(message, internalErrorMessage, "error");
//   }
//   return res;
// }
