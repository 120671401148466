import { Card, CardContent } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import lang from "../../../presetEntity";
import _ from "lodash";
import options from "../../../presetEntity/options";

const fontSize = 14;
const dataDefault = {
  agent: {
    agentCount: 5000,
    networkCount: 500,
    meanRiskTolerance: 1,
    covRiskTolerance: 1,
    laborCountPerLand: 8,
    discountRate: 1,
    bornRate: 1,
    deadRate: 1,
    ratioOfPopulationToAge: 1,
    ratioOfPopulationToSex: 1,
  },
  belief: {
    priorExpectMeanScalingMachineUsage: 1,
    priorExpectMeanScalingWeatherYield: 2,
    priorStrengthMeanCropPrice: 3,
    priorStrengthMeanScalingMachineUsage: 4,
    priorStrengthMeanWeatherYield: 5,
    priorExpectVarianceScalingMachineUsage: 6,
    priorExpectVarianceScalingWeatherYield: 7,
    priorStrengthVarianceCropPrice: 8,
    priorStrengthVarianceScalingMachineUsage: 9,
    priorStrengthVarianceScalingWeatherYield: 10,
  },
  landMarket: {
    landPrice: 1,
    cropcosts: 2,
    fertilizerCosts: 3,
    loanInterestRateRoutine: 4,
    loanInterestRateInvestor: 5,
    machineCosts: 6,
    roiInvestor: 7,
    roiInFarm: 8,
    roiOffFarm: 9,
    creditLimitInvertor: 10,
    creditLimitRoutine: 11,
    creditLimitRatioProfit: 12,
    creditLimitRatioLandPrice: 13,
    downPaymentRatioInvestor: 14,
  },
  disaster: {
    diasaterPeriod: 1,
    rainfallThreshold: 1,
    temperatureTheshold: 1,
  },
  emergencySubsidyPolicy: {
    waitingFieldName: "?",
    highFloodDamageCompensationRate: 1,
    meduimFloodDamageCompensationRate: 1,
    lowFloodDamageCompensationRate: 1,
    highDroughtDamageCompensationRate: 1,
    mediumDroughtDamageCompensationRate: 1,
    lowDroughtDamageCompensationRate: 1,
    assistedMaximumAreaCount: 1,
    efficientcyRate: 1,
  },
  supportAgicultureInsurancePolicy: {
    premiumPriceRiceHighRiskAreaTier1: 3,
    premiumPriceRiceMediumRiskAreaTier1: 2,
    premiumPriceRiceLowRiskAreaTier1: 1,
    premiumPriceRiceHighRiskAreaTier2: 3,
    premiumPriceRiceMediumRiskAreaTier2: 2,
    premiumPriceRiceLowRiskAreaTier2: 1,
    premiumPriceMaizeHighRiskAreaTier1: 3,
    premiumPriceMaizeMediumRiskAreaTier1: 2,
    premiumPriceMaizeLowRiskAreaTier1: 1,
    premiumPriceMaizeHighRiskAreaTier2: 3,
    premiumPriceMaizeMediumRiskAreaTier2: 2,
    premiumPriceMaizeLowRiskAreaTier2: 1,
    riceCompensationRate: 1,
    maizeCompensationRate: 1,
    efficientcyRate: 1,
  },
  incomeInsurancePolicy: {
    guaranteePrice: 1,
    assistedMaximumAreaCount: 1,
  },
  climateSmartAgriculturePolicy: {
    awdSubsidyCost: 1,
    dripSubsidyCost: 2,
  },
};
export default function ModelSummary(props) {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(props?.data)) {
      setData((prevState) => ({ ...prevState, ...props?.data }));
    }
  }, [props?.data]);

  const {
    agent,
    landMarket,
    disaster,
    emergencySubsidyPolicy,
    supportAgicultureInsurancePolicy,
    incomeInsurancePolicy,
    climateSmartAgriculturePolicy,
  } = data || {};

  const getValueOfArray = (aObj) => {
    if (!_.isEmpty(aObj)) {
      const found = aObj.find(({ value }) => value > 0);
      return <p className="font-w-bold">{found?.value ? found?.value : "0"}</p>;
    } else {
      return "-";
    }
  };

  const getValue = (value) => {
    return <p className="font-w-bold">{value ? value : "-"}</p>;
  };

  const getValueMeanAndSD = (value) => {
    switch (value?.mode) {
      case 5:
        return value ? (
          <p className="font-w-bold">
            {`M: ${value?.mean ? value?.mean : 0} SD: ${
              value?.sd ? value?.sd : 0
            }`}
          </p>
        ) : (
          "-"
        );
        break;

      default:
        return (
          <p className="font-w-bold" style={{ fontSize: "14px" }}>
            {getValueMode(value?.mode)}
          </p>
        );
        break;
    }
  };

  const getValueMode = (mode) => {
    const found = options.mmsOptions.find(({ value }) => mode === value);
    return found?.label ? found?.label : mode;
  };

  const renderListValueByOptions = (data, options, title) => {
    if (!_.isEmpty(data)) {
      return data.map((val) => {
        const label = options.find(({ value }) => value === val.key);
        return (
          <Row>
            <Col md={9} className="font-w-bold">
              {title} : {label?.label}
            </Col>
            <Col className="text-right">
              <p className="font-w-bold">{val?.value ? val?.value : "0"}</p>
            </Col>
          </Row>
        );
      });
    }
  };

  return (
    <>
      <CardContent>
        {!props?.isModal ? (
          <p className="text-topic-2 Mui" style={{ fontSize: 18 }}>
            ภาพรวมแบบจำลอง (Model Summary)
          </p>
        ) : (
          ""
        )}
        <Row className={""}>
          <p className={"font-w-bold my-1"}>ตัวแทน</p>
        </Row>
        <div style={{ fontSize: fontSize }}>
          <Row>
            <Col md={9} className="font-w-bold">
              {lang.agentCount}
            </Col>
            <Col className="text-right">{getValue(agent?.agentCount)}</Col>
          </Row>
          {/* <Row>
            <Col md={9} className="font-w-bold">
              {lang.riskAversion}
            </Col>
            <Col className="text-right">{getValue(agent?.riskAversion)}</Col>
          </Row> */}
          {/*<Row>*/}
          {/*  <Col md={9} className="font-w-bold">*/}
          {/*    {lang.discountRate}*/}
          {/*  </Col>*/}
          {/*  <Col className="text-right">{getValue(agent?.discountRate)}</Col>*/}
          {/*</Row>*/}
          <Row>
            <Col md={8} className="font-w-bold">
              {lang.ratioOfPopulationToAge}
            </Col>
            <Col md={4} className="text-right">
              {getValueMeanAndSD(agent?.ratioOfPopulationToAge)}
            </Col>
          </Row>
          <Row>
            <Col md={8} className="font-w-bold">
              {lang.education}
            </Col>
            <Col className="text-right">
              {getValueMeanAndSD(agent?.education)}
            </Col>
          </Row>
          <Row>
            <Col md={8} className="font-w-bold">
              {lang.ratioOfPopulationToSex}
            </Col>
            <Col className="text-right">
              {getValueMeanAndSD(agent?.ratioOfPopulationToSex)}
            </Col>
          </Row>
          <Row>
            <Col md={8} className="font-w-bold">
              {lang.ratioOfPopulationToDepen}
            </Col>
            <Col className="text-right">
              {getValueMeanAndSD(agent?.ratioOfPopulationToDepen)}
            </Col>
          </Row>
          <Row>
            <Col md={8} className="font-w-bold">
              {lang.householdSize}
            </Col>
            <Col className="text-right">
              {getValueMeanAndSD(agent?.householdSize)}
            </Col>
          </Row>
          <Row>
            <Col md={8} className="font-w-bold">
              {lang.debt}
            </Col>
            <Col className="text-right">{getValueMeanAndSD(agent?.debt)}</Col>
          </Row>
          <Row>
            <Col md={8} className="font-w-bold">
              {lang.wealth}
            </Col>
            <Col className="text-right">{getValueMeanAndSD(agent?.wealth)}</Col>
          </Row>
          <Row>
            <Col md={8} className="font-w-bold">
              {lang.landSize}
            </Col>
            <Col className="text-right">
              {getValueMeanAndSD(agent?.landSize)}
            </Col>
          </Row>
          <Row>
            <Col md={8} className="font-w-bold">
              {lang.machineCount}
            </Col>
            <Col className="text-right">
              {getValueMeanAndSD(agent?.machineCount)}
            </Col>
          </Row>
          <Row>
            <Col md={8} className="font-w-bold">
              {lang.agricultureCost}
            </Col>
            <Col className="text-right">
              {getValueMeanAndSD(agent?.agricultureCost)}
            </Col>
          </Row>
        </div>
        <Row className={"mt-2"}>
          <p className={"font-w-bold my-1"}>ที่ดินและตลาด</p>
          <div style={{ fontSize: fontSize }}>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.landPrice}
              </Col>
              <Col className="text-right">
                {getValue(landMarket?.landPrice)}
              </Col>
            </Row>
            {/* <Row>
              <Col md={9} className="font-w-bold">
                {lang.cropcosts}
              </Col>
              <Col className="text-right">
                {getValueOfArray(landMarket?.cropcosts)}
              </Col>
            </Row> */}
            <div>
              {renderListValueByOptions(
                landMarket?.cropcosts,
                options.riceOptions,
                lang.cropcosts
              )}
            </div>
            {/* <Row>
              <Col md={9} className="font-w-bold">
                {lang.fertilizerCosts}
              </Col>
              <Col className="text-right">
                {getValueOfArray(landMarket?.fertilizerCosts)}
              </Col>
            </Row> */}
            <div>
              {renderListValueByOptions(
                landMarket?.fertilizerCosts,
                options.riceOptions,
                lang.fertilizerCosts
              )}
            </div>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.loanInterestRateRoutine}
              </Col>
              <Col className="text-right">
                {getValue(landMarket?.loanInterestRateRoutine)}
              </Col>
            </Row>
            {/* <Row>
              <Col md={9} className="font-w-bold">
                {lang.loanInterestRateInvestor}
              </Col>
              <Col className="text-right">
                {getValueOfArray(landMarket?.loanInterestRateInvestor)}
              </Col>
            </Row> */}
            {/*<div>*/}
            {/*  {renderListValueByOptions(*/}
            {/*    landMarket?.loanInterestRateInvestor,*/}
            {/*    options.machineOptions,*/}
            {/*    lang.loanInterestRateInvestor*/}
            {/*  )}*/}
            {/*</div>*/}
            {/* <Row>
              <Col md={9} className="font-w-bold">
                {lang.machineCosts}
              </Col>
              <Col className="text-right">
                {getValueOfArray(landMarket?.machineCosts)}
              </Col>
            </Row> */}
            <div>
              {renderListValueByOptions(
                landMarket?.machineCosts,
                options.machineOptions,
                lang.machineCosts
              )}
            </div>
            {/* <Row>
              <Col md={9} className="font-w-bold">
                {lang.roiInvestor}
              </Col>
              <Col className="text-right">
                {getValueOfArray(landMarket?.roiInvestor)}
              </Col>
            </Row> */}
            <div>
              {renderListValueByOptions(
                landMarket?.roiInvestor,
                options.machineOptions,
                lang.roiInvestor
              )}
            </div>
            {/*<Row>*/}
            {/*  <Col md={9} className="font-w-bold">*/}
            {/*    {lang.roiInFarm}*/}
            {/*  </Col>*/}
            {/*  <Col className="text-right">*/}
            {/*    {getValue(landMarket?.roiInFarm)}*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.roiOffFarm}
              </Col>
              <Col className="text-right">
                {getValue(landMarket?.roiOffFarm)}
              </Col>
            </Row>
            {/* <Row>
              <Col md={9} className="font-w-bold">
                {lang.creditLimitInvertor}
              </Col>
              <Col className="text-right">
                {getValue(landMarket?.creditLimitInvertor)}
              </Col>
            </Row> */}
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.creditLimitRoutine}
              </Col>
              <Col className="text-right">
                {getValue(landMarket?.creditLimitRoutine)}
              </Col>
            </Row>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.creditLimitRatioProfit}
              </Col>
              <Col className="text-right">
                {getValue(landMarket?.creditLimitRatioProfit)}
              </Col>
            </Row>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.creditLimitRatioLandPrice}
              </Col>
              <Col className="text-right">
                {getValue(landMarket?.creditLimitRatioLandPrice)}
              </Col>
            </Row>
            {/* <Row>
              <Col md={9} className="font-w-bold">
                {lang.downPaymentRatioInvestor}
              </Col>
              <Col className="text-right">
                {getValue(landMarket?.downPaymentRatioInvestor)}
              </Col>
            </Row> */}
          </div>
        </Row>

        <Row className={"mt-2"}>
          <p className={"font-w-bold my-1"}>ภัยพิบัติ</p>
          <div style={{ fontSize: fontSize }}>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.rainfallThreshold}
              </Col>
              <Col className="text-right">
                <p className="font-w-bold">
                  {disaster?.rainfallThreshold[0]} -{" "}
                  {disaster?.rainfallThreshold[1]}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.temperatureTheshold}
              </Col>
              <Col className="text-right">
                <p className="font-w-bold">
                  {disaster?.temperatureTheshold[0]} -{" "}
                  {disaster?.temperatureTheshold[1]}
                </p>
              </Col>
            </Row>
          </div>
        </Row>

        <Row className={"mt-2"}>
          <p className={"font-w-bold my-1"}>
            มาตรการการจ่ายเงินช่วยเหลือเกษตรกรผู้ประสบภัยพิบัติกรณีฉุกเฉิน
          </p>
          <div style={{ fontSize: fontSize }}>
            {/* <Row>
              <Col md={9} className="font-w-bold">
                {lang.FloodDamageCompensationRate}
              </Col>
              <Col className="text-right">
                {getValueOfArray(
                  emergencySubsidyPolicy?.FloodDamageCompensationRate
                )}
              </Col>
            </Row> */}
            <div>
              {renderListValueByOptions(
                emergencySubsidyPolicy?.FloodDamageCompensationRate,
                options.riceOptions,
                lang.FloodDamageCompensationRate
              )}
            </div>
            {/* <Row>
              <Col md={9} className="font-w-bold">
                {lang.DroughtDamageCompensationRate}
              </Col>
              <Col className="text-right">
                {getValueOfArray(
                  emergencySubsidyPolicy?.DroughtDamageCompensationRate
                )}
              </Col>
            </Row> */}
            <div>
              {renderListValueByOptions(
                emergencySubsidyPolicy?.DroughtDamageCompensationRate,
                options.riceOptions,
                lang.DroughtDamageCompensationRate
              )}
            </div>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.assistedMaximumAreaCount}
              </Col>
              <Col className="text-right">
                {getValue(emergencySubsidyPolicy?.assistedMaximumAreaCount)}
              </Col>
            </Row>
            {/* <Row>
              <Col md={9} className="font-w-bold">
                {lang.efficientcyRate}
              </Col>
              <Col className="text-right">
                {getValue(emergencySubsidyPolicy?.efficientcyRate)}
              </Col>
            </Row> */}
          </div>
        </Row>

        <Row className={"mt-2"}>
          <p className={"font-w-bold my-1"}>
            มาตรการสนับสนุนการทำประกันภัยการเกษตร
          </p>
          <div style={{ fontSize: fontSize }}>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.premiumPriceHighRiskAreaTier1}
              </Col>
              <Col className="text-right">
                {getValue(
                  supportAgicultureInsurancePolicy?.premiumPriceHighRiskAreaTier1
                )}
              </Col>
            </Row>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.premiumPriceMediumRiskAreaTier1}
              </Col>
              <Col className="text-right">
                {getValue(
                  supportAgicultureInsurancePolicy?.premiumPriceMediumRiskAreaTier1
                )}
              </Col>
            </Row>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.premiumPriceLowRiskAreaTier1}
              </Col>
              <Col className="text-right">
                {getValue(
                  supportAgicultureInsurancePolicy?.premiumPriceLowRiskAreaTier1
                )}
              </Col>
            </Row>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.premiumPriceHighRiskAreaTier2}
              </Col>
              <Col className="text-right">
                {getValue(
                  supportAgicultureInsurancePolicy?.premiumPriceHighRiskAreaTier2
                )}
              </Col>
            </Row>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.premiumPriceMediumRiskAreaTier2}
              </Col>
              <Col className="text-right">
                {getValue(
                  supportAgicultureInsurancePolicy?.premiumPriceMediumRiskAreaTier2
                )}
              </Col>
            </Row>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.premiumPriceLowRiskAreaTier2}
              </Col>
              <Col className="text-right">
                {getValue(
                  supportAgicultureInsurancePolicy?.premiumPriceLowRiskAreaTier2
                )}
              </Col>
            </Row>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.CompensationHighRiskAreaTier1}
              </Col>
              <Col className="text-right">
                {getValue(
                  supportAgicultureInsurancePolicy?.CompensationHighRiskAreaTier1
                )}
              </Col>
            </Row>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.CompensationMediumRiskAreaTier1}
              </Col>
              <Col className="text-right">
                {getValue(
                  supportAgicultureInsurancePolicy?.CompensationMediumRiskAreaTier1
                )}
              </Col>
            </Row>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.CompensationLowRiskAreaTier1}
              </Col>
              <Col className="text-right">
                {getValue(
                  supportAgicultureInsurancePolicy?.CompensationLowRiskAreaTier1
                )}
              </Col>
            </Row>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.CompensationHighRiskAreaTier2}
              </Col>
              <Col className="text-right">
                {getValue(
                  supportAgicultureInsurancePolicy?.CompensationHighRiskAreaTier2
                )}
              </Col>
            </Row>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.CompensationMediumRiskAreaTier2}
              </Col>
              <Col className="text-right">
                {getValue(
                  supportAgicultureInsurancePolicy?.CompensationMediumRiskAreaTier2
                )}
              </Col>
            </Row>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.CompensationLowRiskAreaTier2}
              </Col>
              <Col className="text-right">
                {getValue(
                  supportAgicultureInsurancePolicy?.CompensationLowRiskAreaTier2
                )}
              </Col>
            </Row>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.SupportPremiumHighRiskAreaTier1}
              </Col>
              <Col className="text-right">
                {getValue(
                  supportAgicultureInsurancePolicy?.SupportPremiumHighRiskAreaTier1
                )}
              </Col>
            </Row>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.SupportPremiumMediumRiskAreaTier1}
              </Col>
              <Col className="text-right">
                {getValue(
                  supportAgicultureInsurancePolicy?.SupportPremiumMediumRiskAreaTier1
                )}
              </Col>
            </Row>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.SupportPremiumLowRiskAreaTier1}
              </Col>
              <Col className="text-right">
                {getValue(
                  supportAgicultureInsurancePolicy?.SupportPremiumLowRiskAreaTier1
                )}
              </Col>
            </Row>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.efficientcyRate}
              </Col>
              <Col className="text-right">
                {getValue(supportAgicultureInsurancePolicy?.efficientcyRate)}
              </Col>
            </Row>
          </div>
        </Row>

        <Row className={"mt-2"}>
          <p className={"font-w-bold my-1"}>
            มาตรการรักษาเสถียรภาพรายได้ของเกษตรกร
          </p>
          <div style={{ fontSize: fontSize }}>
            {/* <Row>
              <Col md={9} className="font-w-bold">
                {lang.guaranteePrice}
              </Col>
              <Col className="text-right">
                {getValueOfArray(incomeInsurancePolicy?.guaranteePrice)}
              </Col>
            </Row> */}
            <div>
              {renderListValueByOptions(
                incomeInsurancePolicy?.guaranteePrice,
                options.riceOptions,
                lang.guaranteePrice
              )}
            </div>
            {/* <Row>
              <Col md={9} className="font-w-bold">
                {lang.assistedMaximumAreaCount}
              </Col>
              <Col className="text-right">
                {getValueOfArray(
                  incomeInsurancePolicy?.assistedMaximumAreaCount
                )}
              </Col>
            </Row> */}
            <div>
              {renderListValueByOptions(
                incomeInsurancePolicy?.assistedMaximumAreaCount,
                options.riceOptions,
                lang.assistedMaximumAreaCount
              )}
            </div>
          </div>
        </Row>

        <Row className={"mt-2"}>
          <p className={"font-w-bold my-1"}>
            มาตรการสนับสนุนการปรับเปลี่ยนเป็นเกษตรกรรมที่เท่าทันต่อภูมิอากาศ
          </p>
          <div style={{ fontSize: fontSize }}>
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.awdSubsidyCost}
              </Col>
              <Col className="text-right">
                {getValue(climateSmartAgriculturePolicy?.awdSubsidyCost)}
              </Col>
            </Row>{" "}
            <Row>
              <Col md={9} className="font-w-bold">
                {lang.dripSubsidyCost}
              </Col>
              <Col className="text-right">
                {getValue(climateSmartAgriculturePolicy?.dripSubsidyCost)}
              </Col>
            </Row>
          </div>
        </Row>
      </CardContent>
    </>
  );
}
