import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-circular-progressbar/dist/styles.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./views/login/login";
import Home from "./views/home/home";
import "./scss/_custom.scss";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { userService } from "./services/user.service";
import "./scss/sweetalert.scss";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {userService.user() ? (
            <Route path="/" element={<Home />} />
          ) : (
            <Route path="/" element={<Login />} />
          )}

          <Route path="/login" element={<Login />} />
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
