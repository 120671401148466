import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { Card } from "@mui/material";
import { Bar, Line } from "react-chartjs-2";
import _ from "lodash";
import { useResultContext } from "..";
import ExportImage from "../ExportImage";

const Select6 = () => {
  const { labelsYearChartArr, dataResult1, dataResult2, yearLabel } =
    useResultContext();
  const [cropSize1, setCropSize1] = useState([0, 0, 0]);
  const [cropSize2, setCropSize2] = useState([0, 0, 0]);

  let refBar1 = useRef(null);
  let refBar2 = useRef(null);
  let refLine1 = useRef(null);
  let refLine2 = useRef(null);

  useEffect(() => {
    handleCropSize1();
  }, [dataResult1]);

  useEffect(() => {
    const cSize2 = handleCropSize(dataResult2);
    setCropSize2(cSize2);
  }, [dataResult2]);

  const handleCropSize1 = () => {
    let dataCropSize = [];
    let totalYear = 0;
    // รวมจำนวน amount ของแต่ละ size
    let size1 = 0;
    let size10 = 0;
    let size50 = 0;
    dataResult1.map((item, index) => {
      const { cropSizeGroup } = item;
      //size 1-10
      let amount1 = findCopsSize(cropSizeGroup, 1);
      size1 += amount1;
      //size 10-50
      let amount10 = findCopsSize(cropSizeGroup, 10);
      size10 += amount10;
      //size 50-1000
      let amount50 = findCopsSize(cropSizeGroup, 50);
      size50 += amount50;
      totalYear = index + 1;
    });
    const avgSize1 = size1 / totalYear;
    const avgSize10 = size10 / totalYear;
    const avgSize50 = size50 / totalYear;
    dataCropSize.push(avgSize1);
    dataCropSize.push(avgSize10);
    dataCropSize.push(avgSize50);
    setCropSize1(dataCropSize);
  };

  const handleCropSize = (data) => {
    let dataCropSize = [];
    let totalYear = 0;
    // รวมจำนวน amount ของแต่ละ size
    let size1 = 0;
    let size10 = 0;
    let size50 = 0;
    data.map((item, index) => {
      const { cropSizeGroup } = item;
      //size 1-10
      let amount1 = findCopsSize(cropSizeGroup, 1);
      size1 += amount1;
      //size 10-50
      let amount10 = findCopsSize(cropSizeGroup, 10);
      size10 += amount10;
      //size 50-1000
      let amount50 = findCopsSize(cropSizeGroup, 50);
      size50 += amount50;
      totalYear = index + 1;
    });
    const avgSize1 = size1 / totalYear;
    const avgSize10 = size10 / totalYear;
    const avgSize50 = size50 / totalYear;
    dataCropSize.push(avgSize1);
    dataCropSize.push(avgSize10);
    dataCropSize.push(avgSize50);
    return dataCropSize;
  };

  const getAvgLandtotal = (data) => {
    let sum = 0;
    let yearTotal = 0;
    data?.map(({ landTotal }, index) => {
      sum = sum + landTotal;
      yearTotal = index + 1;
    });
    const avg = sum && yearTotal ? sum / yearTotal : 0;
    return avg
      ? Number(avg.toFixed(2)).toLocaleString("en", {
          minimumFractionDigits: 2,
        })
      : "0";
  };

  const labels = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ];

  const labelsBar = ["1-10", "11-50", ">50"];

  const findCopsSize = (data, minVal) => {
    if (!_.isEmpty(data)) {
      let found = data.find(({ minSize }) => minSize === minVal);
      return found?.amount ? found?.amount : 0;
    } else {
      return 0;
    }
  };

  const optionsBar = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: "จำนวนไร่",
          font: {
            family: "'Prompt', sans-serif",
            size: 14,
          },
        },
      },
      x: {
        title: {
          align: "center",
          display: true,
          text: "ขนาด",
          font: {
            family: "'Prompt', sans-serif",
            size: 14,
          },
        },
      },
    },
    responsive: false,
    animation: false,
  };

  const optionsLine = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          font: {
            family: "'Prompt', sans-serif",
            size: 12,
          },
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
    scales: {
      y: {
        title: {
          align: "center",
          display: true,
          text: "จำนวนไร่",
          font: {
            family: "'Prompt', sans-serif",
            size: 14,
          },
        },
      },
      x: {
        title: {
          align: "center",
          display: true,
          text: "คาบ",
          font: {
            family: "'Prompt', sans-serif",
            size: 14,
          },
        },
      },
    },
    responsive: false,
    animation: false,
  };

  const data1 = {
    labels: labelsBar,
    datasets: [
      {
        label: "จำนวน:",
        data: cropSize1,
        backgroundColor: "#4178BC",
        borderRadius: 3,
      },
    ],
  };

  const data2 = {
    labels: labelsBar,
    datasets: [
      {
        label: "จำนวน:",
        data: cropSize2,
        backgroundColor: "#e84c4c",
        borderRadius: 3,
      },
      // {
      //   label: "นโยบาย 2",
      //   data: [7, 2, 2],
      //   backgroundColor: "#E8384F",
      //   borderRadius: 3,
      // },
    ],
  };

  const getArrayOfString = (arr, minSizeVal) => {
    let res = [];
    arr.map(({ cropSizeGroup }) => {
      const found = cropSizeGroup.find(({ minSize }) => minSize === minSizeVal);
      res.push(found?.amount);
    });
    return res;
  };

  const lineData1 = {
    labels: labelsYearChartArr(),
    datasets: [
      {
        label: "1-10",
        data: getArrayOfString(dataResult1, 1),
        borderColor: "#33d49a",
        backgroundColor: "#33d49a",
        pointRadius: 2,
        tension: 0.4,
      },
      {
        label: "11-50",
        data: getArrayOfString(dataResult1, 10),
        borderColor: "#4286e0",
        backgroundColor: "#4286e0",
        pointRadius: 2,
        tension: 0.4,
      },
      {
        label: ">50",
        data: getArrayOfString(dataResult1, 50),
        borderColor: "#8d42e0",
        backgroundColor: "#8d42e0",
        pointRadius: 2,
        tension: 0.4,
      },
    ],
  };

  const lineData2 = {
    labels: labelsYearChartArr(),
    datasets: [
      {
        label: "1-10",
        data: getArrayOfString(dataResult2, 1),
        borderColor: "#e8384f",
        backgroundColor: "#e8384f",
        pointRadius: 2,
        tension: 0.4,
      },
      {
        label: "11-50",
        data: getArrayOfString(dataResult2, 10),
        borderColor: "#ff9458",
        backgroundColor: "#ff9458",
        pointRadius: 2,
        tension: 0.4,
      },
      {
        label: ">50",
        data: getArrayOfString(dataResult2, 50),
        borderColor: "#f360da",
        backgroundColor: "#f360da",
        pointRadius: 2,
        tension: 0.4,
      },
    ],
  };
  return (
    <div className="mt-3">
      <Row>
        <Col>
          <Card
            style={{
              backgroundColor: "#4178BC",
              height: 80,
              padding: 25,
            }}
          >
            <div className="m-auto">
              <p className="text-white" style={{ fontSize: 20 }}>
                พื้นที่เพาะปลูกเฉลี่ย {getAvgLandtotal(dataResult1)} ไร่ (
                {yearLabel()} คาบ)
              </p>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            style={{
              backgroundColor: "#E84C4C",
              height: 80,
              padding: 25,
            }}
          >
            <div className="m-auto">
              <p className="text-white" style={{ fontSize: 20 }}>
                พื้นที่เพาะปลูกเฉลี่ย {getAvgLandtotal(dataResult2)} ไร่ (
                {yearLabel()} คาบ)
              </p>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="border-card mt-3 text-center chart-panel">
            <div className="m-auto" style={{ width: 500 }}>
              <ExportImage
                ref={refBar1}
                fileName={"CropSizeGroup_Bar1"}
                chartSettings={{
                  type: "bar",
                  data: data1,
                  options: optionsBar,
                }}
              />
              <Bar
                ref={refBar1}
                data={data1}
                options={{ ...optionsBar, responsive: true, animation: true }}
              />
            </div>
          </div>
        </Col>
        <Col>
          <div className="border-card mt-3 text-center chart-panel">
            <div className="m-auto" style={{ width: 500 }}>
              <ExportImage
                ref={refBar2}
                fileName={"CropSizeGroup_Bar2"}
                chartSettings={{
                  type: "bar",
                  data: data2,
                  options: optionsBar,
                }}
              />
              <Bar
                ref={refBar2}
                data={data2}
                options={{ ...optionsBar, responsive: true, animation: true }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="border-card mt-3 text-center chart-panel">
            <div className="m-auto mt-3" style={{ width: 500 }}>
              <ExportImage
                ref={refLine1}
                fileName={"CropSizeGroup_Line1"}
                chartSettings={{
                  type: "line",
                  data: lineData1,
                  options: optionsLine,
                }}
              />
              <Line
                ref={refLine1}
                data={lineData1}
                options={{ ...optionsLine, responsive: true, animation: true }}
              />
            </div>
          </div>
        </Col>
        <Col>
          <div className="border-card mt-3 text-center chart-panel">
            <div className="m-auto mt-3" style={{ width: 500 }}>
              <ExportImage
                ref={refLine2}
                fileName={"CropSizeGroup_Line2"}
                chartSettings={{
                  type: "line",
                  data: lineData2,
                  options: optionsLine,
                }}
              />
              <Line
                ref={refLine2}
                data={lineData2}
                options={{ ...optionsLine, responsive: true, animation: true }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Select6;
