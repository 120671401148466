import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { Card } from "@mui/material";
import { Bar, Line } from "react-chartjs-2";
import _ from "lodash";
import { useResultContext } from "..";
import ExportImage from "../ExportImage";

const color1 = ["#4178BC", "#98C5FF", "#AEC3DE"];

const Select1 = () => {
  const { yearLabel, labelsYearChartArr, dataResult1, dataResult2, fromTo } =
    useResultContext();

  const [line1, setLine1] = useState([]);
  const [line2, setLine2] = useState([]);

  let refBar1 = useRef(null);
  let refBar2 = useRef(null);
  let refLine1 = useRef(null);
  let refLine2 = useRef(null);

  useEffect(() => {
    setDataLine1();
  }, [dataResult1]);

  useEffect(() => {
    setDataLine2();
  }, [dataResult2]);

  const setDataLine1 = () => {
    if (!_.isEmpty(dataResult1)) {
      let arrHouseHoldAvg = [];
      dataResult1.map(({ householdAvg }) =>
        arrHouseHoldAvg.push(householdAvg.toFixed(2))
      );
      setLine1(arrHouseHoldAvg);
    }
  };

  const setDataLine2 = () => {
    if (!_.isEmpty(dataResult2)) {
      let arrHouseHoldAvg = [];
      dataResult2.map(({ householdAvg }) =>
        arrHouseHoldAvg.push(householdAvg.toFixed(2))
      );
      setLine2(arrHouseHoldAvg);
    }
  };

  const getArrayOfString = (arr, fieldName) => {
    let res = [];
    arr.map((item) => res.push(item[fieldName]));
    return res;
  };

  const getNameOfEntities = (entities) => {
    return entities?.name;
  };

  const getDataSet1 = (arr, colorBar) => {
    let totalAmout = [];
    let totalYear = 0;
    // รวมจำนวน amount ของแต่ละ size
    arr.map((item, index) => {
      item.householdSizeCounts.map(({ size, amount }, index) => {
        if (totalAmout[size - 1] === undefined) {
          totalAmout[size - 1] = amount;
        } else {
          totalAmout[size - 1] = totalAmout[size - 1] + amount;
        }
      });
      totalYear = index + 1;
    });

    //หา Average ของแต่ละ size
    let avg = totalAmout.map((val) => Number(val / totalYear));
    let obj = [
      {
        label: "amount:",
        data: avg,
        backgroundColor: colorBar,
      },
    ];
    return obj;
  };

  const getLabelBar1 = (arr) => {
    if (!_.isEmpty(arr)) {
      let label = [];
      arr.householdSizeCounts.map((item, index) => {
        label.push(index + 1);
      });
      return label;
    }
  };

  const optionsBar = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          align: "center",
          display: true,
          text: "จำนวนสมาชิก",
          font: {
            family: "'Prompt', sans-serif",
            size: 14,
          },
        },
      },
      y: {
        title: {
          align: "center",
          display: true,
          text: "จำนวนครัวเรือน",
          font: {
            family: "'Prompt', sans-serif",
            size: 14,
          },
        },
      },
    },
    responsive: false,
    animation: false,
  };

  const optionsLine = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          align: "center",
          display: true,
          text: "คาบ",
          font: {
            family: "'Prompt', sans-serif",
            size: 14,
          },
        },
      },
      y: {
        title: {
          align: "center",
          display: true,
          text: "ค่าเฉลี่ยจำนวนสมาชิก",
          font: {
            family: "'Prompt', sans-serif",
            size: 14,
          },
        },
      },
    },
    responsive: false,
    animation: false,
  };

  const data1 = {
    labels: getLabelBar1(dataResult1[0]),
    datasets: getDataSet1(dataResult1, "#4178BC"),
  };

  const data2 = {
    labels: getLabelBar1(dataResult2[0]),
    datasets: getDataSet1(dataResult2, "#FFA6A6"),
    // datasets: [
    //   {
    //     label: getNameOfEntities(entities2),
    //     data: getArrayOfString(result2?.householdSizeCounts, "amount"),
    //     backgroundColor: "#FFA6A6",
    //     borderRadius: 3,
    //   },
    // ],
  };

  const lineData1 = {
    labels: labelsYearChartArr(),
    datasets: [
      {
        label: "householdAvg",
        data: line1,
        fill: true,
        borderColor: "#4186E099",
        backgroundColor: "#4186E099",
        pointRadius: 2,
        tension: 0.4,
      },
    ],
  };

  const lineData2 = {
    labels: labelsYearChartArr(),
    datasets: [
      {
        label: "householdAvg",
        data: line2,
        fill: true,
        borderColor: "#E8384F99",
        backgroundColor: "#E8384F99",
        pointRadius: 2,
        tension: 0.4,
      },
    ],
  };

  const renderLine = (data, refId) => {
    return (
      <Line
        ref={refId}
        data={data}
        options={{ ...optionsLine, responsive: true }}
      />
    );
  };

  const getAverage = (data) => {
    let sum = 0;
    let yearTotal = 0;
    data?.map(({ householdAvg }, index) => {
      sum = sum + householdAvg;
      yearTotal = index + 1;
    });
    const avg = sum && yearTotal ? sum / yearTotal : 0;

    return avg
      ? Number(avg.toFixed(2)).toLocaleString("en", {
          minimumFractionDigits: 2,
        })
      : "-";
  };

  const getAverageSTD = (data) => {
    let sum = 0;
    let yearTotal = 0;
    data?.map(({ incomeAvg }, index) => {
      sum = sum + incomeAvg;
      yearTotal = index + 1;
    });
    const avg = sum && yearTotal ? sum / yearTotal : 0;

    return avg
      ? Number(avg.toFixed(2)).toLocaleString("en", {
          minimumFractionDigits: 2,
        })
      : "0";
  };

  return (
    <div className="mt-3">
      {/* <Row>
        <Col>
          <Row>
            <Col>
              <div className="text-left">
                <p className="font-w-bold">{selectSim1?.label}</p>
              </div>
            </Col>
            <Col></Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <div className="text-left">
                <p className="font-w-bold">{selectSim2?.label}</p>
              </div>
            </Col>
            <Col></Col>
          </Row>
        </Col>
      </Row> */}
      <Row>
        <Col>
          <Card
            style={{
              backgroundColor: "#4178BC",
              height: 80,
              padding: 25,
            }}
          >
            <div className="m-auto">
              <p className="text-white font-w-bold" style={{ fontSize: 20 }}>
                เฉลี่ย {yearLabel()} คาบ {getAverage(dataResult1)}{" "}
                คนต่อครัวเรือน
              </p>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            style={{
              backgroundColor: "#E84C4C",
              height: 80,
              padding: 25,
            }}
          >
            <div className="m-auto">
              <p className="text-white font-w-bold" style={{ fontSize: 20 }}>
                เฉลี่ย {yearLabel()} คาบ {getAverage(dataResult2)}{" "}
                คนต่อครัวเรือน
              </p>
            </div>
          </Card>
        </Col>
      </Row>
      {/* <Row className="mt-3">
        <Col>
          <Card
            style={{
              backgroundColor: "#0C4C9F",
              height: 80,
              padding: 25,
            }}
          >
            <div className="m-auto">
              <p className="text-white font-w-bold" style={{ fontSize: 20 }}>
                ค่าเบี่ยงเบนมาตรฐาน {getAverageSTD(dataResult1)}
              </p>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            style={{
              backgroundColor: "#C04328",
              height: 80,
              padding: 25,
            }}
          >
            <div className="m-auto">
              <p className="text-white font-w-bold" style={{ fontSize: 20 }}>
                ค่าเบี่ยงเบนมาตรฐาน {getAverageSTD(dataResult2)}
              </p>
            </div>
          </Card>
        </Col>
      </Row> */}
      <Row>
        <Col>
          <div className="border-card mt-3 text-center chart-panel">
            <div className="m-auto mt-3" style={{ width: 500 }}>
              <ExportImage
                ref={refBar1}
                fileName={"HouseHold_Bar1"}
                chartSettings={{
                  type: "bar",
                  data: data1,
                  options: optionsBar,
                }}
              />
              <Bar
                ref={refBar1}
                data={data1}
                options={{ ...optionsBar, responsive: true }}
              />
            </div>
          </div>
        </Col>
        <Col>
          <div className="border-card mt-3 text-center chart-panel">
            <div className="m-auto mt-3" style={{ width: 500 }}>
              <ExportImage
                ref={refBar2}
                fileName={"HouseHold_Bar2"}
                chartSettings={{
                  type: "bar",
                  data: data2,
                  options: optionsBar,
                }}
              />
              <Bar
                ref={refBar2}
                data={data2}
                options={{ ...optionsBar, responsive: true }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="border-card mt-3 text-center chart-panel">
            <div className="m-auto  mt-3" style={{ width: 500 }}>
              <ExportImage
                ref={refLine1}
                fileName={"HouseHold_Line1"}
                chartSettings={{
                  type: "line",
                  data: lineData1,
                  options: optionsLine,
                }}
              />
              {renderLine(lineData1, refLine1)}
            </div>
          </div>
        </Col>
        <Col>
          <div className="border-card mt-3 text-center chart-panel">
            <div className="m-auto  mt-3" style={{ width: 500 }}>
              <ExportImage
                ref={refLine2}
                fileName={"HouseHold_Line2"}
                chartSettings={{
                  type: "line",
                  data: lineData2,
                  options: optionsLine,
                }}
              />
              {renderLine(lineData2, refLine2)}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Select1;
