import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import { Button, Card, Container, Form } from "react-bootstrap";
import "../../scss/login.scss";

// import api from "../../../../api/queueApi";
import Swal from "sweetalert2";
import Image from "react-bootstrap/Image";
import Logo from "../../assets/LogoOAE.png";
import { userService } from "../../services/user.service";

const Login = () => {
  const navigate = useNavigate();
  // const history = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  // useEffect(() => {
  //   if (localStorage.getItem("access-token") !== null) {
  //     navigate("/");
  //   }
  // }, []);

  const onLogin = async (e) => {
    e.preventDefault();
    const req = { username: username, password: password };
    await userService.login(req).then((status) => {
      if (status) {
        navigate("/home");
      }
    });
    // try {
    //     const response = await apiLogin.login({
    //         email: username,
    //         password: password,
    //     });
    //     const data = response.data;
    //     if (data.message === "Correct") {
    //         http.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
    //         localStorage.setItem("access-token", response.data.token);
    //         localStorage.setItem("user-info", JSON.stringify(response.data.info));
    //         localStorage.setItem(
    //             "user-permissions",
    //             JSON.stringify(response.data.permissions)
    //         );
    //         navigate("/");
    //         window.location.reload();
    //     } else {
    //         alertSweet(
    //
    //             "ไม่สำเร็จ",
    //             "อีเมลหรือรหัสผ่านไม่ถูกต้อง กรุณากรอกใหม่อีกครั้ง",
    //             "error"
    //         );
    //     }
    // } catch (e) {
    //     alertSweet("ไม่สำเร็จ", { e }, "error");
    // }
  };

  const alertSweet = (title, text, icon) => {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
    });
  };

  return (
    <div className="login p-5">
      <Container>
        <div className="text-center" style={{ marginTop: "3%" }}>
          <Card
            className="card-login m-auto"
            style={{ paddingTop: 50, width: "80%" }}
          >
            <Card.Body>
              <Form
                onSubmit={onLogin}
                className="m-auto w-30-per"
              >
                <Image
                  src={Logo}
                  //style={{ width: "-webkit-fill-available, -moz-available" }}
                  className="img-logo"
                />
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="mb-3 mt-5 text-topic text-center">
                    Login
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Username"
                    onChange={(e) => setUsername(e.target.value)}
                    style={{ minWidth: 120 }}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3 text-left"
                  controlId="formBasicPassword"
                >
                  <Form.Control
                    required
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ minWidth: 120 }}
                  />
                </Form.Group>
                <Button
                  className="w-100 mb-5"
                  style={{ backgroundColor: "#0C4C9F" }}
                  type="submit"
                  style={{ minWidth: 120 }}
                >
                  Sign in
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default Login;
