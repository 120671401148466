import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  Grid,
  Icon,
  Slider,
} from "@mui/material";
import { Col, Image, Row } from "react-bootstrap";
import { Collapse } from "reactstrap";
import "../../scss/_homePage.scss";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import options from "../presetEntity/options";
import Swal from "sweetalert2";
import _ from "lodash";

import { fetchWrapper } from "../../helpers/fetch-wrapper";
import { InputLabel } from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";
import login from "../login/login";
import lang from "../presetEntity";
import { useCustomModelContext } from "./index";
import { presetService } from "../../services/preset.service";
import InfoModal from "./components/infoModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const isEditOptionSelect = {
  priorExpectMeanScalingMachineUsage: "m1",
  priorExpectMeanScalingWeatherYield: "ri1",
  priorStrengthMeanCropPrice: "ri1",
  priorStrengthMeanScalingMachineUsage: "m1",
  priorStrengthMeanWeatherYield: "ri1",
  priorExpectVarianceScalingMachineUsage: "m1",
  priorExpectVarianceScalingWeatherYield: "ri1",
  priorStrengthVarianceCropPrice: "ri1",
  priorStrengthVarianceScalingMachineUsage: "m1",
  priorStrengthVarianceScalingWeatherYield: "ri1",
  cropcosts: "ri1",
  fertilizerCosts: "ri1",
  loanInterestRateInvestor: "m1",
  machineCosts: "m1",
  roiInvestor: "m1",
  //emergencySubsidyPolicy
  FloodDamageCompensationRate: "ri1",
  DroughtDamageCompensationRate: "ri1",
  //supportAgicultureInsurancePolicy
  CompensationHighRiskAreaTier1: "ri1",
  CompensationMediumRiskAreaTier1: "ri1",
  CompensationLowRiskAreaTier1: "ri1",
  CompensationHighRiskAreaTier2: "ri1",
  CompensationMediumRiskAreaTier2: "ri1",
  CompensationLowRiskAreaTier2: "ri1",
  premiumPriceHighRiskAreaTier1: "ri1",
  premiumPriceMediumRiskAreaTier1: "ri1",
  premiumPriceLowRiskAreaTier1: "ri1",
  premiumPriceHighRiskAreaTier2: "ri1",
  premiumPriceMediumRiskAreaTier2: "ri1",
  premiumPriceLowRiskAreaTier2: "ri1",
  //incomeInsurancePolicy
  guaranteePrice: "ri1",
  assistedMaximumAreaCount: "ri1",
};

const isEditOptionSelectMMS = {
  ratioOfPopulationToAge: "mean",
  education: "mean",
  ratioOfPopulationToSex: "mean",
  ratioOfPopulationToDepen: "mean",
  householdSize: "mean",
  debt: "mean",
  wealth: "mean",
  landSize: "mean",
  machineCount: "mean",
  agricultureCost: "mean",
};

const defaultCrops = [
  {
    key: "ri1",
    value: 1,
  },
  {
    key: "ri2",
    value: 1,
  },
  {
    key: "ri3",
    value: 1,
  },
  {
    key: "ri4",
    value: 1,
  },
  {
    key: "mp",
    value: 1,
  },
  {
    key: "cf",
    value: 1,
  },
  {
    key: "sc",
    value: 1,
  },
  {
    key: "rb",
    value: 1,
  },
  {
    key: "op",
    value: 1,
  },
];

const reqNumberAndDot = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;

const Tab2 = () => {
  const {
    presetDefault,
    selectPreset,
    getPresetList,
    setSelectPreset,
    setTab,
  } = useCustomModelContext();
  const [isEdit, setIsEdit] = useState(false);
  const [editEntity, setEditEntity] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [optionSelect, setOptionSelect] = useState({});
  const [touchedInput, setTouchedInput] = useState({});
  const [touchedSelectMMS, setTouchedSelectMMS] = useState({});
  const [isToggle, setIsToggle] = useState({
    agent: true,
    belief: true,
    landMarket: true,
    disaster: true,
    emergencySubsidyPolicy: true,
    supportAgicultureInsurancePolicy: true,
    incomeInsurancePolicy: true,
    climateSmartAgriculturePolicy: true,
  });
  const [data, setData] = useState({
    agent: {
      agentCount: 675,
      riskAversion: 1,
      discountRate: 0.706,
      ratioOfPopulationToAge: { mode: 0, mean: 0, sd: 0 },
      ratioOfPopulationToDepen: { mode: 0, mean: 0, sd: 0 },
      ratioOfPopulationToSex: { mode: 0, mean: 0, sd: 0 },
      education: { mode: 0, mean: 0, sd: 0 },
      householdSize: { mode: 0, mean: 0, sd: 0 },
      debt: { mode: 0, mean: 0, sd: 0 },
      wealth: { mode: 0, mean: 0, sd: 0 },
      landSize: { mode: 4, mean: 0, sd: 0 },
      machineCount: { mode: 0, mean: 0, sd: 0 },
      agricultureCost: { mode: 0, mean: 0, sd: 0 },
    },
    landMarket: {
      landPrice: 100000,
      cropcosts: [
        { key: "ri1", value: 5210 },
        { key: "ri2", value: 5210 },
        { key: "ri3", value: 5210 },
        { key: "ri4", value: 5210 },
        { key: "mp", value: 4251 },
        { key: "cf", value: 9490 },
        { key: "sc", value: 5193.27 },
        { key: "rb", value: 281703 },
        { key: "op", value: 8518 },
      ],
      fertilizerCosts: [
        { key: "ri1", value: 1500 },
        { key: "ri2", value: 1500 },
        { key: "ri3", value: 1500 },
        { key: "ri4", value: 1500 },
        { key: "mp", value: 1500 },
        { key: "cf", value: 1500 },
        { key: "sc", value: 1500 },
        { key: "rb", value: 1500 },
        { key: "op", value: 1500 },
      ],
      loanInterestRateRoutine: 25,
      loanInterestRateInvestor: [
        { key: "m1", value: 6 },
        { key: "m2", value: 6 },
        { key: "m3", value: 6 },
      ],
      machineCosts: [
        { key: "m1", value: 150000 },
        { key: "m2", value: 100000 },
        { key: "m3", value: 5000000 },
      ],
      roiInvestor: [
        { key: "m1", value: 20 },
        { key: "m2", value: 15 },
        { key: "m3", value: 10 },
      ],
      roiInFarm: 1,
      roiOffFarm: 1,
      creditLimitInvertor: 1000000,
      creditLimitRoutine: 1000000,
      creditLimitRatioProfit: 30,
      creditLimitRatioLandPrice: 50,
      downPaymentRatioInvestor: 20,
    },
    disaster: {
      rainfallThreshold: [1000, 2500],
      temperatureTheshold: [10, 45],
    },
    emergencySubsidyPolicy: {
      FloodDamageCompensationRate: [
        { key: "ri1", value: 1340 },
        { key: "ri2", value: 1340 },
        { key: "ri3", value: 1340 },
        { key: "ri4", value: 1340 },
        { key: "mp", value: 1980 },
        { key: "cf", value: 1980 },
        { key: "sc", value: 1980 },
        { key: "rb", value: 4048 },
        { key: "op", value: 4048 },
      ],
      DroughtDamageCompensationRate: [
        { key: "ri1", value: 1340 },
        { key: "ri2", value: 1340 },
        { key: "ri3", value: 1340 },
        { key: "ri4", value: 1340 },
        { key: "mp", value: 1980 },
        { key: "cf", value: 1980 },
        { key: "sc", value: 1980 },
        { key: "rb", value: 4048 },
        { key: "op", value: 4048 },
      ],
      assistedMaximumAreaCount: 30,
      efficientcyRate: 1,
    },
    supportAgicultureInsurancePolicy: {
      premiumPriceHighRiskAreaTier1: 218,
      premiumPriceMediumRiskAreaTier1: 199,
      premiumPriceLowRiskAreaTier1: 99,
      premiumPriceHighRiskAreaTier2: 110,
      premiumPriceMediumRiskAreaTier2: 60,
      premiumPriceLowRiskAreaTier2: 27,
      SupportPremiumHighRiskAreaTier1: 59.4,
      SupportPremiumMediumRiskAreaTier1: 59.4,
      SupportPremiumLowRiskAreaTier1: 59.4,
      CompensationHighRiskAreaTier1: 1190,
      CompensationMediumRiskAreaTier1: 1190,
      CompensationLowRiskAreaTier1: 1190,
      CompensationHighRiskAreaTier2: 240,
      CompensationMediumRiskAreaTier2: 240,
      CompensationLowRiskAreaTier2: 240,
      efficientcyRate: 1,
    },
    incomeInsurancePolicy: {
      guaranteePrice: [
        { key: "ri1", value: 12 },
        { key: "ri2", value: 9.2 },
        { key: "ri3", value: 8 },
        { key: "ri4", value: 9.2 },
        { key: "mp", value: 9 },
        { key: "cf", value: 3 },
        { key: "sc", value: 10 },
        { key: "rb", value: 90 },
        { key: "op", value: 90 },
      ],
      assistedMaximumAreaCount: [
        { key: "ri1", value: 14 },
        { key: "ri2", value: 25 },
        { key: "ri3", value: 30 },
        { key: "ri4", value: 16 },
        { key: "mp", value: 30 },
        { key: "cf", value: 30 },
        { key: "sc", value: 30 },
        { key: "rb", value: 30 },
        { key: "op", value: 30 },
      ],
    },
    climateSmartAgriculturePolicy: {
      awdSubsidyCost: 30000,
      dripSubsidyCost: 15000,
    },
  });
  const [infoModal, setInfoModal] = useState({
    isOpen: false,
    data: { type: null, name: null },
  });

  useEffect(() => {
    if (presetDefault) {
      setData(presetDefault);
    }
  }, [presetDefault]);

  useEffect(() => {
    if (selectPreset?.detail) {
      const { header, detail } = selectPreset;
      setName(header?.name);
      setDescription(header?.description);
      setData((prevState) => ({ ...prevState, ...detail }));
      setOptionSelect(isEditOptionSelect);
      setTouchedSelectMMS(isEditOptionSelectMMS);
      setIsEdit(true);
      setEditEntity(header);
      setSelectPreset(null);
    }
  }, [selectPreset]);

  const onHandleInfoModal = (obj) => {
    if (infoModal.isOpen) {
      setInfoModal((prev) => ({ isOpen: !prev.isOpen, type: null }));
    } else {
      setInfoModal((prev) => ({
        isOpen: !prev.isOpen,
        data: { type: obj?.type, name: obj?.name },
      }));
    }
  };

  const onSubmit = () => {
    let obj = { name: name, description: description, ...data };
    if (isEdit) {
      obj.id = editEntity.id;
      delete obj.createdOn;
      delete obj.updatedOn;
      presetService
        .updatePreset(obj)
        .then((res) => {
          const { success } = res;
          if (success) {
            getPresetList();
            Swal.fire("แก้ไข", "แก้ไขฉากทัศน์สำเร็จ", "success");
            setTab("3");
          }
        })
        .catch((err) => {});
    } else {
      presetService
        .create(obj)
        .then((res) => {
          const { success } = res;
          if (success) {
            getPresetList();
            Swal.fire("สำเร็จ", "เพิ่มฉากทัศน์สำเร็จ", "success");
            setTab("3");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const checkOptionEmpty = (field) => {
    if (!_.isEmpty(optionSelect?.[field])) {
      return false;
    } else {
      return true;
    }
  };

  const getSlideVal = (objName, field) => {
    const keySelect = optionSelect[field];
    if (!_.isEmpty(data?.[objName]?.[field])) {
      const found = data?.[objName]?.[field]
        ? data?.[objName]?.[field].find(({ key }) => key === keySelect)
        : null;

      return touchedInput[`${objName}_${field}_${keySelect}`] !== undefined
        ? touchedInput[`${objName}_${field}_${keySelect}`]
        : found?.value;
    }
    return 0;
  };

  const handleInputWithSelect = (e, objName) => {
    let input = e.target.value;
    const { name } = e.target;
    const reg = /^[0-9\b]+$/;
    const keySelect = optionSelect[name];
    if (keySelect && input.match(reqNumberAndDot)) {
      let findKey = data?.[objName]?.[name].find(
        ({ key }) => key === keySelect
      );
      let val = parseFloat(input) ? parseFloat(input) : 0;
      findKey.value = val;
      // let news = data?.[objName]?.[name];
      setTouchedInput((prev) => ({
        ...prev,
        [`${objName}_${name}_${keySelect}`]: input,
      }));

      setData((prevState) => ({
        ...prevState,
        [objName]: { ...prevState?.[objName] },
      }));
    }
  };

  const handleSliderInput = (e, objName) => {
    const { name, value } = e.target;
    const val = value ? parseInt(value) : 0;
    setData((prevState) => ({
      ...prevState,
      [objName]: { ...prevState?.[objName], [name]: val },
    }));
  };

  const handleInputName = (e) => {
    const { value } = e.target;
    setName(value);
  };
  const handleInputDescription = (e) => {
    const { value } = e.target;
    setDescription(value);
  };

  const handleChangeInput = (e, objName) => {
    let input = e.target.value;
    const name = e.target.name;
    if (input.match(reqNumberAndDot)) {
      let val = parseInt(input) ? parseInt(input) : 0;
      setData((prevState) => ({
        ...prevState,
        [objName]: { ...prevState?.[objName], [name]: val },
      }));
    }
    // const { name, value } = e.target;
    // const reg = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;
    // if (value === "" || reg.test(value)) {
    //   let val = value ? parseFloat(value) : "";
    //   setData((prevState) => ({
    //     ...prevState,
    //     [objName]: { ...prevState?.[objName], [name]: val },
    //   }));
    // }
  };

  const handleChangeInputFloat = (e, objName) => {
    let input = e.target.value;
    const name = e.target.name;
    if (input.match(reqNumberAndDot)) {
      let val = parseFloat(input) ? parseFloat(input) : 0;
      setTouchedInput((prev) => ({ ...prev, [`${objName}_${name}`]: input }));
      setData((prevState) => ({
        ...prevState,
        [objName]: { ...prevState?.[objName], [name]: val },
      }));
    }

    // const { name, value } = e.target;
    // const reg = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;
    // if (value === "" || reg.test(value)) {
    //   let val = value ? parseFloat(value) : "";
    //   setData((prevState) => ({
    //     ...prevState,
    //     [objName]: { ...prevState?.[objName], [name]: val },
    //   }));
    // }
  };

  const handleChangeInputArrayOfString = (e, objName, index) => {
    let input = e.target.value;
    const name = e.target.name;
    // const reg = /^[0-9\b]+$/;
    // if (value === "" || reg.test(value)) {
    //   let val = value ? parseInt(value) : 0;
    //   let objData = data;
    //   objData[objName][name][index] = val;
    //   setData((prevState) => ({
    //     ...prevState,
    //     [objName]: { ...prevState?.[objName], [name]: objData[objName][name] },
    //   }));
    // }

    if (input.match(reqNumberAndDot)) {
      let val = parseFloat(input) ? parseFloat(input) : 0;
      setTouchedInput((prev) => ({ ...prev, [name + index]: input }));
      // setData((prevState) => ({
      //   ...prevState,
      //   [objName]: { ...prevState?.[objName], [name]: val },
      // }));
      let objData = data;
      objData[objName][name][index] = val;
      setData((prevState) => ({
        ...prevState,
        [objName]: { ...prevState?.[objName], [name]: objData[objName][name] },
      }));
    }
  };

  const onHandleToggle = (name) => {
    setIsToggle((prevState) => ({ ...prevState, [name]: !isToggle[name] }));
  };

  const renderCardHeader = (name, wording) => {
    return (
      <Card>
        <CardHeader
          style={{ padding: "5px", backgroundColor: "#98c5ff" }}
          action={
            <>
              <div className={"m-2"}>
                {isToggle?.[name] ? (
                  <KeyboardArrowUpIcon
                    style={{ color: "white" }}
                    onClick={() => onHandleToggle(name)}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    style={{ color: "white" }}
                    onClick={() => onHandleToggle(name)}
                  />
                )}
              </div>
            </>
          }
          title={
            <p
              className="font-w-bold"
              style={{ fontSize: "20px", color: "#000000" }}
            >
              {wording}{" "}
              <span
                className="text-secondary m-2"
                style={{ fontSize: "14px", cursor: "pointer" }}
                onClick={() => onHandleInfoModal({ type: name, name: wording })}
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{ fontSize: 16 }}
                  className={"fa-regular"}
                />
                <span className="m-1">รายละเอียด</span>
              </span>
            </p>
          }
        />
      </Card>
    );
  };

  const renderOptionsList = (options) => {
    return (
      <>
        {options.map((item) => {
          return <option value={item?.value}>{item?.label}</option>;
        })}
      </>
    );
  };

  const renderOptionsMMSList = (options, isValue) => {
    return (
      <>
        {options.map((item) => {
          return (
            <option value={item?.value} selected={item?.value === isValue}>
              {item?.label}
            </option>
          );
        })}
      </>
    );
  };

  const renderFormSelect = (obj, field, options, placeholder) => {
    return (
      <Form.Select
        size="sm"
        id={field}
        name={field}
        onChange={(e) => {
          const { name, value } = e.target;
          setOptionSelect((prevState) => ({
            ...prevState,
            [field]: value,
          }));
        }}
        value={isEdit ? optionSelect[field] : null}
      >
        <option value={""} disabled={!checkOptionEmpty(field)}>
          {placeholder}
        </option>
        {renderOptionsList(options)}
      </Form.Select>
    );
  };

  const renderInputByRiceOptions = (label, options, objName, field) => {
    const getVal = (objName, field, keySelect) => {
      if (!_.isEmpty(data?.[objName]?.[field])) {
        const found = data?.[objName]?.[field]
          ? data?.[objName]?.[field].find(({ key }) => key === keySelect)
          : null;

        return touchedInput[`${objName}_${field}_${keySelect}`] !== undefined
          ? touchedInput[`${objName}_${field}_${keySelect}`]
          : found?.value;
      }
    };

    return options.map((op) => {
      return (
        <div className="border-around p-2 mt-2">
          <Row>
            <Col md={9} className="d-flex">
              <p className="font-l-big my-auto">
                {label} : {op.label}
              </p>
            </Col>
            <Col>
              <InputGroup>
                <Form.Control
                  id={field + "_" + op.value}
                  name={field + "_" + op.value}
                  value={getVal(objName, field, op.value)}
                  onChange={(e) => {
                    let input = e.target.value;
                    if (input.match(reqNumberAndDot)) {
                      let val = parseFloat(input) ? parseFloat(input) : 0;

                      data?.[objName]?.[field].map((n) => {
                        if (op.value === n.key) {
                          n.value = val;
                        }
                      });

                      setTouchedInput((prev) => ({
                        ...prev,
                        [`${objName}_${field}_${op.value}`]: input,
                      }));

                      setData((prevState) => ({
                        ...prevState,
                        [objName]: { ...prevState?.[objName] },
                      }));
                    }
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
        </div>
      );
    });
  };

  const renderSelectAndInput = (obj, field, option, placeholder) => {
    return (
      <>
        <InputGroup>
          {renderFormSelect(obj, field, option, placeholder)}
        </InputGroup>
        <InputGroup className="mt-1">
          <Form.Control
            id={field}
            name={field}
            value={getSlideVal(obj, field)}
            disabled={checkOptionEmpty(field)}
            onChange={(e) => handleInputWithSelect(e, obj)}
          />
          {/* {JSON.stringify(touchedInput[`${obj}_${field}`], 1, 1)} */}
        </InputGroup>
      </>
    );
  };

  const renderFormInput = (obj, field) => {
    return (
      <InputGroup>
        <Form.Control
          id={field}
          name={field}
          value={data[obj]?.[field]}
          onChange={(e) => handleChangeInput(e, obj)}
        />
      </InputGroup>
    );
  };

  const renderFormInputFloat = (obj, field) => {
    return (
      <InputGroup>
        <Form.Control
          id={field}
          name={field}
          value={
            touchedInput?.[`${obj}_${field}`] !== undefined
              ? touchedInput?.[`${obj}_${field}`]
              : data?.[obj]?.[field]
          }
          onChange={(e) => handleChangeInputFloat(e, obj)}
        />
      </InputGroup>
    );
  };

  const renderSelectMMS = (obj, field) => {
    return (
      <>
        <Row>
          <Col md={6}>
            {/* {JSON.stringify(touchedInput, 1, 1)}
            {JSON.stringify(touchedSelectMMS?.[field], 1, 1)} */}
          </Col>
          <Col md={6}>
            <InputGroup>
              <Form.Select
                size="sm"
                id={field}
                name={field}
                onChange={(e) => {
                  let value = e.target.value;
                  value = parseInt(value);
                  let cusDefaultVal = {};
                  if (value !== 5) {
                    cusDefaultVal = {
                      mean: 0,
                      sd: 0,
                    };
                  }
                  setData((prevState) => ({
                    ...prevState,
                    [obj]: {
                      ...prevState?.[obj],
                      [field]: {
                        ...prevState?.[obj]?.[field],
                        mode: value,
                        ...cusDefaultVal,
                      },
                    },
                  }));
                }}
                value={isEdit ? data?.[obj]?.[field]?.mode : null}
              >
                <option
                  value={""}
                  selected={data?.[obj]?.[field]?.mode == ""}
                  disabled={data?.[obj]?.[field]?.mode != "" ? true : false}
                >
                  เลือกค่าที่กำหนด
                </option>
                {renderOptionsMMSList(
                  options.mmsOptions,
                  data?.[obj]?.[field]?.mode
                )}
              </Form.Select>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          {data?.[obj][field]?.mode === 5 ? (
            <>
              <Col md={6}>
                <InputGroup>
                  <Form.Select
                    className="mt-1"
                    onChange={(e) => {
                      let value = e.target.value;
                      setTouchedSelectMMS((prevState) => ({
                        ...prevState,
                        [field]: value,
                      }));
                    }}
                    value={isEdit ? touchedSelectMMS[field] : null}
                  >
                    <option value={""} disabled={!checkOptionMMSEmpty(field)}>
                      เลือกกำหนดค่าเอง
                    </option>
                    {renderOptionsList(options.csOptions)}
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col md={6}>
                <InputGroup className="mt-1">
                  <Form.Control
                    id={field}
                    name={field}
                    disabled={_.isEmpty(touchedSelectMMS?.[field])}
                    value={
                      touchedInput[field + "_" + touchedSelectMMS?.[field]] !==
                      undefined
                        ? touchedInput[field + "_" + touchedSelectMMS?.[field]]
                        : data?.[obj]?.[field]?.[touchedSelectMMS?.[field]]
                    }
                    onChange={(e) => {
                      let input = e.target?.value;
                      if (input.match(reqNumberAndDot)) {
                        const csOption = touchedSelectMMS?.[field];
                        const valueIsFloat = {
                          [csOption]: parseFloat(input) ? parseFloat(input) : 0,
                        };
                        setTouchedInput((prev) => ({
                          ...prev,
                          [field + "_" + csOption]: input,
                        }));
                        setData((prevState) => ({
                          ...prevState,
                          [obj]: {
                            ...prevState?.[obj],
                            [field]: {
                              ...prevState?.[obj]?.[field],
                              ...valueIsFloat,
                            },
                          },
                        }));
                      }
                    }}
                  />
                </InputGroup>
              </Col>
            </>
          ) : (
            ""
          )}
          {data?.[obj][field]?.mode !== 5 ? (
            <>
              <Col md={6}></Col>
              <Col md={6}>
                <InputGroup className="mt-1">
                  <Form.Control disabled={true} />
                </InputGroup>
              </Col>
            </>
          ) : (
            ""
          )}
        </Row>
        {/* {JSON.stringify(data?.[obj]?.[field], 1, 1)} */}
      </>
    );
  };

  const checkOptionMMSEmpty = (field) => {
    if (!_.isEmpty(touchedSelectMMS?.[field])) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      <Card sx={{ minWidth: 275 }} className="mt-3">
        <CardHeader
          action={
            <Button
              variant="contained"
              style={{
                backgroundColor: "#FFFFFF",
                marginTop: 5,
              }}
              onClick={() => onSubmit()}
            >
              <p style={{ fontSize: "1rem", color: "#4186E0", fontWeight: "bold" }}>
                บันทึกฉากทัศน์
              </p>
            </Button>
          }
          title={<p>ปรับแต่งค่าแบบจำลอง</p>}
        />
        <CardContent className="w-90">
          <div className="text-left mt-3">
            <Form.Group className="mb-3">
              <Form.Label>
                <p className="font-w-bold">ชื่อฉากทัศน์</p>
              </Form.Label>
              <Form.Control
                id={"name"}
                name={"name"}
                value={name}
                onChange={(e) => handleInputName(e)}
              />
            </Form.Group>
          </div>
          <div className="text-left">
            <Form.Group className="mb-3">
              <Form.Label>
                <p className="font-w-bold">คำอธิบาย</p>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id={"description"}
                name={"description"}
                value={description}
                onChange={(e) => handleInputDescription(e)}
              />
            </Form.Group>
          </div>

          <div className="text-left mt-3">
            {renderCardHeader("agent", "ตัวแทน")}
            <Collapse isOpen={isToggle?.agent}>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">{lang.agentCount}</p>
                  </Col>
                  <Col>
                    {renderFormInput("agent", "agentCount")}
                    {/* <Form.Control
                        id={"agentCount"}
                        name={"agentCount"}
                        value={
                          touchedInput?.agentCount !== undefined
                            ? touchedInput?.agentCount
                            : data?.agent?.agentCount
                        }
                        onChange={(e) => handleChangeInput(e, "agent")}
                      /> */}
                  </Col>
                </Row>
              </div>
              {/* <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">{lang.riskAversion}</p>
                  </Col>
                  <Col>{renderFormInputFloat("agent", "riskAversion")}</Col>
                </Row>
              </div> */}
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={6} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.ratioOfPopulationToAge}
                    </p>
                  </Col>
                  <Col md={6}>
                    {renderSelectMMS("agent", "ratioOfPopulationToAge")}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={6} className="d-flex">
                    <p className="font-l-big my-auto">{lang.education}</p>
                  </Col>
                  <Col md={6}>{renderSelectMMS("agent", "education")}</Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={6} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.ratioOfPopulationToSex}
                    </p>
                  </Col>
                  <Col md={6}>
                    {renderSelectMMS("agent", "ratioOfPopulationToSex")}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={6} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.ratioOfPopulationToDepen}
                    </p>
                  </Col>
                  <Col md={6}>
                    {renderSelectMMS("agent", "ratioOfPopulationToDepen")}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={6} className="d-flex">
                    <p className="font-l-big my-auto">{lang.householdSize}</p>
                  </Col>
                  <Col md={6}>{renderSelectMMS("agent", "householdSize")}</Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={6} className="d-flex">
                    <p className="font-l-big my-auto">{lang.debt}</p>
                  </Col>
                  <Col md={6}>{renderSelectMMS("agent", "debt")}</Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={6} className="d-flex">
                    <p className="font-l-big my-auto">{lang.wealth}</p>
                  </Col>
                  <Col md={6}>{renderSelectMMS("agent", "wealth")}</Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={6} className="d-flex">
                    <p className="font-l-big my-auto">{lang.landSize}</p>
                  </Col>
                  <Col md={6}>{renderSelectMMS("agent", "landSize")}</Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={6} className="d-flex">
                    <p className="font-l-big my-auto">{lang.machineCount}</p>
                  </Col>
                  <Col md={6}>{renderSelectMMS("agent", "machineCount")}</Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={6} className="d-flex">
                    <p className="font-l-big my-auto">{lang.agricultureCost}</p>
                  </Col>
                  <Col md={6}>
                    {renderSelectMMS("agent", "agricultureCost")}
                  </Col>
                </Row>
              </div>
            </Collapse>
          </div>
          <div className="text-left mt-3">
            {renderCardHeader("landMarket", "ที่ดินและตลาด")}
            <Collapse isOpen={isToggle?.landMarket}>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">{lang.landPrice}</p>
                  </Col>
                  <Col>
                    {renderFormInputFloat("landMarket", "landPrice")}
                    {/* <InputGroup>
                      <Form.Control
                        id={"landPrice"}
                        name={"landPrice"}
                        value={data?.landMarket?.landPrice}
                        onChange={(e) => handleChangeInput(e, "landMarket")}
                      />
                    </InputGroup> */}
                  </Col>
                </Row>
              </div>
              {/* <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">{lang.cropcosts}</p>
                  </Col>
                  <Col>
                    <InputGroup>
                      {renderSelectAndInput(
                        "landMarket",
                        "cropcosts",
                        options.riceOptions,
                        "เลือกประเภทผลผลิต"
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </div> */}
              <div>
                {renderInputByRiceOptions(
                  lang.cropcosts,
                  options.riceOptions,
                  "landMarket",
                  "cropcosts"
                )}
                {/* <pre>
                  {JSON.stringify(data?.["landMarket"]?.["cropcosts"], 1, 1)}
                </pre> */}
              </div>
              {/* <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">{lang.fertilizerCosts}</p>
                  </Col>
                  <Col>
                    <InputGroup>
                      {renderSelectAndInput(
                        "landMarket",
                        "fertilizerCosts",
                        options.riceOptions,
                        "เลือกประเภทผลผลิต"
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </div> */}
              <div>
                {renderInputByRiceOptions(
                  lang.fertilizerCosts,
                  options.riceOptions,
                  "landMarket",
                  "fertilizerCosts"
                )}
                {/* <pre>
                  {JSON.stringify(
                    data?.["landMarket"]?.["fertilizerCosts"],
                    1,
                    1
                  )}
                </pre> */}
              </div>

              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.loanInterestRateRoutine}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "landMarket",
                      "loanInterestRateRoutine"
                    )}
                    {/* <InputGroup>
                      <Form.Control
                        id={"loanInterestRateRoutine"}
                        name={"loanInterestRateRoutine"}
                        value={data?.landMarket?.loanInterestRateRoutine}
                        onChange={(e) => handleChangeInput(e, "landMarket")}
                      />
                    </InputGroup> */}
                  </Col>
                </Row>
              </div>
              {/* <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.loanInterestRateInvestor}
                    </p>
                  </Col>
                  <Col>
                    <InputGroup>
                      {renderSelectAndInput(
                        "landMarket",
                        "loanInterestRateInvestor",
                        options.machineOptions,
                        "เลือกเครื่องจักร/เทคโนโลยี"
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </div> */}
              {/*<div>*/}
              {/*  {renderInputByRiceOptions(*/}
              {/*    lang.loanInterestRateInvestor,*/}
              {/*    options.machineOptions,*/}
              {/*    "landMarket",*/}
              {/*    "loanInterestRateInvestor"*/}
              {/*  )}*/}
              {/*</div>*/}
              {/*<div className="border-around p-2 mt-2">}
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">{lang.machineCosts}</p>
                  </Col>
                  <Col>
                    <InputGroup>
                      {renderSelectAndInput(
                        "landMarket",
                        "machineCosts",
                        options.machineOptions,
                        "เลือกเครื่องจักร/เทคโนโลยี"
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              {/*</div>*/}
              <div>
                {renderInputByRiceOptions(
                  lang.machineCosts,
                  options.machineOptions,
                  "landMarket",
                  "machineCosts"
                )}
              </div>
               {/*<div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">{lang.roiInvestor}</p>
                  </Col>
                  <Col>
                    <InputGroup>
                      {renderSelectAndInput(
                        "landMarket",
                        "roiInvestor",
                        options.machineOptions,
                        "เลือกเครื่องจักร/เทคโนโลยี"
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </div> */}
              <div>
                {renderInputByRiceOptions(
                  lang.roiInvestor,
                  options.machineOptions,
                  "landMarket",
                  "roiInvestor"
                )}
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">{lang.roiOffFarm}</p>
                  </Col>
                  <Col>
                    {renderFormInputFloat("landMarket", "roiOffFarm")}
                    {/* <InputGroup>
                      <Form.Control
                        id={"roiOffFarm"}
                        name={"roiOffFarm"}
                        value={data?.landMarket?.roiOffFarm}
                        onChange={(e) => handleChangeInput(e, "landMarket")}
                      />
                    </InputGroup> */}
                  </Col>
                </Row>
              </div>
              {/* <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.creditLimitInvertor}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat("landMarket", "creditLimitInvertor")}
                  </Col>
                </Row>
              </div> */}
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.creditLimitRoutine}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat("landMarket", "creditLimitRoutine")}
                    {/* <InputGroup>
                      <Form.Control
                        id={"creditLimitRoutine"}
                        name={"creditLimitRoutine"}
                        value={data?.landMarket?.creditLimitRoutine}
                        onChange={(e) => handleChangeInput(e, "landMarket")}
                      />
                    </InputGroup> */}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.creditLimitRatioProfit}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "landMarket",
                      "creditLimitRatioProfit"
                    )}
                    {/* <InputGroup>
                      <Form.Control
                        id={"creditLimitRatioProfit"}
                        name={"creditLimitRatioProfit"}
                        value={data?.landMarket?.creditLimitRatioProfit}
                        onChange={(e) => handleChangeInput(e, "landMarket")}
                      />
                    </InputGroup> */}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.creditLimitRatioLandPrice}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "landMarket",
                      "creditLimitRatioLandPrice"
                    )}
                    {/* <InputGroup>
                      <Form.Control
                        id={"creditLimitRatioLandPrice"}
                        name={"creditLimitRatioLandPrice"}
                        value={data?.landMarket?.creditLimitRatioLandPrice}
                        onChange={(e) => handleChangeInput(e, "landMarket")}
                      />
                    </InputGroup> */}
                  </Col>
                </Row>
              </div>
            </Collapse>
          </div>
          <div className="text-left mt-3">
            {renderCardHeader("disaster", "ภัยพิบัติ")}
            <Collapse isOpen={isToggle?.disaster}>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.rainfallThreshold}
                    </p>
                  </Col>
                  <Col>
                    <InputGroup>
                      <Form.Control
                        id={"rainfallThreshold"}
                        name={"rainfallThreshold"}
                        value={
                          touchedInput?.rainfallThreshold0 !== undefined
                            ? touchedInput?.rainfallThreshold0
                            : data?.disaster?.rainfallThreshold[0]
                        }
                        onChange={(e) =>
                          handleChangeInputArrayOfString(e, "disaster", 0)
                        }
                      />
                      <InputGroup.Text className={"p-0"}>
                        <p className="mx-1">min</p>
                      </InputGroup.Text>
                      <Form.Control
                        id={"rainfallThreshold"}
                        name={"rainfallThreshold"}
                        value={
                          touchedInput?.rainfallThreshold1 !== undefined
                            ? touchedInput?.rainfallThreshold1
                            : data?.disaster?.rainfallThreshold[1]
                        }
                        onChange={(e) =>
                          handleChangeInputArrayOfString(e, "disaster", 1)
                        }
                      />
                      <InputGroup.Text className={"p-0"}>
                        <p className="mx-1">max</p>
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.temperatureTheshold}
                    </p>
                  </Col>
                  <Col>
                    <InputGroup>
                      <Form.Control
                        id={"temperatureTheshold"}
                        name={"temperatureTheshold"}
                        value={
                          touchedInput?.temperatureTheshold0 !== undefined
                            ? touchedInput?.temperatureTheshold0
                            : data?.disaster?.temperatureTheshold[0]
                        }
                        onChange={(e) =>
                          handleChangeInputArrayOfString(e, "disaster", 0)
                        }
                      />
                      <InputGroup.Text className={"p-0"}>
                        <p className="mx-1">min</p>
                      </InputGroup.Text>
                      <Form.Control
                        id={"temperatureTheshold"}
                        name={"temperatureTheshold"}
                        value={data?.disaster?.temperatureTheshold[1]}
                        onChange={(e) =>
                          handleChangeInputArrayOfString(e, "disaster", 1)
                        }
                      />
                      <InputGroup.Text className={"p-0"}>
                        <p className="mx-1">max</p>
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>
                </Row>
              </div>
            </Collapse>
          </div>
          <div className="text-left mt-3">
            {renderCardHeader(
              "emergencySubsidyPolicy",
              "มาตรการการจ่ายเงินช่วยเหลือเกษตรกรผู้ประสบภัยพิบัติกรณีฉุกเฉิน"
            )}
            <Collapse isOpen={isToggle?.emergencySubsidyPolicy}>
              {/* <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.FloodDamageCompensationRate}
                    </p>
                  </Col>
                  <Col>
                    <InputGroup>
                      {renderSelectAndInput(
                        "emergencySubsidyPolicy",
                        "FloodDamageCompensationRate",
                        options.riceOptions,
                        "เลือกประเภทผลผลิต"
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </div> */}
              <div>
                {renderInputByRiceOptions(
                  lang.FloodDamageCompensationRate,
                  options.riceOptions,
                  "emergencySubsidyPolicy",
                  "FloodDamageCompensationRate"
                )}
              </div>
              {/* <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.DroughtDamageCompensationRate}
                    </p>
                  </Col>
                  <Col>
                    <InputGroup>
                      {renderSelectAndInput(
                        "emergencySubsidyPolicy",
                        "DroughtDamageCompensationRate",
                        options.riceOptions,
                        "เลือกประเภทผลผลิต"
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </div> */}
              <div>
                {renderInputByRiceOptions(
                  lang.DroughtDamageCompensationRate,
                  options.riceOptions,
                  "emergencySubsidyPolicy",
                  "DroughtDamageCompensationRate"
                )}
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.assistedMaximumAreaCount}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "emergencySubsidyPolicy",
                      "assistedMaximumAreaCount"
                    )}
                    {/* <InputGroup>
                      <Form.Control
                        id={"assistedMaximumAreaCount"}
                        name={"assistedMaximumAreaCount"}
                        value={
                          data?.emergencySubsidyPolicy?.assistedMaximumAreaCount
                        }
                        onChange={(e) =>
                          handleChangeInput(e, "emergencySubsidyPolicy")
                        }
                      />
                    </InputGroup> */}
                  </Col>
                </Row>
              </div>

              {/* <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">{lang.efficientcyRate}</p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "emergencySubsidyPolicy",
                      "efficientcyRate"
                    )}
                  </Col>
                </Row>
              </div> */}
            </Collapse>
          </div>
          <div className="text-left mt-3">
            {renderCardHeader(
              "supportAgicultureInsurancePolicy",
              "มาตรการสนับสนุนการทำประกันภัยการเกษตร"
            )}
            <Collapse isOpen={isToggle?.supportAgicultureInsurancePolicy}>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.premiumPriceHighRiskAreaTier1}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "supportAgicultureInsurancePolicy",
                      "premiumPriceHighRiskAreaTier1"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.premiumPriceMediumRiskAreaTier1}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "supportAgicultureInsurancePolicy",
                      "premiumPriceMediumRiskAreaTier1"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.premiumPriceLowRiskAreaTier1}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "supportAgicultureInsurancePolicy",
                      "premiumPriceLowRiskAreaTier1"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.premiumPriceHighRiskAreaTier2}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "supportAgicultureInsurancePolicy",
                      "premiumPriceHighRiskAreaTier2"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.premiumPriceMediumRiskAreaTier2}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "supportAgicultureInsurancePolicy",
                      "premiumPriceMediumRiskAreaTier2"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.premiumPriceLowRiskAreaTier2}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "supportAgicultureInsurancePolicy",
                      "premiumPriceLowRiskAreaTier2"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.CompensationHighRiskAreaTier1}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "supportAgicultureInsurancePolicy",
                      "CompensationHighRiskAreaTier1"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.CompensationMediumRiskAreaTier1}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "supportAgicultureInsurancePolicy",
                      "CompensationMediumRiskAreaTier1"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.CompensationLowRiskAreaTier1}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "supportAgicultureInsurancePolicy",
                      "CompensationLowRiskAreaTier1"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.CompensationHighRiskAreaTier2}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "supportAgicultureInsurancePolicy",
                      "CompensationHighRiskAreaTier2"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.CompensationMediumRiskAreaTier2}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "supportAgicultureInsurancePolicy",
                      "CompensationMediumRiskAreaTier2"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.CompensationLowRiskAreaTier2}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "supportAgicultureInsurancePolicy",
                      "CompensationLowRiskAreaTier2"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.SupportPremiumHighRiskAreaTier1}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "supportAgicultureInsurancePolicy",
                      "SupportPremiumHighRiskAreaTier1"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.SupportPremiumMediumRiskAreaTier1}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "supportAgicultureInsurancePolicy",
                      "SupportPremiumMediumRiskAreaTier1"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.SupportPremiumLowRiskAreaTier1}
                    </p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "supportAgicultureInsurancePolicy",
                      "SupportPremiumLowRiskAreaTier1"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">{lang.efficientcyRate}</p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "supportAgicultureInsurancePolicy",
                      "efficientcyRate"
                    )}
                  </Col>
                </Row>
              </div>
            </Collapse>
          </div>
          <div className="text-left mt-3">
            {renderCardHeader(
              "incomeInsurancePolicy",
              "มาตรการรักษาเสถียรภาพรายได้ของเกษตรกร"
            )}
            <Collapse isOpen={isToggle?.incomeInsurancePolicy}>
              {/* <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">{lang.guaranteePrice}</p>
                  </Col>
                  <Col>
                    {renderSelectAndInput(
                      "incomeInsurancePolicy",
                      "guaranteePrice",
                      options.riceOptions,
                      "เลือกประเภทผลผลิต"
                    )}
                  </Col>
                </Row>
              </div> */}
              <div>
                {renderInputByRiceOptions(
                  lang.guaranteePrice,
                  options.riceOptions,
                  "incomeInsurancePolicy",
                  "guaranteePrice"
                )}
              </div>
              {/* <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">
                      {lang.assistedMaximumAreaCount}
                    </p>
                  </Col>
                  <Col>
                    {renderSelectAndInput(
                      "incomeInsurancePolicy",
                      "assistedMaximumAreaCount",
                      options.riceOptions,
                      "เลือกประเภทผลผลิต"
                    )}
                  </Col>
                </Row>
              </div> */}
              <div>
                {renderInputByRiceOptions(
                  lang.assistedMaximumAreaCount,
                  options.riceOptions,
                  "incomeInsurancePolicy",
                  "assistedMaximumAreaCount"
                )}
              </div>
            </Collapse>
          </div>
          <div className="text-left mt-3">
            {renderCardHeader(
              "climateSmartAgriculturePolicy",
              "มาตรการสนับสนุนการปรับเปลี่ยนเป็นเกษตรกรรมที่เท่าทันต่อภูมิอากาศ"
            )}
            <Collapse isOpen={isToggle?.climateSmartAgriculturePolicy}>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">{lang.awdSubsidyCost}</p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "climateSmartAgriculturePolicy",
                      "awdSubsidyCost"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="border-around p-2 mt-2">
                <Row>
                  <Col md={9} className="d-flex">
                    <p className="font-l-big my-auto">{lang.dripSubsidyCost}</p>
                  </Col>
                  <Col>
                    {renderFormInputFloat(
                      "climateSmartAgriculturePolicy",
                      "dripSubsidyCost"
                    )}
                  </Col>
                </Row>
              </div>
            </Collapse>
          </div>
        </CardContent>
      </Card>
      <InfoModal
        isOpen={infoModal?.isOpen}
        data={infoModal?.data}
        toggle={onHandleInfoModal}
      />
      {/* <pre className="text-left">{JSON.stringify(data, 1, 1)}</pre> */}
    </div>
  );
};

export default Tab2;
