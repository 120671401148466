import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Col, Image, Row, InputGroup, Form, Container } from "react-bootstrap";

import { Card } from "@mui/material";

const ExportModal = (props) => {
  const {
    isOpen,
    tab,
    extension,
    onHandleExportFile,
    onHandleChangeExtension,
    toggle,
  } = props;
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: "5em" }}>
      <ModalHeader toggle={toggle}>
        <p className="text-topic-2">ส่งออกข้อมูล</p>
      </ModalHeader>
      <ModalBody className={"p-0 mt-2"}>
        <Container>
          <Card
            sx={{
              // minWidth: 200,
              height: "auto",
              overflow: "scroll",
              border: "0px",
            }}
            variant="outlined"
          >
            <Row>
              <Col md={6} className="d-flex">
                <p
                  className="font-s-small my-auto"
                  style={{
                    fontSize: 14,
                  }}
                >
                  เลือกประเภทไฟล์
                </p>
              </Col>
              <Col>
                <InputGroup>
                  <Form.Select
                    size="sm"
                    id={"exportResult"}
                    name={"exportResult"}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      onHandleChangeExtension(value);
                    }}
                    value={extension}
                  >
                    <option value={"xlsx"}>.excel</option>
                    <option value={"csv"}>.csv</option>
                    <option value={"json"}>.json</option>
                    <option value={"txt"}>.txt</option>
                  </Form.Select>
                </InputGroup>
              </Col>
            </Row>
            <Row className="my-4">
              <Col>
                <Button
                  variant="contained"
                  className={"w-100"}
                  onClick={() => toggle()}
                >
                  <p
                    style={{
                      fontSize: 18,
                      color: "white",
                    }}
                    className={"font-w-bold"}
                  >
                    ยกเลิก
                  </p>
                </Button>
              </Col>
              <Col>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4186E0" }}
                  onClick={() => onHandleExportFile()}
                  className={"w-100"}
                >
                  <div>
                    <p className={"font-w-bold"} style={{ fontSize: 18 }}>
                      ดาวน์โหลด
                    </p>
                  </div>
                </Button>
              </Col>
            </Row>
          </Card>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default ExportModal;
