import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { Card } from "@mui/material";
import { Bar, Line } from "react-chartjs-2";
import _ from "lodash";
import { useResultContext } from "..";
import ExportImage from "../ExportImage";

const Select5 = () => {
  const { labelsYearChartArr, dataResult1, dataResult2 } = useResultContext();

  const [line1_income, setLine1_income] = useState([]);
  const [line1_debt, setLine1_debt] = useState([]);
  const [line2_income, setLine2_income] = useState([]);
  const [line2_debt, setLine2_debt] = useState([]);

  let refLine1 = useRef(null);
  let refLine2 = useRef(null);

  useEffect(() => {
    setDataChart1();
  }, [dataResult1]);

  useEffect(() => {
    setDataChart2();
  }, [dataResult2]);

  const setDataChart1 = () => {
    if (!_.isEmpty(dataResult1)) {
      let income = [];
      let debt = [];
      dataResult1.map(({ incomeAvg, debtAvg }) => {
        income.push(incomeAvg.toFixed(2));
        debt.push(debtAvg.toFixed(2));
      });
      setLine1_income(income);
      setLine1_debt(debt);
    }
  };

  const setDataChart2 = () => {
    if (!_.isEmpty(dataResult2)) {
      let income = [];
      let debt = [];
      dataResult2.map(({ incomeAvg, debtAvg }) => {
        income.push(incomeAvg.toFixed(2));
        debt.push(debtAvg.toFixed(2));
      });
      setLine2_income(income);
      setLine2_debt(debt);
    }
  };

  const getAvgIncome = (data) => {
    let sum = 0;
    let yearTotal = 0;
    data?.map(({ incomeAvg }, index) => {
      sum = sum + incomeAvg;
      yearTotal = index + 1;
    });
    const avg = sum && yearTotal ? sum / yearTotal : 0;
    return avg
      ? Number(avg.toFixed(2)).toLocaleString("en", {
          minimumFractionDigits: 2,
        })
      : "0";
  };

  const getAvgDebt = (data) => {
    let sum = 0;
    let yearTotal = 0;
    data?.map(({ debtAvg }, index) => {
      sum = sum + debtAvg;
      yearTotal = index + 1;
    });
    const avg = sum && yearTotal ? sum / yearTotal : 0;
    return avg
      ? Number(avg.toFixed(2)).toLocaleString("en", {
          minimumFractionDigits: 2,
        })
      : "0";
  };

  const labels = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ];

  const data1 = {
    labels,
    datasets: [
      {
        label: "นโยบาย 1",
        data: [17, 10, 11, 12, 11, 15],
        backgroundColor: "#4178BC",
        borderRadius: 3,
      },
      {
        label: "นโยบาย 2",
        data: [4, 6, 5, 7, 2, 2],
        backgroundColor: "#98C5FF",
        borderRadius: 3,
      },
    ],
  };

  const data2 = {
    labels,
    datasets: [
      {
        label: "นโยบาย 1",
        data: [17, 10, 11, 12, 11, 15],
        backgroundColor: "#FFA6A6",
        borderRadius: 3,
      },
      {
        label: "นโยบาย 2",
        data: [4, 6, 5, 7, 2, 2],
        backgroundColor: "#E8384F",
        borderRadius: 3,
      },
    ],
  };

  const optionsLine = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          font: {
            family: "'Prompt', sans-serif",
            size: 12,
          },
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
    scales: {
      y: {
        title: {
          align: "center",
          display: true,
          text: "บาท",
          font: {
            family: "'Prompt', sans-serif",
            size: 14,
          },
        },
      },
      x: {
        title: {
          align: "center",
          display: true,
          text: "คาบ",
          font: {
            family: "'Prompt', sans-serif",
            size: 14,
          },
        },
      },
    },
    responsive: false,
    animation: false,
  };

  const lineData1 = {
    labels: labelsYearChartArr(),
    datasets: [
      {
        label: "รายได้สุทธิ",
        data: line1_income,
        // fill: true,
        borderColor: "#33d49a",
        backgroundColor: "#33d49a",
        pointRadius: 2,
        tension: 0.4,
      },
      {
        label: "หนี้สิน",
        data: line1_debt,
        // fill: true,
        borderColor: "#4286e0",
        backgroundColor: "#4286e0",
        pointRadius: 2,
        tension: 0.4,
      },
    ],
  };

  const lineData2 = {
    labels: labelsYearChartArr(),
    datasets: [
      {
        label: "รายได้สุทธิ",
        data: line2_income,
        // fill: true,
        borderColor: "#e8384f",
        backgroundColor: "#e8384f",
        pointRadius: 2,
        tension: 0.4,
      },
      {
        label: "หนี้สิน",
        data: line2_debt,
        // fill: true,
        borderColor: "#ff9458",
        backgroundColor: "#ff9458",
        pointRadius: 2,
        tension: 0.4,
      },
    ],
  };

  return (
    <div className="mt-3">
      <Row>
        <Col>
          <Card
            style={{
              backgroundColor: "#4178BC",
              height: 80,
              padding: 25,
            }}
          >
            <div className="m-auto">
              <p className="text-white font-w-bold" style={{ fontSize: 20 }}>
                รายได้สุทธิเฉลี่ย {getAvgIncome(dataResult1)} บาทต่อครัวเรือน
              </p>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            style={{
              backgroundColor: "#E84C4C",
              height: 80,
              padding: 25,
            }}
          >
            <div className="m-auto">
              <p className="text-white font-w-bold" style={{ fontSize: 20 }}>
                รายได้สุทธิเฉลี่ย {getAvgIncome(dataResult2)} บาทต่อครัวเรือน
              </p>
            </div>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card
            style={{
              backgroundColor: "#0C4C9F",
              height: 80,
              padding: 25,
            }}
          >
            <div className="m-auto">
              <p className="text-white font-w-bold" style={{ fontSize: 20 }}>
                หนี้สิน เฉลี่ย {getAvgDebt(dataResult1)} บาทต่อครัวเรือน
              </p>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            style={{
              backgroundColor: "#C04328",
              height: 80,
              padding: 25,
            }}
          >
            <div className="m-auto">
              <p className="text-white font-w-bold" style={{ fontSize: 20 }}>
                หนี้สิน เฉลี่ย {getAvgDebt(dataResult2)} บาทต่อครัวเรือน
              </p>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="border-card mt-3 text-center chart-panel" style={{}}>
            <div className="m-auto mt-3" style={{ width: 500 }}>
              <ExportImage
                ref={refLine1}
                fileName={"IncomeAndDebt_Line1"}
                chartSettings={{
                  type: "line",
                  data: lineData1,
                  options: optionsLine,
                }}
              />
              <Line
                ref={refLine1}
                data={lineData1}
                options={{ ...optionsLine, responsive: true, animation: true }}
              />
            </div>
          </div>
        </Col>
        <Col>
          <div className="border-card mt-3 text-center chart-panel" style={{}}>
            <div className="m-auto mt-3" style={{ width: 500 }}>
              <ExportImage
                ref={refLine2}
                fileName={"IncomeAndDebt_Line2"}
                chartSettings={{
                  type: "line",
                  data: lineData2,
                  options: optionsLine,
                }}
              />
              <Line
                ref={refLine2}
                data={lineData2}
                options={{ ...optionsLine, responsive: true, animation: true }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Select5;
