import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Icon,
  Slider,
} from "@mui/material";
import { Col, Image, Row } from "react-bootstrap";
import "../../scss/_homePage.scss";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Bar, Line } from "react-chartjs-2";
import _ from "lodash";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  BarElement,
  ArcElement,
} from "chart.js";
import { useResultContext } from "./index";
import options from "../../helpers/options";
import StaticCross from "./staticcross";
import Select1 from "./selectChart/select1";
import Select2 from "./selectChart/select2";
import Select3 from "./selectChart/select3";
import Select4 from "./selectChart/select4";
import Select5 from "./selectChart/select5";
import Select6 from "./selectChart/select6";
import Select7 from "./selectChart/select7";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  Filler
);

const dropdownStatic = [
  { name: "จำนวนสมาชิกในครัวเรือน", value: 1 },
  { name: "รสนิยมต่อความเสี่ยง", value: 2 },
  { name: "ร้อยละครัวเรือนเพาะปลูก", value: 3 },
  { name: "การถือครองที่ดิน", value: 4 },
  { name: "รายได้และหนี้สินต่อครัวเรือน", value: 5 },
  { name: "ขนาดพื้นที่เพาะปลูก", value: 6 },
  { name: "ร้อยละครัวเรือนที่เข้าร่วมนโยบาย", value: 7 },
];
const Tab2 = () => {
  const {
    optionSimulation,
    selectSim1,
    setSelectSim1,
    selectSim2,
    setSelectSim2,
    allStep,
    fromTo,
    setFromTo,
  } = useResultContext();
  const [selectChart, setSelectChart] = useState("1");

  const drawBySelection = () => {
    switch (selectChart) {
      case "1":
        return <Select1 />;
        break;
      case "2":
        return <Select2 />;
        break;
      case "3":
        return <Select3 />;
        break;
      case "4":
        return <Select4 />;
        break;
      case "5":
        return <Select5 />;
        break;
      case "6":
        return <Select6 />;
        break;
      case "7":
        return <Select7 />;
        break;

      default:
        break;
    }
  };

  const renderDropdownBySelection = () => {
    return (
      <Row>
        <Col>
          <Row>
            <Col>
              <div className="text-left">
                <p className="font-w-bold">
                  {selectSim1?.label ? selectSim1?.label : "-"}
                </p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <div className="text-left">
                <p className="font-w-bold">
                  {selectSim2?.label ? selectSim2?.label : "-"}
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );

    // switch (selectChart) {
    //   case "4":
    //     return (
    //       <Row>
    //         <Col>
    //           <Row>
    //             <Col>
    //               <div className="text-left">
    //                 <p className="font-w-bold">ฉากทัศน์หลัก</p>
    //               </div>
    //             </Col>
    //             <Col>
    //               <div className="d-flex justify-content-end">
    //                 <Form.Group className="mb-3 w-50">
    //                   <Form.Select>
    //                     <option value="1">ปี 1-30</option>
    //                   </Form.Select>
    //                 </Form.Group>
    //               </div>
    //             </Col>
    //           </Row>
    //         </Col>
    //         <Col>
    //           <Row>
    //             <Col>
    //               <div className="text-left">
    //                 <p className="font-w-bold">ฉากทัศน์ที่ 1</p>
    //               </div>
    //             </Col>
    //             <Col>
    //               <div className="d-flex justify-content-end">
    //                 <Form.Group className="mb-3 w-50">
    //                   <Form.Select>
    //                     <option value="1">ปี 1-30</option>
    //                   </Form.Select>
    //                 </Form.Group>
    //               </div>
    //             </Col>
    //           </Row>
    //         </Col>
    //       </Row>
    //     );
    //     break;

    // default:
    //   return (
    //     <Row>
    //       <Col>
    //         <Row>
    //           <Col>
    //             <div className="text-left">
    //               <p className="font-w-bold">ฉากทัศน์หลัก</p>
    //             </div>
    //           </Col>
    //           <Col></Col>
    //         </Row>
    //       </Col>
    //       <Col>
    //         <Row>
    //           <Col>
    //             <div className="text-left">
    //               <p className="font-w-bold">ฉากทัศน์ที่ 1</p>
    //             </div>
    //           </Col>
    //           <Col></Col>
    //         </Row>
    //       </Col>
    //     </Row>
    //   );
    //   break;
    // }
  };

  const handleChangeFromTo = (e, type) => {
    if (type === "from") {
      if (parseInt(e.target.value) <= parseInt(fromTo.to)) {
        setFromTo((prev) => ({ ...prev, from: e.target.value }));
      }
    } else {
      if (parseInt(e.target.value) >= parseInt(fromTo.from)) {
        setFromTo((prev) => ({ ...prev, to: e.target.value }));
      }
    }
  };

  return (
    <div>
      <Card
        sx={{
          minWidth: 275,
        }}
        className="p-down mt-3"
      >
        <CardContent style={{ paddingBottom: 10 }}>
          <p className="text-topic text-left mb-3" style={{ fontSize: 20 }}>
            สถิติเชิงพรรณนาทั่วไป
          </p>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Select
                  onChange={(e) => setSelectChart(e.target.value)}
                  style={{ color: "#0c4c9f" }}
                >
                  {dropdownStatic.map((item) => (
                    <option value={item.value}>{item.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <div className="d-flex">
                <Form.Group className="mb-3 w-50">
                  <Form.Select
                    style={{ color: "#0c4c9f" }}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value) {
                        const select = optionSimulation.find(
                          ({ id }) => id === value
                        );
                        setSelectSim1(select);
                      }
                    }}
                  >
                    <option value="" disabled selected>
                      เลือกฉากทัศน์
                    </option>
                    {optionSimulation.map((sim) => (
                      <option
                        key={sim.id}
                        value={sim?.id}
                        selected={selectSim1?.id === sim?.id}
                      >
                        {sim?.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3 w-50" style={{ marginLeft: 10 }}>
                  <Form.Group>
                    <Form.Select
                      style={{ color: "#0c4c9f" }}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value) {
                          const select = optionSimulation.find(
                            ({ id }) => id === value
                          );
                          setSelectSim2(select);
                        }
                      }}
                    >
                      <option value="" disabled selected>
                        เลือกฉากทัศน์
                      </option>
                      {optionSimulation.map((sim) => (
                        <option
                          key={sim.id}
                          value={sim?.id}
                          selected={selectSim2?.id === sim?.id}
                        >
                          {sim?.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Form.Group>
              </div>
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col>
              <div className="d-flex">
                <Form.Group className="mb-2 w-50">
                  <Form.Select
                    onChange={(e) => handleChangeFromTo(e, "from")}
                    value={fromTo.from}
                  >
                    <option value="" disabled selected>
                      เลือกคาบเริ่มต้น
                    </option>
                    {Array.from(Array(allStep), (e, i) => {
                      return <option value={i + 1}>คาบที่ {i + 1}</option>;
                    })}
                    {/* {options.yearFrom.map((year) => {
                      if (year <= allStep) {
                        return <option value={year}>ปีที่ {year}</option>;
                      }
                    })} */}
                  </Form.Select>
                </Form.Group>
                <Form.Group
                  className="mb-2 w-50 text-right"
                  style={{ marginLeft: 10 }}
                >
                  <Form.Select
                    onChange={(e) => handleChangeFromTo(e, "to")}
                    value={fromTo.to}
                  >
                    <option value="" disabled selected>
                      เลือกคาบสิ้นสุด
                    </option>
                    {Array.from(Array(allStep), (e, i) => {
                      return <option value={i + 1}>คาบที่ {i + 1}</option>;
                    })}
                    {/* {options.yearTo.map((year) => {
                      if (year <= allStep) {
                        return (
                          <option
                            value={year}
                          >
                            ปีที่ {year}
                          </option>
                        );
                      }
                    })} */}
                  </Form.Select>
                  <span>หมายเหตุ: 1 คาบ = 6 เดือน</span>
                </Form.Group>
              </div>
            </Col>
          </Row>
          {renderDropdownBySelection()}
          {drawBySelection()}
        </CardContent>
      </Card>
      <StaticCross />
    </div>
  );
};

export default Tab2;
