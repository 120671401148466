import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useRef,
} from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Icon,
  ListSubheader,
  Tab,
} from "@mui/material";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab1 from "./tab1";
import Tab2 from "./tab2";
import { datasetService } from "../../services/dataset.service";
import _ from "lodash";
import Swal from "sweetalert2";

const CustomizeDataContext = createContext();

const UploadData = () => {
  const [value, setValue] = React.useState("1");
  const [dataManage, setDataManage] = useState(null);
  const [clickCalibrate, setClickCalibrate] = useState(null);

  useEffect(() => {
    getDataList();
  }, []);

  useEffect(() => {
    if (clickCalibrate != null) {
      getDataList();
    }
  }, [clickCalibrate]);

  const getDataList = () => {
    datasetService
      .getDataList()
      .then((res) => {
        const { success } = res;
        if (success) {
          const { entities } = res?.data || {};
          if (!_.isEmpty(entities)) {
            setDataManage(entities);
            setClickCalibrate(null);
          } else {
            setDataManage(null);
            setClickCalibrate(null);
          }
        }
      })
      .catch((err) => {});
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onHandleCalibrate = (id) => {
    datasetService
      .updateDataList(id)
      .then((res) => {
        const { success } = res;
        if (success) {
          getDataList();
        }
      })
      .catch((err) => {});
  };

  const onHandleSaveFile = async (data) => {
    datasetService
      .createDataSetFile(data)
      .then((res) => {
        const { success } = res;
        if (success) {
          Swal.fire("สำเร็จ", "บันทึกข้อมูลสำเร็จ", "success");
          getDataList();
        }
      })
      .catch((err) => {});
  };

  const onHandleRemove = async (id) => {
    Swal.fire({
      title: "ยืนยันลบข้อมูล?",
      text: "ต้องการที่จะลบข้อมูลนี้ใช่หรือไม่!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        datasetService
          .removeEntities(id)
          .then((res) => {
            const { success } = res;
            if (success) {
              Swal.fire("ลบสำเร็จ", "ลบข้อมูลสำเร็จ", "success");
              getDataList();
            }
          })
          .catch((err) => {});
      }
    });
  };

  const onHandleSaveAgriculture = async (data) => {
    datasetService
      .createDataSetAgriculture(data)
      .then((res) => {
        const { success } = res;
        if (success) {
          Swal.fire("สำเร็จ", "บันทึกข้อมูลสำเร็จ", "success");
          getDataList();
        }
      })
      .catch((err) => {});
  };

  const onHandleSensitivity = (id) => {
    datasetService
      .updateSensitivity(id)
      .then((res) => {
        const { success } = res;
        if (success) {
          getDataList();
        }
      })
      .catch((err) => {});
  };

  const context = {
    dataManage,
    onHandleCalibrate,
    onHandleSaveFile,
    onHandleSaveAgriculture,
    onHandleSensitivity,
    onHandleRemove,
  };

  return (
    <div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Card>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="fullWidth"
              >
                <Tab
                  className={
                    value === "1" ? "custom-tab-bg" : "custom-tab-text"
                  }
                  label={
                    <div>
                      <p className="mb-0" style={{ fontSize: 18 }}>
                        อัพโหลดชุดข้อมูลใหม่
                      </p>
                      <p style={{ fontSize: 12 }}>(Create Data Set)</p>
                    </div>
                  }
                  value="1"
                />
                <Tab
                  className={
                    value === "2" ? "custom-tab-bg" : "custom-tab-text"
                  }
                  label={
                    <div>
                      <p className="mb-0" style={{ fontSize: 18 }}>
                        จัดการชุดข้อมูล
                      </p>
                      <p style={{ fontSize: 12 }}>(Data Management)</p>
                    </div>
                  }
                  value="2"
                />
              </TabList>
            </Box>
          </Card>
          <CustomizeDataContext.Provider value={context}>
            <TabPanel value="1">
              <Tab1 />
            </TabPanel>
            <TabPanel value="2">
              <Tab2 />
            </TabPanel>
          </CustomizeDataContext.Provider>
        </TabContext>
      </Box>
    </div>
  );
};

export function useCustomDataContext() {
  return useContext(CustomizeDataContext);
}

export default UploadData;
