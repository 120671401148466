import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Icon,
} from "@mui/material";
import ImageHome from "../../assets/Frame-4.png";
import { Col, Image, Row } from "react-bootstrap";
import "../../scss/_homePage.scss";
import { CircularProgressbar } from "react-circular-progressbar";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faEye } from "@fortawesome/free-solid-svg-icons";
import ModelSummary from "./tab/simulation/ModelSummary";
import { useCustomModelContext } from "./index";
import _ from "lodash";
import Form from "react-bootstrap/Form";
import { RESULT_BASE_URL } from "../../common";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const Tab1 = () => {
  const {
    presetActive,
    presetList,
    onHandleView,
    onHandleUpdatePresetActive,
    onHandleSimulate,
    isSimulate,
    percentage,
    runningLogs,
    modelSummary,
    onHandleCancelSimulation,
    dataManage,
    runType,
  } = useCustomModelContext();

  const [logs, setLogs] = useState([]);
  const [selectData, setSelectData] = useState("");
  const [selectRunType, setSelectRunType] = useState("normalrun");
  const { height, width } = useWindowDimensions();

  const data5 = [
    {
      text1: "[info] Processing 1 from 20",
      text2: "[system]: Completed",
    },
    {
      text1: "[info] Processing 1 from 20",
      text2: "[system]: Completed",
    },
    {
      text1: "[info] Processing 1 from 20",
      text2: "[system]: Completed",
    },
    {
      text1: "[info] Processing 1 from 20",
      text2: "[system]: Completed",
    },
    {
      text1: "[info] Processing 1 from 20",
      text2: "[system]: Completed",
    },
    {
      text1: "[info] Processing 1 from 20",
      text2: "[system]: Completed",
    },
    {
      text1: "[info] Processing 1 from 20",
      text2: "[system]: Completed",
    },
  ];

  useEffect(() => {
    if (!_.isEmpty(runningLogs)) {
      setLogs(_.reverse(runningLogs));
    } else {
      setLogs([]);
    }
  }, [runningLogs]);

  useEffect(() => {
    if (runType) {
      setSelectRunType(runType);
    }
  }, [runType]);

  const renderPresetCards = () => {
    if (!_.isEmpty(presetList)) {
      return presetList.map((item) => {
        return (
          <div className="mt-2" key={item?.id}>
            <Card
              sx={{
                minWidth: 275,
                height: 110,
                backgroundColor: "#4186E01A",
                borderRadius: 3,
                boxShadow: "unset",
                border: "1px solid #BBBBBB",
                padding: "unset !important",
              }}
              className="p-down mb-2"
            >
              <CardContent>
                <Row className="align-items-center">
                  <Col md={3}>
                    <p style={{ fontSize: 14, fontWeight: "bold" }}>
                      {item?.name}
                    </p>
                  </Col>
                  <Col className="text-right">
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#62BB35",
                        marginRight: 5,
                      }}
                      className="mb-1"
                      onClick={() => onHandleView(item)}
                    >
                      <FontAwesomeIcon
                        icon={faEye}
                        style={{ fontSize: 15, marginRight: 5 }}
                      />
                      <p style={{ fontSize: 12 }}>ภาพรวม</p>
                    </Button>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#4186E0" }}
                      className={`mb-1 ${
                        presetActive?.id !== item?.id || "preset-active"
                      }`}
                      disabled={presetActive === item?.id ? true : false}
                      onClick={() =>
                        presetActive?.id !== item?.id
                          ? onHandleUpdatePresetActive(item?.id)
                          : ""
                      }
                    >
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        style={{ fontSize: 15, marginRight: 5 }}
                      />
                      <p className={"font-w-bold"} style={{ fontSize: 12 }}>
                        {presetActive?.id === item?.id
                          ? "กำลังใช้"
                          : "เลือกใช้"}
                      </p>
                    </Button>
                  </Col>
                </Row>
                <hr className="my-1" />
                <Row>
                  <div
                    className={"mb-1"}
                    style={{ overflow: "scroll", height: 50 }}
                  >
                    <p style={{ fontSize: 12 }}>{item?.description}</p>
                  </div>
                </Row>
              </CardContent>
            </Card>
          </div>
        );
      });
    } else {
      return (
        <div className="mt-2">
          <Card
            sx={{
              minWidth: 275,
              backgroundColor: "#4186E01A",
              borderRadius: 3,
              boxShadow: "unset",
              border: "1px solid #BBBBBB",
              padding: "unset !important",
            }}
            className="p-down"
          >
            <CardContent>
              <Row className="align-items-center">
                <Col md={12} className={"text-center mt-3"}>
                  <p style={{ fontSize: 16, fontWeight: "bold" }}>No data</p>
                </Col>
              </Row>
            </CardContent>
          </Card>
        </div>
      );
    }
  };

  const renderRunningLogs = () => {
    if (!_.isEmpty(logs)) {
      return (
        <List
          sx={{
            width: "100%",
            // maxWidth: 360,
            height: 320,
            bgcolor: "background.paper",
            overflow: "scroll",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {/*{data5.map((r) => (
                <ListItemButton>
                  <ListItemText primary={r.text1} secondary={r.text2} />
                </ListItemButton>
            ))}*/}
          {logs.map((log, index) => {
            const text1 = `[info] Processing ${log?.currentCount} from ${log?.batchCount}`;
            const text2 = `Step ${log?.currentStep} from ${log?.allStep}`;
            return (
              <ListItemButton key={index}>
                <ListItemText
                  className="section-logrunning"
                  primary={text1}
                  secondary={text2}
                />
              </ListItemButton>
            );
          })}
        </List>
      );
    } else {
      return (
        <Card
          className={"text-center"}
          variant="outlined"
          sx={{ border: "0px" }}
        >
          <p className={"my-2 font-w-bold"}>No data</p>
        </Card>
      );
    }
  };

  return (
    <div>
      <Row className="mt-4 text-left">
        <Col className="mb-4">
          <Card
            sx={{
              minWidth: 275,
              height: height - 188,
              overflow: "scroll",
            }}
          >
            <ModelSummary data={modelSummary} />
          </Card>
        </Col>
        <Col className="mb-4">
          <div style={{ margin: "auto" }}>
            <div style={{ width: 250, margin: "auto" }}>
              <Button
                disabled={
                  isSimulate ? false : _.isEmpty(selectData) ? true : false
                }
                onClick={() => onHandleSimulate(selectData, selectRunType)}
              >
                <CircularProgressbar
                  value={percentage}
                  text={`${
                    isSimulate
                      ? `${
                          percentage >= 100
                            ? "สำเร็จ"
                            : `กำลังจำลอง ${percentage}%`
                        }`
                      : selectRunType !== "batch"
                      ? "เริ่มจำลอง 1 ครั้ง"
                      : "เริ่มจำลองหลายครั้ง"
                  }`}
                />
              </Button>
            </div>
            <Card
              sx={{ minWidth: 275, height: height - 450, padding: "unset" }}
              className="mt-3"
            >
              <CardContent>
                <Row className="my-2">
                  <Col>
                    <p className="text-topic-2">ประเภทการจำลองแบบจำลอง</p>
                  </Col>
                  {selectRunType === "batch" ? (
                    <Col md={4} className="text-right">
                      <a
                        href={`${
                          RESULT_BASE_URL
                            ? RESULT_BASE_URL
                            : "https://abm.oae.go.th/batchrun"
                        }/${sessionStorage.getItem("username")}`}
                        target="_blank"
                      >
                        ดูผลลัพธ์
                      </a>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
                <Row>
                  <Form.Group lassName="my-2">
                    <Form.Select
                      onChange={(e) => setSelectRunType(e.target.value)}
                    >
                      <option
                        value="normal"
                        selected={selectRunType === "normal"}
                      >
                        จำลอง 1 ครั้ง
                      </option>
                      <option
                        value="batch"
                        selected={selectRunType === "batch"}
                      >
                        จำลองหลายครั้ง
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className="my-2">
                  <p className="text-topic-2">ชุดข้อมูล</p>
                </Row>
                <Row>
                  <Form.Group lassName="my-2">
                    <Form.Select
                      onChange={(e) => setSelectData(e.target.value)}
                    >
                      <option value="" disabled selected>
                        เลือกชุดข้อมูล
                      </option>
                      {dataManage?.map((item) => (
                        <option key={item.name} value={item?.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Row>
              </CardContent>
              <CardContent>
                <Row className="my-2">
                  <p className="text-topic-2">ประวัติการทำงาน (Logs)</p>
                </Row>
                <Row style={{ margin: "auto" }}>{renderRunningLogs()}</Row>
              </CardContent>
            </Card>
          </div>
        </Col>
        <Col className="mb-4">
          <Card
            sx={{
              minWidth: 275,
              height: height - 188,
              overflow: "scroll",
            }}
          >
            <CardContent>
              <Row className="">
                <p className="text-topic-2" style={{ fontSize: 18 }}>
                  ฉากทัศน์ที่บันทึกไว้ (Saved Scenarios)
                </p>
              </Row>
              <CardContent
                className={"p-0"}
                sx={{ minWidth: 275, height: "47.3em", overflow: "scroll" }}
              >
                <Row>{renderPresetCards()}</Row>
              </CardContent>
            </CardContent>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Tab1;
