import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { Card } from "@mui/material";
import { Bar, Line } from "react-chartjs-2";
import _ from "lodash";
import { useResultContext } from "..";
import ExportImage from "../ExportImage";

const Select2 = () => {
  const {
    selectSim1,
    selectSim2,
    yearLabel,
    labelsYearChartArr,
    dataResult1,
    dataResult2,
  } = useResultContext();

  const [line1, setLine1] = useState([]);
  const [line2, setLine2] = useState([]);

  let refLine1 = useRef(null);
  let refLine2 = useRef(null);

  useEffect(() => {
    setDataLine1();
  }, [dataResult1]);

  useEffect(() => {
    setDataLine2();
  }, [dataResult2]);

  const setDataLine1 = () => {
    if (!_.isEmpty(dataResult1)) {
      let arr = [];
      dataResult1.map(({ riskAversionAvg }) => {
        arr.push((riskAversionAvg / 100000000).toFixed(2));
      });
      setLine1(arr);
    }
  };

  const setDataLine2 = () => {
    if (!_.isEmpty(dataResult2)) {
      let arr = [];
      dataResult2.map(({ riskAversionAvg }) =>
        arr.push((riskAversionAvg / 100000000).toFixed(2))
      );
      setLine2(arr);
    }
  };

  const lineData1 = {
    labels: labelsYearChartArr(),
    datasets: [
      {
        label: "นโยบาย 1",
        data: line1,
        fill: true,
        borderColor: "#208EA399",
        backgroundColor: "#208EA399",
        pointRadius: 2,
        tension: 0.4,
      },
    ],
  };

  const lineData2 = {
    labels: labelsYearChartArr(),
    datasets: [
      {
        label: "นโยบาย 1",
        data: line2,
        fill: true,
        borderColor: "#E56F0299",
        backgroundColor: "#E56F0299",
        pointRadius: 2,
        tension: 0.4,
      },
    ],
  };

  const getAverage = (data) => {
    let sum = 0;
    let yearTotal = 0;
    data?.map(({ riskAversionAvg }, index) => {
      sum = sum + riskAversionAvg;
      yearTotal = index + 1;
    });
    const avg = sum && yearTotal ? sum / yearTotal / 100000000 : 0;

    return avg
      ? Number(avg.toFixed(2)).toLocaleString("en", {
          minimumFractionDigits: 2,
        })
      : "0";
  };

  const optionsLine = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          align: "center",
          display: true,
          text: "คาบ",
          font: {
            family: "'Prompt', sans-serif",
            size: 14,
          },
        },
      },
      y: {
        title: {
          align: "center",
          display: true,
          text: "ความถี่",
          font: {
            family: "'Prompt', sans-serif",
            size: 14,
          },
        },
      },
    },
    responsive: false,
    animation: false,
  };

  const renderLine = (data, refId) => {
    return (
      <Line
        ref={refId}
        data={data}
        options={{ ...optionsLine, responsive: true }}
      />
    );
  };

  const getAverageSTD = (data) => {
    let sum = 0;
    let yearTotal = 0;
    data?.map(({ riskAversionSTD }, index) => {
      sum = sum + riskAversionSTD;
      yearTotal = index + 1;
    });
    const avg = sum && yearTotal ? sum / yearTotal / 100000000 : 0;

    return avg
      ? Number(avg.toFixed(2)).toLocaleString("en", {
          minimumFractionDigits: 2,
        })
      : "0";
  };

  return (
    <div className="mt-3">
      <Row>
        <Col>
          <Card
            style={{
              backgroundColor: "#4178BC",
              height: 80,
              padding: 25,
            }}
          >
            <div className="m-auto">
              <p className="text-white font-w-bold" style={{ fontSize: 20 }}>
                เฉลี่ย {yearLabel()} คาบ {getAverage(dataResult1)}
              </p>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            style={{
              backgroundColor: "#E84C4C",
              height: 80,
              padding: 25,
            }}
          >
            <div className="m-auto">
              <p className="text-white font-w-bold" style={{ fontSize: 20 }}>
                เฉลี่ย {yearLabel()} คาบ {getAverage(dataResult2)}
              </p>
            </div>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card
            style={{
              backgroundColor: "#0C4C9F",
              height: 80,
              padding: 25,
            }}
          >
            <div className="m-auto">
              <p className="text-white font-w-bold" style={{ fontSize: 20 }}>
                ค่าเบี่ยงเบนมาตรฐาน {getAverageSTD(dataResult1)}
              </p>
            </div>
          </Card>
        </Col>
        <Col>
          <Card
            style={{
              backgroundColor: "#C04328",
              height: 80,
              padding: 25,
            }}
          >
            <div className="m-auto">
              <p className="text-white font-w-bold" style={{ fontSize: 20 }}>
                ค่าเบี่ยงเบนมาตรฐาน {getAverageSTD(dataResult2)}
              </p>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="border-card mt-3 text-center chart-panel" style={{}}>
            <div className="m-auto mt-3" style={{ width: 500 }}>
              <ExportImage
                ref={refLine1}
                fileName={"RiskAversion_Line1"}
                chartSettings={{
                  type: "line",
                  data: lineData1,
                  options: optionsLine,
                }}
              />
              {renderLine(lineData1, refLine1)}
            </div>
          </div>
        </Col>
        <Col>
          <div className="border-card mt-3 text-center chart-panel" style={{}}>
            <div className="m-auto mt-3" style={{ width: 500 }}>
              <ExportImage
                ref={refLine2}
                fileName={"RiskAversion_Line2"}
                chartSettings={{
                  type: "line",
                  data: lineData2,
                  options: optionsLine,
                }}
              />
              {renderLine(lineData2, refLine2)}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Select2;
