import { fetchWrapper } from "../helpers/fetch-wrapper";
import service from "./index";

const apiDataset = "/api/v1/dataset";

export const datasetService = {
  getDataList,
  updateDataList,
  createDataSetFile,
  createDataSetAgriculture,
  updateSensitivity,
  removeEntities,
};

function getDataList() {
  return fetchWrapper.get(`${apiDataset}/entities`).then(service.handleErrors);
}

function removeEntities(id) {
  const obj = {
    entityID: id,
  };
  return fetchWrapper.delete(`${apiDataset}/entity`, obj).then(service.handleErrors);
}

function updateDataList(id) {
  const obj = {
    datasetID: id,
  };
  return fetchWrapper
    .post(`${apiDataset}/parameter/search`, obj)
    .then(service.handleErrors);
}

function createDataSetFile(obj) {
  return fetchWrapper
    .postByParam(`${apiDataset}/upload`, obj)
    .then(service.handleErrors);
}

function updateSensitivity(id) {
  const obj = {
    datasetID: id,
  };
  return fetchWrapper
    .post(`${apiDataset}/sensitivity/start`, obj)
    .then(service.handleErrors);
}

function createDataSetAgriculture(obj) {
  return fetchWrapper
    .postByParam(`${apiDataset}/upload/api`, obj)
    .then(service.handleErrors);
}

// function handleErrors(res) {
//   const { success, error } = res;
//   if (!success) {
//     const { message, internalErrorMessage } = error || {};
//     Swal.fire(message, internalErrorMessage, "error");
//   }
//   return res;
// }
